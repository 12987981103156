import React, {useEffect, useState} from 'react';
import PageTitle from "../../common/PageTitle/PageTitle";
import s from './TemplatesForEmail.module.scss'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetTemplatesForEmail,
    onSetOfferTemplatesType,
    selectTemplatesForEmail,
    selectTemplatesType
} from "../../../store/parametersReducer";
import Box from "@mui/material/Box";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {Select} from "antd";
import {Pagination} from "@mui/material";
import {parametersDataHelper} from "../../../helpers/localStorageHelper";
import {useNavigate} from "react-router";
import commonS from "../../RequirementsPage/CommonTableStyles.module.scss";
import moment from "moment";
import {ReactComponent as GreenDot} from "../../../img/icons/greenDot.svg";
import {ReactComponent as RedDot} from "../../../img/icons/redDot.svg";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {EmailTemplateTypes} from "../../../app/api";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";


const TemplatesForEmail = () => {
    return (
        <PageWithSidebar>
            <TemplatesForEmailComponent/>
        </PageWithSidebar>
    )
}

const TemplatesForEmailComponent = () => {
    const [gridContentHeight, setGridContentHeight] = useState(850)
    const [height, setHeight] = useState(window.innerHeight);
    const rowsPerPageOptions = [5, 10, 50];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const templatesForEmail = useAppSelector(selectTemplatesForEmail)
    const templatesType = useAppSelector(selectTemplatesType)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    useEffect(() => {
        dispatch(GetTemplatesForEmail(templatesType))

    }, [dispatch, templatesType])

    const arr = Object.values(templatesForEmail);

    const columns = [
        {
            field: 'col1_template',
            headerName: 'Templates',
            flex: currentScreenWidth! > 768 ? 1 : 2
        },
        {
            field: 'col1_domain',
            headerName: 'Domain',
            flex: currentScreenWidth! > 768 ? 1 : 2
        },
        {
            field: 'col1_last_update_EN',
            headerName: 'EN',
            flex: currentScreenWidth! > 768 ? 1 : 2
        },
        {
            field: 'col2_last_update_CZ',
            headerName: 'CZ',
            flex: currentScreenWidth! > 768 ? 1 : 2
        },
    ];
    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        const heightForPage = height - 500
        setGridContentHeight(heightForPage)
        return () => window.removeEventListener('resize', handleResize);
    }, [height]);


    const apiRef = useGridApiRef();

    const [page, setPage] = React.useState(1);


    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);

    };


    const flattenedData = arr.map((row: any) => {
        const obj = {};
        row.forEach((cell: any, index: any) => {
            Object.entries(cell).forEach(([key, value]) => {

                if (key === 'last_update') {
                    // @ts-ignore
                    obj[`col${index + 1}_${key}_${cell.language}`] = value;
                } else {
                    // @ts-ignore
                    obj[`col${index + 1}_${key}`] = value;
                }

            });
        });
        return obj;
    });


    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
    }

    let paginatedRows = flattenedData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);


    const onTableRowClick = (e: any) => {
        parametersDataHelper.setCurrentTemplateForEmail(e.row)
        navigate('/parameters/templates-for-email/email-template')
    }


    const onSetTemplatesType = (templatesType: EmailTemplateTypes) => {
        dispatch(onSetOfferTemplatesType(templatesType))
    }

    return (
        <div className={s.inner}>
            <div className={s.inner__pageTitle}>
                <PageTitle title='Templates for email' backLink='/parameters' backLinktext={'Back'}
                           isBackButton={true}  top={currentScreenWidth! > 768 ? '125px' : '95px'}/>
            </div>
            <div
                className={commonS.tableInner}
            >
                {templatesForEmail &&
                    <Box>
                        <div className={s.tabButtons}>
                            <button className={templatesType === 'OFFER' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button} onClick={() => onSetTemplatesType('OFFER')}>Offers</button>
                            <button className={templatesType === 'OWNER' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button} onClick={() => onSetTemplatesType('OWNER')}>Owners</button>
                        </div>
                        <DataGridPro
                            getRowClassName={getRowClassName}
                            sx={{
                                borderRight: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRadius: '0px',
                                minHeight: '600px',
                                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                    display: "none"
                                },
                                "& .MuiDataGrid-cellContent": {
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#111827'
                                },
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 600,
                                    color: '#6B7280',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                },
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                },
                                "& .MuiDataGrid-row.Mui-selected": {
                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                },
                                "& .MuiDataGrid-row.Mui-selected:hover": {
                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                },
                                "& .MuiDataGrid-cell:focus": {
                                    outline: 'none'
                                },
                                "& .MuiDataGrid-toolbarContainer": {
                                    color: '#454545',
                                    position: 'absolute',
                                    top: '-50px',
                                    right: '0px'
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    margin: '0',
                                    fontFamily: 'Inter, sans-serif !important',
                                    fontWeight: 400,
                                    color: '#6B7280',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                },
                                "& .MuiPagination-root": {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '7px 0px'
                                },
                                "& .MuiButtonBase-root.Mui-selected": {
                                    backgroundColor: '#F9FAFB',
                                    borderRadius: '0px'
                                },
                                "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                    width: '40px',
                                    height: '40px',
                                    margin: 0,
                                    borderRight: '1px solid #D0D5DD',
                                    borderLeft: '1px solid #D0D5DD',
                                    borderTop: 'none',
                                    borderBottom: 'none',
                                    fontFamily: 'Inter, sans-serif !important',
                                    fontWeight: 500,
                                    color: '#1D2939',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    borderRadius: '0px'
                                },
                                "& .MuiPagination-ul": {
                                    border: '1px solid #D0D5DD',
                                    borderRadius: '8px'
                                },
                                "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                    borderRadius: '0px',
                                    border: 'none'
                                },
                                "& .MuiDataGrid-row:nth-of-type(odd)": {
                                    backgroundColor: '#F9FAFB'
                                },
                                "& .MuiDataGrid-virtualScrollerRenderZone": {
                                    width: '100%'
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    overflowY: 'scroll !important',
                                    overflowX: currentScreenWidth! > 768 ? 'hidden' : 'scroll !important',
                                    height: height > 920 ? `${gridContentHeight + 100}px !important` : `${gridContentHeight}px !important`,
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: '#FCFDFE'
                                },
                                "& .MuiDataGrid-root": {
                                    marginTop: '50px'
                                },
                                "& .MuiDataGrid-cell": {
                                    minWidth: currentScreenWidth! > 768 ? 'initial' : '200px !important'
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    minWidth: currentScreenWidth! > 768 ? 'initial' : '200px !important'
                                }
                            }}
                            apiRef={apiRef}
                            getRowId={(e: any) => e.col1_id}
                            columns={columns.map((column: any) => ({
                                ...column,
                                renderCell: (params: any) =>
                                    <CustomCell field={column.field}
                                                value={params.value}
                                                params={params}
                                    />,
                            }))}
                            rows={paginatedRows}
                            {...paginatedRows}
                            headerHeight={40}
                            rowHeight={56}
                            hideFooterSelectedRowCount
                            autoPageSize={true}
                            autoHeight={true}
                            disableColumnReorder={true}
                            onRowClick={onTableRowClick}
                            components={{
                                Footer: () =>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                        alignItems: 'center'
                                    }}>
                                        {
                                            currentScreenWidth! > 768
                                            &&
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                <p
                                                    className={s.footerText}
                                                >
                                                    Rows per page:
                                                </p>
                                                <Select
                                                    value={rowsPerPage}
                                                    onChange={handleRowsPerPageChange}
                                                    className={s.rowsPerPage}
                                                >
                                                    {rowsPerPageOptions.map((option) => (
                                                        <Select.Option key={option} value={option}>
                                                            {option}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        }

                                        <Pagination
                                            count={Math.ceil(flattenedData.length / rowsPerPage)}
                                            page={page}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                        {
                                            currentScreenWidth! > 768
                                            &&
                                            <p className={s.footerText}>
                                                Total templates: {flattenedData.length}
                                            </p>
                                        }
                                    </div>,
                            }}
                        />
                    </Box>
                }
            </div>
        </div>
    );
};


const CustomCell = ({field, value}: any) => {
    if (field === 'col1_last_update_EN') {
        return <div style={{display: 'flex', alignItems: 'center'}}>
            {
                value === null
                    ?
                    <>
                        <RedDot/>
                        <span
                            style={{
                                marginLeft: '8px'
                            }}
                            className={s.columnText}
                        >Empty</span>
                    </>
                    :
                    <div>
                        <GreenDot/>
                        <span style={{
                            marginLeft: '8px'
                        }}
                              className={s.columnText}
                        >Edited</span>
                        <div>
                            <span
                                className={s.date}
                            >{moment(value).format('YYYY.MM.DD')}</span>
                        </div>
                    </div>
            }
        </div>;
    }
    if (field === 'col2_last_update_CZ') {
        return <div style={{display: 'flex', alignItems: 'center'}}>
            {
                value === null
                    ?
                    <>
                        <RedDot/>
                        <span
                            style={{
                                marginLeft: '8px'
                            }}
                            className={s.columnText}
                        >Empty</span>
                    </>
                    :
                    <div>
                        <GreenDot/>
                        <span style={{
                            marginLeft: '8px'
                        }}
                              className={s.columnText}
                        >Edited</span>
                        <div>
                            <span
                                className={s.date}
                            >{moment(value).format('YYYY.MM.DD')}</span>
                        </div>
                    </div>
            }
        </div>;
    }
    return <div>{value}</div>;
};

export default TemplatesForEmail;