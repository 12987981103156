import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {contactsApi, gridApi} from "../app/api";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {GridsColumnFilters, GridsColumnsForFilters} from "../types/commonTypes";
import {GridSortItem} from "@mui/x-data-grid";
import moment from "moment";
import {ShownAndHiddenColumnsType} from "./propertiesReducer";



interface InitialStateType {
    currentPropId: string | null
    gridColumns: BrokerageReqRow[]
    gridRows: BrokerageReqRow[]
    gridFiltersForArray: GridsColumnFilters[]
    gridColumnsForFilters: GridsColumnsForFilters[]
    defaultGridData: BrokerageReqRow[]
    checkedReqsForOffer: any[]
    currentProperty: BrokerageCurrentProperty
    propertyOwnerContacts: any[]
    currentOfferType: 'Offer subscription' | 'Letter to the owner'
    brokerageReqsGridShownColumns: ShownAndHiddenColumnsType[],
    brokerageReqsGridHiddenColumns: ShownAndHiddenColumnsType[],
    brokerageReqsSortModel: GridSortItem[] | [],
    brokerageReqsSortingString: string | null,
    brokerageReqsDefaultGridRows: BrokerageReqRow[],
    brokerageLoaderMessage: string
    isBrokerageGridDataLoading: null | boolean
}

const initialState: InitialStateType = {
    currentPropId: null,
    gridColumns: [],
    gridRows: [],
    gridFiltersForArray: [],
    gridColumnsForFilters: [],
    defaultGridData: [],
    checkedReqsForOffer: [],
    currentProperty: {
        ADDR_CITY: "Praha",
        ADDR_COUNTRY_CODE: "CZ",
        ADDR_LATI: 50.09,
        ADDR_LONGI: 14.43,
        ADDR_STREET: "Petrská 1131/2",
        ADDR_ZIP: "11000",
        AGENT_NAME: "Vaškovičová, Zuzana",
        DATE_VISITED: null,
        DESCRIPTION: null,
        IS_TOPRINT: 0,
        LOT_SIZE: null,
        PROP_ID: 236671,
        SURFACE_TOTAL: 37.07,
        id: 0,
        isFiltered: false,
        isOpened: false,
        reqs: [],
        withSurface: false
    },
    propertyOwnerContacts: [],
    currentOfferType: 'Offer subscription',
    brokerageReqsGridShownColumns: [],
    brokerageReqsGridHiddenColumns: [],
    brokerageReqsSortModel: [],
    brokerageReqsSortingString: null,
    brokerageReqsDefaultGridRows: [],
    brokerageLoaderMessage: 'Loading',
    isBrokerageGridDataLoading: null
}

export const brokerageReqsSlice = createSlice({
    name: 'brokerageReqs',
    initialState,
    reducers: {
        setBrokerageReqProp: (state, action: PayloadAction<BrokerageCurrentProperty>) => {
            state.currentProperty = action.payload
        },
        onSetBrokerageReqsGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onChangeBrokerageReqsGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onSetBrokerageReqs: (state, action: PayloadAction<BrokerageReqRow[]>) => {
            state.gridRows = action.payload
        },
        onSetFiltersForBrokerageReqsGrid: (state, action: PayloadAction<{ gridFilters: GridsColumnFilters[] }>) => {
            state.gridFiltersForArray = action.payload.gridFilters
        },
        onSetBrokerageReqsGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeBrokerageReqsGridFilterValue: (state, action: PayloadAction<{ title?: string, value: string, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeBrokerageReqsGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeBrokerageReqsGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        setBrokerageReqsGridColumnsForFilters: (state, action: PayloadAction<{ columns: GridsColumnsForFilters[] }>) => {
            state.gridColumnsForFilters = action.payload.columns
        },
        setCheckedReqsForOffer: (state, action: PayloadAction<any>) => {
            state.checkedReqsForOffer.push({...action.payload, addText: ''});
        },
        onChangeOfferType: (state, action: PayloadAction<'Offer subscription' | 'Letter to the owner'>) => {
            state.currentOfferType = action.payload
        },
        onBrokerageReqsResetFiltersButtonsSelected: (state) => {

            state.gridColumnsForFilters = state.gridColumnsForFilters.map((m: GridsColumnsForFilters) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        setBrokerageReqsShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.brokerageReqsGridShownColumns = action.payload.columns.sort((a: ShownAndHiddenColumnsType, b: ShownAndHiddenColumnsType) => a.col_pos!! - b.col_pos!!)
        },
        setBrokerageReqsHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[]}>) => {
            state.brokerageReqsGridHiddenColumns = action.payload.columns.sort((a: ShownAndHiddenColumnsType, b: ShownAndHiddenColumnsType) => a.col_pos!! - b.col_pos!!)
        },
        onSetBrokerageReqsLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.brokerageReqsSortModel = action.payload.sort_model
        },
        setBrokerageReqsGridColumns: (state, action: PayloadAction<BrokerageReqRow[]>) => {
            state.gridColumns = action.payload
        },
        onSetBrokerageReqsGridSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.brokerageReqsSortingString = action.payload.sorting_string
        },
        onChangeBrokerageReqsGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.brokerageReqsGridShownColumns = state.brokerageReqsGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeBrokerageReqsGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.brokerageReqsGridShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
        onSetBrokerageGridLoaderMessage: (state, action: PayloadAction<string>) => {
            state.brokerageLoaderMessage = action.payload
        },
        onSetIsBrokerageReqsGridDataLoading: (state, action: PayloadAction<boolean | null>) => {
            state.isBrokerageGridDataLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetBrokerageReqsGridData.fulfilled, (state, action) => {
                const brokerageReqs = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {

                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['reqs'] = [];
                        obj['isOpened'] = false;

                        return obj;
                    }
                });
                state.gridRows = brokerageReqs
                // @ts-ignore
                state.gridColumns = action.payload[0]
                state.defaultGridData = brokerageReqs
            })
            .addCase(GetBrokeragePropertyOwnerContacts.fulfilled, (state, action) => {
                state.propertyOwnerContacts = action.payload
            })


    }
})

export const {
    setBrokerageReqProp,
    onSetBrokerageReqsGridFiltersButtonsModalOpened,
    onChangeBrokerageReqsGridFilterConditionValue,
    onSetBrokerageReqs,
    onSetFiltersForBrokerageReqsGrid,
    onSetBrokerageReqsGridFiltersButtonsSelected,
    onChangeBrokerageReqsGridFilterValue,
    onChangeBrokerageReqsGridFilterStartValue,
    onChangeBrokerageReqsGridFilterEndValue,
    setBrokerageReqsGridColumnsForFilters,
    onChangeOfferType,
    onBrokerageReqsResetFiltersButtonsSelected,
    setBrokerageReqsShownColumns,
    setBrokerageReqsHiddenColumns,
    setBrokerageReqsGridColumns,
    onSetBrokerageReqsLocalSortModel,
    onChangeBrokerageReqsGridColumnsPosition,
    onChangeBrokerageReqsGridColumnsWidth,
    onSetBrokerageReqsGridSortingString,
    onSetBrokerageGridLoaderMessage,
    onSetIsBrokerageReqsGridDataLoading
} = brokerageReqsSlice.actions

export const selectProperty = (state: RootState): BrokerageCurrentProperty => state.brokerageReqs.currentProperty
export const selectGridDataColumns = (state: RootState): BrokerageReqRow[] => state.brokerageReqs.gridColumns
export const selectGridDataRows = (state: RootState): BrokerageReqRow[] => state.brokerageReqs.gridRows
export const selectBrokerageReqsGridFiltersForArray = (state: RootState): GridsColumnFilters[] => state.brokerageReqs.gridFiltersForArray
export const selectBrokerageReqsColumnsForFilters = (state: RootState): GridsColumnsForFilters[] => state.brokerageReqs.gridColumnsForFilters
export const selectBrokerageReqsDefaultColumns = (state: RootState): BrokerageReqRow[] => state.brokerageReqs.defaultGridData
export const selectBrokerageReqCheckedProperties = (state: RootState): any[] => state.brokerageReqs.checkedReqsForOffer
export const selectBrokeragePropertyOwnerContacts = (state: RootState): any[] => state.brokerageReqs.propertyOwnerContacts
export const selectBrokeragePropertyOfferType = (state: RootState): 'Offer subscription' | 'Letter to the owner' => state.brokerageReqs.currentOfferType
export const selectBrokerageSortingString = (state: RootState): string | null => state.brokerageReqs.brokerageReqsSortingString
export const selectBrokerageShownColumns = (state: RootState): any[] => state.brokerageReqs.brokerageReqsGridShownColumns
export const selectBrokerageHiddenColumns = (state: RootState): any[] => state.brokerageReqs.brokerageReqsGridHiddenColumns
export const selectBrokerageSortModel = (state: RootState): GridSortItem[] | [] => state.brokerageReqs.brokerageReqsSortModel
export const selectBrokerageGridMessage = (state: RootState): string => state.brokerageReqs.brokerageLoaderMessage
export const selectIsBrokerageGridDataLoading = (state: RootState): boolean | null => state.brokerageReqs.isBrokerageGridDataLoading

export const GetBrokerageReqsGridData = createAsyncThunk<BrokerageReqRow[], {"p_agent_ref": number,
    "p_prop_ref": number,
    "p_sort_order": string | null}, AsyncThunkConfig>(
    'brokerageReqs/getBrokerageGridReqs',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        thunkAPI.dispatch(onSetIsBrokerageReqsGridDataLoading(true))
        try {
            const {status, data} = await gridApi.getBrokeragePropertiesReqsData(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetBrokerageReqsGridSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetBrokerageReqsLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetBrokerageReqsGridSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}));
                }
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue([])
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(onSetIsBrokerageReqsGridDataLoading(false))
        }
    }
)


export const GetBrokeragePropertyOwnerContacts = createAsyncThunk<any[], {agent_ref: string, prop_ref: string}, AsyncThunkConfig>(
    'brokerageReqs/getBrokeragePropertyOwnerContacts',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        try {
            const {
                status,
                data
            } = await contactsApi.getPropertyOwnerContacts(reqData.agent_ref, reqData.prop_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export interface BrokerageReqRow {
    CO_EMAIL: null | string
    CO_NAME: null | string
    CREATED_BY: null | string
    DATE_CREATED: null | string
    DATE_MODIFIED: null | string
    DATE_OFFERED: null | string
    DOMAIN: null | string
    EMAIL_LIST: null | string
    INFORMER: null | string
    MODIFIED_BY: null | string
    OFFER_STATUS: null | string
    REMARKS: null | string
    REQ_ID: number
}

export interface BrokerageCurrentProperty {
    ADDR_CITY:  null | string
    ADDR_COUNTRY_CODE:  null | string
    ADDR_LATI: null | number
    ADDR_LONGI: null | number
    ADDR_STREET:  null | string
    ADDR_ZIP:  null | string
    AGENT_NAME:  null | string
    DATE_VISITED: null | string
    DESCRIPTION: null | string
    IS_TOPRINT: number | null
    LOT_SIZE: null | number
    PROP_ID: null | number
    SURFACE_TOTAL: null | number
    id: null | number
    isFiltered: boolean
    isOpened: boolean
    reqs: any[]
    withSurface: boolean
}

export default brokerageReqsSlice.reducer