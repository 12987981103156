import React, {useEffect, useLayoutEffect, useState} from 'react';
import PageTitle from "../common/PageTitle/PageTitle";
import commonS from "../RequirementsPage/CommonTableStyles.module.scss";
import s from "./AttributeCode.module.scss";
import {InputLabel} from "@mui/material";
import searchIcon from "../../img/icons/searchTable.svg";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    ActivityCodeRow,
    changeActivityCodeRowValue,
    GetActivityAttributesThunk,
    onAttrCodesResetFiltersButtonsSelected, onChangeAttrCodesGridFilterValue,
    onChangeAttrOrderNum,
    onSetAttrCodesCurrentDomain, onSetAttrCodesCurrentPosition,
    onSetAttrCodesGridFiltersButtonsSelected,
    onSetAttrCodesReqs,
    onSetFiltersForAttrCodesGrid,
    selectActivityAttributeCodeColumns,
    selectActivityAttributeCodeDefaultRows,
    selectActivityAttributeCodeRows,
    selectAttrCodesCurrentDomain,
    setAttrCodesGridColumnsForFilters,
} from "../../store/parametersReducer";
import {debounce} from 'lodash'
import {selectUserData} from "../../store/userReducer";
import {
    ActivityCodesGridColumn,
} from "../../types/parametersTypes";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {DataGridPro, GridRowOrderChangeParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {Checkbox, Empty, InputNumber, message, Popconfirm, Select} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import Box from "@mui/material/Box";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import PrimaryButton from "../common/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../common/Buttons/SecondaryButton/SecondaryButton";
import TextArea from "antd/lib/input/TextArea";
import {parametersAPI} from "../../app/api";
import AttributesCodeModal from "./AttributesCodeModal/AttributesCodeModal";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import {gridDataHelper} from "../../helpers/localStorageHelper";
import moment from "moment";



const AttributesCode = () => {
    return (
        <PageWithSidebar>
            <AttributesCodeComponent/>
        </PageWithSidebar>
    )
}


const AttributesCodeComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useAppDispatch()
    const apiRef = useGridApiRef()
    const userData = useAppSelector(selectUserData)
    const gridRows = useAppSelector(selectActivityAttributeCodeRows)
    const gridColumns = useAppSelector(selectActivityAttributeCodeColumns)
    const [isLoading, setIsLoading] = useState(false)
    const [height, setHeight] = useState(window.innerHeight)
    const [gridContentHeight, setGridContentHeight] = useState(height - 500)
    const [sortModel, setSortModel] = useState<any>([])
    const rowsPerPageOptions = [300, 400, 500]
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1])
    const defaultGridRows = useAppSelector(selectActivityAttributeCodeDefaultRows)
    const [isCreateCodeModalOpened, setIsCreateCodeModalOpened] = useState(false)
    const currentDomain = useAppSelector(selectAttrCodesCurrentDomain)



    useEffect(() => {
        setIsLoading(true)
        dispatch(GetActivityAttributesThunk({
            act_code: null,
            agent_ref: Number(userData.user_ref),
            domain_cd: null,
            position: userData.position,
            sort_order: null
        }))
            .then(() => {
                setIsLoading(false)
            })
    }, [dispatch, userData])

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
    }

    let columns = gridColumns
        .map((g: ActivityCodesGridColumn) => {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH <= 100 ? 170 : g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                filterable: false
            }
        })
    columns.push({
        field: 'Actions',
        headerName: 'Actions',
        width: 200,
        dataType: '',
        col_pos: 11,
        col_value: '',
        col_options: '',
        filterable: false
    })

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSortModelChange = (newModel: any) => {
        setSortModel(newModel);
    };


    let rows = gridRows.filter((row: any) => {
        const entries = Object.entries(row);
        return entries.some(([key, value]) =>
            key === "ACT_CODE" && String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
    });


    const onUpdateActivitiesAttr = () => {
        const mergeArrays = (defaultRows:  ActivityCodesGridColumn[], rowsToModify:  ActivityCodeRow[]) => {
            return rowsToModify.map((rowToModify: ActivityCodeRow) => {
                const defaultRow = defaultRows.find((defaultRow: any) => defaultRow.id === rowToModify.id);
                if (defaultRow) {
                    return {
                        ...rowToModify,
                        old_act_code: defaultRow.ACT_CODE,
                        old_domain_cd: defaultRow.DOMAIN_CD,
                        old_position: defaultRow.POSITION,
                    };
                }
                return rowToModify;
            });
        };
        const transformAndRemoveFields = (array: ({ PROP_REQUIRED: number; ORDER_NUM: number; POSITION: string | null; DOMAIN_CD: string; ACT_CODE: string; SEND_SURVEYS: number; ACT_DESCRIPTION: string; ENUM_INDEX: number; old_domain_cd: string | number; id: number; old_position: string | number; old_act_code: string | number } | ActivityCodeRow)[]) => {
            return array.map((obj: ActivityCodeRow) => {
                return Object.entries(obj).reduce((newObj: any, [key, value]) => {
                    if (key !== 'id' && key !== 'ENUM_INDEX') {
                        newObj[key.toLowerCase()] = value;
                    }
                    return newObj;
                }, {});
            });
        };
        parametersAPI.updateActivityCodeAttributes({activity_lists: transformAndRemoveFields(mergeArrays(defaultGridRows, gridRows))})
            .then(() => {
                setIsLoading(true)
                setTimeout(() => {
                    dispatch(GetActivityAttributesThunk({
                        act_code: null,
                        agent_ref: Number(userData.user_ref),
                        domain_cd: null,
                        position: userData.position,
                        sort_order: null
                    }))
                        .then(() => {
                            setIsLoading(false)
                        })
                        .then(() => {
                            message.success('Activity codes was successfully updated')
                        })
                }, 1000)
            })
    }


    const handleOrderChange = (data: GridRowOrderChangeParams) => {
        dispatch(onChangeAttrOrderNum({newOrderNum: data.targetIndex, rowId: data.row.id, oldOrderNum: data.oldIndex}))
    }



    useEffect(() => {
        const localGridFilters = gridDataHelper.getAttrGridFilters()
        if(localGridFilters && localGridFilters.length){
            dispatch(setAttrCodesGridColumnsForFilters({
                columns: gridColumns.filter((c: any) => c.COL_NAME === 'DOMAIN_CD' || c.COL_NAME === 'POSITION').map((g: any) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE,
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
            dispatch(onSetFiltersForAttrCodesGrid({gridFilters: localGridFilters}))
        }
        else{
            dispatch(setAttrCodesGridColumnsForFilters({
                columns: gridColumns.filter((c: any) => c.COL_NAME === 'DOMAIN_CD' || c.COL_NAME === 'POSITION').map((g: any) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE,
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
        }

    }, [dispatch, gridColumns])

    let defaultAttrCodesGridColumns = useAppSelector(selectActivityAttributeCodeDefaultRows)


    useEffect(() => {
        const localGridFilters = gridDataHelper.getAttrGridFilters()
        let arrayForFiltering = defaultAttrCodesGridColumns
        // Function for converting grid numbers to euroFormat
        function convertToNumber(euroFormatStr: any) {
            if (typeof euroFormatStr === 'string') {
                const standardFormatStr = euroFormatStr.replace('.', '').replace(',', '.');
                return parseFloat(standardFormatStr)
            } else {
                return euroFormatStr
            }
        }
        if(localGridFilters && localGridFilters.length){
            localGridFilters.forEach((a: any) => {
                if (a.type === 'class java.lang.String' && a.property === "ACTIVITY_EXISTED") {
                    arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) =>
                        obj[a.property] !== null && a.value === 'yes' ? Number(obj[a.property]) === 1
                            : obj[a.property] !== null && a.value === 'Yes' ? Number(obj[a.property]) === 1
                                : obj[a.property] !== null && a.value === 'no' ? Number(obj[a.property]) === 0
                                    : obj[a.property] !== null && a.value === 'No' ? Number(obj[a.property]) === 0
                                        : null);
                }
                if (a.type === 'class java.lang.String' && a.property !== "ACTIVITY_EXISTED" && a.property !== "POSITION") {
                    arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.toLowerCase()));
                }
                if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value === 'no position') {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) => obj['POSITION'] === null);
                }
                if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value !== 'no position') {
                    arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.toLowerCase()));
                }
                if (a.type === 'class java.math.BigDecimal') {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) =>
                        obj[a.property] !== null && a.condition === 'less' ? convertToNumber(obj[a.property]) < convertToNumber(a.value)
                            : obj[a.property] !== null && a.condition === 'equals' ? convertToNumber(obj[a.property]) === convertToNumber(a.value)
                                : obj[a.property] !== null && a.condition === 'higher' ? convertToNumber(obj[a.property]) > convertToNumber(a.value)
                                    : obj[a.property] !== null && a.condition === 'between' ? convertToNumber(obj[a.property]) >= convertToNumber(a.startValue) && convertToNumber(obj[a.property]) <= convertToNumber(a.endValue) : null);
                }
                if (a.type === 'class java.time.LocalDateTime' || a.type === "class oracle.sql.TIMESTAMP") {
                    arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                        const date = moment(obj[a.property]);
                        return a.dateCondition === 'onDate' ? date.isSame(a.date, "second") : a.dateCondition === 'before' ? date.isBefore(a.date, "second") : date.isAfter(a.date, "second");
                    });
                }
                if (a.property === 'DOMAIN_CD') {
                    dispatch(onSetAttrCodesCurrentDomain(a.value))
                    dispatch(onSetAttrCodesReqs(arrayForFiltering.map((a: any, index: number) => a && {
                        ...a,
                        ORDER_NUM: index + 1
                    })))
                    dispatch(onSetAttrCodesGridFiltersButtonsSelected({
                        headerName: a.property,
                        isFilterSelected: true
                    }))
                    dispatch(onChangeAttrCodesGridFilterValue({title: a.property, value: a.value}))
                } else {
                    dispatch(onSetAttrCodesReqs(arrayForFiltering.map((a: any, index: number) => a && {
                        ...a,
                        ORDER_NUM: index + 1
                    })))
                    dispatch(onSetAttrCodesCurrentPosition(a.value))
                    dispatch(onChangeAttrCodesGridFilterValue({title: a.property, value: a.value}))
                    dispatch(onSetAttrCodesGridFiltersButtonsSelected({
                        headerName: a.property,
                        isFilterSelected: true
                    }))
                }
            })
        }

    }, [defaultAttrCodesGridColumns, dispatch])


    const onResetFilters = () => {
        dispatch(onSetAttrCodesReqs(defaultGridRows))
        dispatch(onAttrCodesResetFiltersButtonsSelected())
        dispatch(onSetFiltersForAttrCodesGrid({gridFilters: []}))
        dispatch(onSetAttrCodesCurrentDomain(null))
        dispatch(onSetAttrCodesCurrentPosition(null))
        gridDataHelper.removeAttrGridFilters()
    }

    const onResetSorting = () => {
        setSortModel([])
    }

    return (
        <div style={{
            marginLeft: '20px',
            marginTop: '42px',
            marginRight: '20px'
        }}
             className={'attributeCodesTable'}
        >
            <PageTitle
                isBackButton={true}
                backLinkSecondText={`Back`}
                top={'98px'}
                backLink={'/parameters'}
            />
            <div className={commonS.tableInner} style={{
                paddingBottom: '24px'
            }}>
                <div className={s.tableHeader}>
                    <h1 className={s.tableTitle}>Activity kinds</h1>
                    <div className={s.tableHeader__buttons}>
                        <div onClick={onUpdateActivitiesAttr}>
                            <SecondaryButton text={'Save changes'} width={'127px'} isWhiteBg={false} height={'40px'}/>
                        </div>
                        <div onClick={() => setIsCreateCodeModalOpened(true)}>
                            <PrimaryButton text={'Add kind'} isAddButton={true} width={'125px'} height={'40px'}/>
                        </div>
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                }}>
                    <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                        <img src={searchIcon} alt="search"/>
                    </InputLabel>
                    <input
                        className={s.search_input}
                        type="text"
                        value={searchTerm}
                        placeholder={'Search by Kind'}
                        onChange={handleSearchInputChange}
                    />

                    <div>
                        {searchTerm !== ''
                            &&
                            <p
                                onClick={() => setSearchTerm('')}
                                style={{
                                    marginBottom: '0',
                                    fontSize: '12px',
                                    color: '#9CA3AF',
                                    cursor: "pointer"
                                }}>x</p>
                        }
                    </div>
                </div>
                <PropertiesFilterButtons
                    shownColumns={columns.filter((c: any) => c.field === 'DOMAIN_CD' || c.field === 'POSITION')}
                    onResetFilters={onResetFilters}
                    onResetSorting={onResetSorting}
                    gridType={'AttrCodes'}
                />
                <div style={{
                    position: 'relative',
                    width: '100%',
                }}>
                    {columns.filter((c: any) => c.field === 'DOMAIN_CD' || c.field === 'POSITION').map((c: any) => {
                        return (
                            <PropertyFilterModal
                                gridType={'AttrCodes'}
                                textValue={c.field}
                            />
                        )
                    })}
                </div>
                {
                    isLoading
                        ?
                        <div style={{
                            height: gridContentHeight + 235,
                            width: '400px',
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                height: '300px',
                                width: '100%',
                                textAlign: 'center'
                            }}>
                                <Lottie
                                    // @ts-ignore
                                    config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                />
                            </div>

                            <p style={{
                                textAlign: 'center'
                            }}>{'Loading...'}</p>
                        </div>
                        :
                        <Box>
                            {
                                gridColumns.length
                                    ?
                                    <>
                                        <DataGridPro
                                            columns={columns.filter((c) => c.headerName !== "Enum_Index").map((column: any, index: number) => ({
                                                ...column,

                                                renderCell: (params: any) => {
                                                    return (
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={gridRows}
                                                                    key={index}
                                                                    dataType={column.dataType}
                                                                    setIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}
                                                                    userData={userData}
                                                        />
                                                    )


                                                },
                                                renderHeader: (params: any) => {
                                                    return (
                                                        <div style={{
                                                            display: 'flex',
                                                        }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Act Code'
                                                            ? 'Kind'
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Domain Cd'
                                                                ? 'Domain'
                                                                    : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Act Description'
                                                                        ? 'Description'
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                            }
                                                        </span>
                                                        </div>
                                                    );
                                                    // }

                                                },
                                            }))}
                                            sx={{
                                                borderRight: 'none',
                                                borderLeft: 'none',
                                                borderBottom: 'none',
                                                borderRadius: '0px',
                                                "& .MuiDataGrid-cellContent": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827'
                                                },
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 600,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiDataGrid-row:hover": {
                                                    backgroundColor: 'transparent'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected": {
                                                    backgroundColor: 'transparent'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected:hover": {
                                                    backgroundColor: 'transparent'
                                                },
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: 'none'
                                                },
                                                "& .MuiDataGrid-toolbarContainer": {
                                                    color: '#454545',
                                                    position: 'absolute',
                                                    top: '-50px',
                                                    right: '0px'
                                                },
                                                "& .MuiTablePagination-displayedRows": {
                                                    margin: '0',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 400,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiPagination-root": {
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '7px 0px'
                                                },
                                                "& .MuiButtonBase-root.Mui-selected": {
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                    width: '40px',
                                                    height: '40px',
                                                    margin: 0,
                                                    borderRight: '1px solid #D0D5DD',
                                                    borderLeft: '1px solid #D0D5DD',
                                                    borderTop: 'none',
                                                    borderBottom: 'none',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 500,
                                                    color: '#1D2939',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiPagination-ul": {
                                                    border: '1px solid #D0D5DD',
                                                    borderRadius: '8px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                    borderRadius: '0px',
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-row:nth-of-type(odd)": {
                                                    backgroundColor: 'transparent'
                                                },
                                                "& .MuiDataGrid-virtualScrollerRenderZone": {
                                                    width: '100%'
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    overflowY: 'scroll !important',
                                                    overflowX: 'scroll',
                                                    height: `${gridContentHeight + 100}px !important`,
                                                    minHeight: 'auto',
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    backgroundColor: '#FCFDFE'
                                                },
                                                "& .MuiDataGrid-root": {
                                                    marginTop: '50px'
                                                }
                                            }}
                                            apiRef={apiRef}
                                            getRowId={e => e.id}
                                            rows={rows}
                                            {...rows}
                                            headerHeight={41}
                                            hideFooterSelectedRowCount
                                            onSortModelChange={handleSortModelChange}
                                            sortModel={sortModel}
                                            autoPageSize={true}
                                            autoHeight={true}
                                            rowHeight={52}
                                            sortingOrder={['desc', 'asc']}
                                            onRowOrderChange={handleOrderChange}
                                            rowReordering={currentDomain !== null}
                                            // filterModel={filterModel}
                                            // onFilterModelChange={(model) => setFilterModel(model)}
                                            components={{
                                                Footer: () =>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginTop: '24px'
                                                    }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}>
                                                                <p
                                                                    className={s.footerText}
                                                                >
                                                                    Rows per page:
                                                                </p>
                                                                <Select
                                                                    value={rowsPerPage}
                                                                    onChange={handleRowsPerPageChange}
                                                                    className={s.rowsPerPage}
                                                                >
                                                                    {rowsPerPageOptions.map((option) => (
                                                                        <Select.Option key={option} value={option}>
                                                                            {option}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            Total rows: {rows.length}
                                                        </p>
                                                    </div>
                                            }}
                                        />
                                    </>
                                    :
                                    <div style={{
                                        height: `${gridContentHeight + 150}px`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Empty/>
                                    </div>

                            }
                        </Box>

                }

            </div>
            {
                isCreateCodeModalOpened
                &&
                <AttributesCodeModal
                    setIsOpenedModal={(isOpenedModal) => setIsCreateCodeModalOpened(isOpenedModal)}
                    setIsLoading={(isLoading) => setIsLoading(isLoading)}
                    loading={isLoading}

                />
            }
        </div>
    );
};


const CustomCell = React.memo(({
                                   value,
                                   field,
                                   params,
                                   setIsLoading,
                                   userData,
                               }: any) => {

    const dispatch = useAppDispatch()
    const defaultGridRows = useAppSelector(selectActivityAttributeCodeDefaultRows)
    if (field === "PROP_REQUIRED") {
        const handleChange = (e: any) => {
            dispatch(changeActivityCodeRowValue({
                fieldName: field,
                itemId: params.id,
                value: e.target.checked === true ? 1 : 0
            }))
        }
        return (
            <div>
                <Checkbox
                    onChange={handleChange}
                    name={value}
                    defaultChecked={value === 1}
                    style={{
                        marginRight: '8px'
                    }}
                />
                {/*<span>{value}</span>*/}
            </div>
        )
    } else if (field === "SEND_SURVEYS") {
        const handleChange = (e: any) => {
            dispatch(changeActivityCodeRowValue({
                fieldName: field,
                itemId: params.id,
                value: e.target.checked === true ? 1 : 0
            }))
        }
        return (
            <div>
                <Checkbox
                    onChange={handleChange}
                    name={value}
                    defaultChecked={value === 1}
                    style={{
                        marginRight: '8px'
                    }}
                />
            </div>
        )
    } else if (field === "ORDER_NUM") {
        return (
            <InputNumber
                value={value}
                className={s.textArea}
                min={1}
                disabled={true}
                style={{height: '100%', resize: 'none'}}
            />
        )
    } else if (field === 'Actions') {

        const onCodeDelete = () => {
            const defaultRow: any = defaultGridRows.find((defaultRow: any) => defaultRow.id === params.row.id);
            let reqData = Object.entries(params.row).reduce((newObj: any, [key, value]) => {
                if (key !== 'id' && key !== 'ENUM_INDEX') {
                    newObj[key.toLowerCase()] = value;
                }
                return newObj;
            }, {});
            reqData = {
                ...reqData,
                old_act_code: defaultRow.ACT_CODE,
                old_domain_cd: defaultRow.DOMAIN_CD,
                old_position: defaultRow.POSITION,
            }
            parametersAPI.deleteActivityCode(reqData)
                .then(() => {
                    setIsLoading(true)
                    dispatch(GetActivityAttributesThunk({
                        act_code: null,
                        agent_ref: Number(userData.user_ref),
                        domain_cd: null,
                        position: userData.position,
                        sort_order: null
                    }))
                        .then(() => {
                            setIsLoading(false)
                        })
                        .then(() => {
                            message.success('Activity code was successfully deleted')
                        })
                })
        }


        return (

            <Popconfirm
                title={`Delete the kind - ${params.row.ACT_CODE}, domain - ${params.row.DOMAIN_CD}${params.row.POSITION !== null ? `, position - ${params.row.POSITION}` : ''} ?`}
                // description="Are you sure to delete this task?"
                onConfirm={onCodeDelete}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                className={'attrCodeDelete'}
                style={{
                    width: 250
                }}
            >
                <button
                    // onClick={onCodeDelete}
                    className={s.deleteCode}
                >
                    Delete
                </button>
            </Popconfirm>
        )
    } else {
        const handleChange = (e: any) => {
            dispatch(changeActivityCodeRowValue({fieldName: field, itemId: params.id, value: e.target.value}))
        }
        return (
            <TextArea
                value={value}
                className={s.textArea}
                style={{height: '100%', resize: 'none'}}
                onChange={(e) => handleChange(e)}
            />

        )
    }


});

export default AttributesCode;