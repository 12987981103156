import {Collapse, Input, theme} from 'antd';
import React, {useEffect} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {Tab, Tabs, Typography} from '@mui/material';
import Box from "@mui/material/Box";
import { CaretRightOutlined } from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {FaqsType, GetFaqsThunk, SearchFaqsThunk, selectFaqsArray} from "../../store/parametersReducer";
import s from './FAQs.module.scss'
const { Panel } = Collapse;


const FaQs = () => {
    return (
        <PageWithSidebar>
            <FaQsPageComponent/>
        </PageWithSidebar>
    )
}

const FaQsPageComponent = () => {
    const [value, setValue] = React.useState(0);
    const dispatch = useAppDispatch()
    const faqs = useAppSelector(selectFaqsArray)


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(GetFaqsThunk({category: value === 0 ? 'Admin' : 'User'}))
    }, [dispatch, value])


    const getItems = (data: FaqsType[], panelStyle: any) => {
        return data.map((item, index) => ({
            key: item.id.toString(),
            label: `${item.question}`,
            children: <p>{item.answer}</p>,
            style: panelStyle,
        }));
    };

    const panelStyle = {marginBottom: 24,
        background: '',
        borderRadius: '',
        border: 'none',};
    const items = getItems(faqs, panelStyle);

    console.log(items)

    const onSearchQuestions = (question: string) => {
        console.log(question)
        dispatch(SearchFaqsThunk({activity_ref: 0, question: question}))
    }

    return (
        <div>
        <div className={s.header}>
            <h1 className={s.header__title}>Frequently Asked Questions</h1>
            <h2 className={s.header__subtitle}>Have any questions?</h2>
            <div>
                <Input className={s.header__search} placeholder={'Type any question here'} onChange={(e) => onSearchQuestions(e.target.value)}/>
            </div>
        </div>
            <Tabs
                value={value}
                onChange={handleChange}
                style={{
                    marginBottom: '12px'
                }}
            >
                <Tab label="Admin"/>
                <Tab label="User"/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <div>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        // Uncomment and set your background style if needed
                        // style={{
                        //   background: token.colorBgContainer,
                        // }}
                    >
                        {items.map(item => (
                            <Panel header={item.label} key={item.key} style={item.style}>
                                {item.children}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        // Uncomment and set your background style if needed
                        // style={{
                        //   background: token.colorBgContainer,
                        // }}
                    >
                        {items.map(item => (
                            <Panel header={item.label} key={item.key} style={item.style}>
                                {item.children}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </TabPanel>

        </div>
    );
};


function TabPanel(props: any) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

export default FaQs;