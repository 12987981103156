import React, {InputHTMLAttributes} from 'react';
import s from './../../AllPropertiesAddressForm/AllPropertiesAddressForm.module.scss'
import {ReactComponent as AddressWarningIcon} from './../../../img/icons/alert-circle.svg'
import Tooltip from "../../Tooltip/Tooltip";

interface MyInputComponentProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    withLabel: boolean;
    lineDirection: 'horizontal' | 'vertical';
    labelClassName?: string;
    labelText: string;
    errorMessage?: string;
    showError?: boolean
    showMainAddress?: boolean,
    mainAddress?: string
}

const MyInputComponent: React.FC<MyInputComponentProps> = ({
                                                               type,
                                                               value,
                                                               onChange,
                                                               className,
                                                               withLabel,
                                                               labelClassName,
                                                               labelText,
                                                               lineDirection,
                                                               errorMessage,
                                                               showError,
                                                               showMainAddress,
                                                               mainAddress,
                                                               ...restProps
                                                           }) => {


    // const showError = errorMessage && !value;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: lineDirection === 'horizontal' ? 'row' : 'column',
                position: 'relative'
            }}
        >
            {withLabel && (
                <label className={`${labelClassName || ''}`}>{labelText}</label>
            )}
            <input
                type={type}
                value={value}
                onChange={onChange}
                className={`${className || ''}`}
                required
                {...restProps}
                style={{
                    border: showError ? '1px solid #FDA29B' : '1px solid #D0D5DD',
                }}
            />
            {
                showError &&
                // <div style={{
                //     position: 'absolute',
                //     top: '44px',
                //     right: '10px',
                //     zIndex: '100',
                //     cursor: 'pointer'
                // }}
                //      onMouseEnter={() => setIsHovered(true)}
                //      onMouseLeave={() => setIsHovered(false)}
                // >
                //
                // </div>
                <Tooltip text="Fill in the field as in the example:'Bělehradská 222/128'" classname={'addressTooltip'}>
                    <AddressWarningIcon/>
                </Tooltip>
            }
            {showError && (
                <p className={s.errorMessage}>{errorMessage}</p>
            )}
            {
                showMainAddress
                &&
                <p className={s.shownAddress}>Original main address: {mainAddress}</p>
            }
        </div>
    );
};

export default MyInputComponent;