import {Button, Form} from 'antd'
import moment, {Moment} from 'moment'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {
    GetFraudReportFileThunk,
    GetReportFileThunk,
    selectActivityReportsDates,
    selectCompanies, setActivityReportsDates
} from '../../../store/reportReducer'
import {ReportTypeDataType, ReportTypes} from '../../../types/reportTypes'
import DatePickerFormItem from '../../common/DatePickerFormItem/DatePickerFormItem'
import DomainFormItem from '../../common/DomainFormItem/DomainFormItem'
import classes from './ReportForm.module.css'

const ReportForm: React.FC<ReportFormPropTypes> = (
    {setIsExported, reportTypeData, isFraud, reportName, fraudValueCount, dateType, setDateType}
) => {
    const dispatch = useAppDispatch()
    const companies = useAppSelector(selectCompanies)
    const defaultValueDomainCode = 'OR'
    const reportDates = useAppSelector(selectActivityReportsDates)

    const defaultValueDates = !isFraud ?
        reportTypeData.reportType === 'activityReportForm'
        ? [moment().utc().startOf('week'), moment().utc()]
        : [moment().utc().startOf('month').subtract(6, 'months'), moment().utc()]
        : [moment().utc().startOf('month'), moment().utc()]


    useEffect(() => {
        dispatch(setActivityReportsDates({start_period: defaultValueDates[0], end_period: defaultValueDates[1]}))
    } ,[dispatch])

    const [form] = Form.useForm()

    // const [dateType, setDateType] = useState<PickerType>(reportTypeData.reportType === 'activityReportForm' ? 'currentWeek' : 'period')
    const [isExporting, setIsExporting] = useState(false)

    const handleExport = (formData: { domain: string, dates: [Moment, Moment] }) => {
        setIsExporting(true)
        if (!isFraud) {
            dispatch(GetReportFileThunk({
                requestData: {
                    domain_code: formData.domain,
                    start_period: reportDates.start_period,
                    end_period: reportDates.end_period
                },
                reportType: reportTypeData.reportType as ReportTypes
            }))
                .then((response) => {
                    setIsExporting(false)
                    const data = response.payload
                    if (!response.type.includes('rejected')) {
                        const blob = new Blob([data as BlobPart], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement('a')
                        const domainName = companies.find(company => company.domain_code === formData.domain)
                        link.href = url
                        link.download = `${reportTypeData.reportTitle} (${dateType === 'date'
                            ? moment(reportDates.start_period).format('DD.MM.YYYY')
                            : moment(reportDates.start_period).format('DD.MM.YYYY') + '-' + moment(reportDates.end_period).format('DD.MM.YYYY')
                        })-${domainName?.domain_code}.xlsx`
                        link.click()
                        setIsExported(true)
                    }
                })
        } else {
            dispatch(GetFraudReportFileThunk({
                reqData: {
                    value_count: fraudValueCount,
                    start_period: reportDates.start_period,
                    end_period: reportDates.end_period
                },
                reportType: reportName
            }))
                .then((response) => {
                    setIsExporting(false)
                    const data = response.payload
                    if (!response.type.includes('rejected')) {
                        const blob = new Blob([data as BlobPart], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement('a')
                        link.href = url
                        link.download = `${reportName} (${dateType === 'date'
                            ? moment(reportDates.start_period).format('DD.MM.YYYY')
                            : moment(reportDates.start_period).format('DD.MM.YYYY') + '-' + moment(reportDates.end_period).format('DD.MM.YYYY')
                        }).xlsx`
                        link.click()
                        setIsExported(true)
                    }
                })
        }


    }


    return (
        <Form
            name={reportTypeData.reportType}
            form={form}
            initialValues={{domain: defaultValueDomainCode, dates: defaultValueDates}}
            className={classes.formWrapper}
            onFinish={handleExport}
            autoComplete='off'
            layout='vertical'
        >
            <div className={classes.formItemWrapper} style={{
                marginTop: isFraud ? '-9px' : '0'
            }}>
                <DatePickerFormItem
                    type={dateType}
                    form={form}
                    setDateType={setDateType}
                    defaultValue={defaultValueDates as [Moment, Moment]}
                />
            </div>
            {
                !isFraud
                &&
                <div className={classes.formItemWrapper}>
                    <DomainFormItem form={form}/>
                </div>
            }


            <div className={classes.buttonsArea}>
                <Link to='/control-panel'>
                    <Button>
                        Cancel
                    </Button>
                </Link>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={isExporting}
                    >
                        Export
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}

interface ReportFormPropTypes {
    setIsExported: Dispatch<SetStateAction<boolean>>
    reportTypeData: ReportTypeDataType
    isFraud: boolean
    reportName?: string
    fraudValueCount?: number | null
    dateType?: any
    setDateType?: any
}

export default ReportForm

