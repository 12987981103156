import { Link } from 'react-router-dom'
import classes from '../../ControlPanel/ControlPanel.module.css'
import React, {useEffect} from "react";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {useAppDispatch} from "../../../app/hooks";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {clearPropertiesForEmail} from "../../../store/propertiesReducer";
import { ReactComponent as EmailFraudIcon } from '../../../img/icons/maxPropsEmail.svg'
import { ReactComponent as ContactFraudIcon } from '../../../img/icons/maxPropsContacts.svg'
import { ReactComponent as OrgFraudIcon } from '../../../img/icons/maxPropsOrganizations.svg'
const FraudReports = () => {
    return (
        <PageWithSidebar>
            <FraudReportsComponent/>
        </PageWithSidebar>
    )
}


const FraudReportsComponent = () => {
    const dispatch = useAppDispatch()
    const panelItems = [
        {id: 1, title: 'Max properties in one email exceeded', img: EmailFraudIcon, link: '/fraud-reports/max-props-in-one-email'},
        {id: 2, title: 'Max properties in contacts exceeded',  img: ContactFraudIcon, link: '/fraud-reports/max-props-in-contacts'},
        {id: 3, title: 'Max properties in organizations exceeded' ,img: OrgFraudIcon,   link: '/fraud-reports/max-props-in-organizations'},
    ]


    useEffect(() => {
        gridDataHelper.removePropertiesGridFilters()
        gridDataHelper.removeConditionGridFiltersString()
        dispatch(clearPropertiesForEmail([]))
    },[dispatch])

    return (
        <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
                <div className={classes.titleWrapper}>
                    <h1>
                        Fraud Reports
                    </h1>
                </div>

                <div className={classes.panelWrapper}>
                    {panelItems.map((item, index) => (
                        <Link to={item.link} className={`${classes.panelItem} ${classes['item' + index]}`} key={item.id}>
                            <item.img />
                            {item.title}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FraudReports