import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import classes from "../ReportPage.module.css";
import ReportForm from "../ReportForm/ReportForm";
import ReportChart from "../ReportChart/ReportChart";
import SuccessMessage from "../../common/SuccessMessage/SuccessMessage";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetFraudReportsDiagramThunk,
    GetReportsCategoriesThunk, selectFraudReportsDates,
} from "../../../store/reportReducer";
import {InputNumber} from "antd";
import {PickerType} from "../../../types/dateTypes";


const FraudReportsPage = () => {
    return (
        <PageWithSidebar>
            <FraudReportsComponent/>
        </PageWithSidebar>
    )
}


const FraudReportsComponent = () => {
    const currentPath = window.location.pathname
    const currentReportName = currentPath === '/fraud-reports/max-props-in-one-email' ? 'Max properties in one email exceeded' : currentPath === '/fraud-reports/max-props-in-contacts' ? 'Max properties in contacts exceeded' : 'Max properties in organizations exceeded'
    const dispatch = useAppDispatch()
    const [isExported, setIsExported] = useState(false)
    const reportTypeData = {}
    const [count, setCount] = useState<number | null>(5);
    const [isLoading, setIsLoading] = useState(true)
    const fraudReportsDates = useAppSelector(selectFraudReportsDates)
    const [dateType, setDateType] = useState<PickerType>('currentWeek' )
    const handleCountChange = (value: number | null) => {
        setCount(value);
    };

    useEffect(() => {
        dispatch(GetReportsCategoriesThunk(2))
        dispatch(GetFraudReportsDiagramThunk({
            start_period: fraudReportsDates.start_period,
            end_period: fraudReportsDates.end_period,
            value_count: count
        }))
        setIsLoading(true)
    }, [count, fraudReportsDates])

    console.log(dateType)


    return (
        <div style={{
            padding: '40px 50px 50px 30px'
        }}>
            <PageTitle title={currentReportName}
                       backLink='/fraud-reports'
                       isBackButton={true}
                       backLinktext={'Fraud Reports'}
                       top={'153px'}
            />
            <div className={classes.contentWrapper} style={{
                marginTop: '50px'
            }}>
                <div>
                    <div style={{
                        padding: '0 0 0 30px'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '16px'
                        }}>
                            <label style={{
                                marginBottom: '6px'
                            }} className={classes.label}>Count:</label>
                            <InputNumber value={count} onChange={handleCountChange} min={1}/>
                        </div>
                    </div>
                    <ReportForm
                        setIsExported={setIsExported}
                        reportTypeData={reportTypeData}
                        isFraud={true}
                        reportName={currentReportName}
                        fraudValueCount={count}
                        dateType={dateType}
                        setDateType={setDateType}
                    />
                </div>
                <ReportChart
                    reportTypeData={reportTypeData}
                    isFraud={true}
                    isLoading={isLoading}
                    dateType={dateType}
                    setIsLoading={(isLoading) => setIsLoading(isLoading)}
                />
            </div>

            <SuccessMessage
                text='Your report has been successfully exported'
                onOk={() => {
                    setIsExported(false)
                }}
                isOpen={isExported}
            />
        </div>
    );
};

export default FraudReportsPage;
