import React from 'react';
import {setIsMobileFiltersOpened} from "../../../store/utilityReducer";
import mobileFilters from "../../../img/icons/mobileMenuFilters.svg";
import {useAppDispatch} from "../../../app/hooks";
import s from './MobileFiltersButton.module.scss'

interface MobileFiltersButtonProps {
    customMargin?: string
}

const MobileFiltersButton = ({customMargin} : MobileFiltersButtonProps) => {
    const dispatch = useAppDispatch()
    return (
        <button
            onClick={() => dispatch(setIsMobileFiltersOpened(true))}
            className={s.mobileFiltersButton}
            style={{
                margin: customMargin ? customMargin : '0 20px 10px 10px'
            }}

        >
            <img src={mobileFilters} alt="mobile-filters"/>
        </button>
    );
};

export default MobileFiltersButton;