import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {utilityDataHelper} from "../../../helpers/localStorageHelper";
import {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {SignInDataType} from "../../../types/userTypes";
import {LoginThunk} from "../../../store/userReducer";
import classes from "../SignIn.module.scss";
import CryptoJS from 'crypto-js';
import {selectPublicKey} from "../../../store/parametersReducer";


const SignInForm = () => {
    const dispatch = useAppDispatch()
    const lastLoggedEmail = utilityDataHelper.getUserLastEmailLogged()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const CRYPTOR_KEY = useAppSelector(selectPublicKey)
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIp();
    }, []);

    const handleSignIn = async (formData: SignInDataType) => {
        try {
            setIsLoading(true);

            const encryptedPassword = encrypt(formData.password, CRYPTOR_KEY);
            const decryptedPassword = decrypt(encryptedPassword, CRYPTOR_KEY); // For demo, in production you won't decrypt here

            console.log('Decrypted Password:', decryptedPassword); // Log decrypted password (for demo purposes)

            // Dispatch the login action with encrypted password
            dispatch(LoginThunk({
                ...formData,
                password: encryptedPassword,
                ip_address: ipAddress
            })).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                console.error('Login failed:', error);
                setIsLoading(false);
            });

        } catch (error) {
            console.error('Error during sign in:', error);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (lastLoggedEmail !== false) {
            form.setFieldsValue({username: lastLoggedEmail})
        }
    }, [form, lastLoggedEmail])



    // Encrypt function using CryptoJS
    function encrypt(text: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    // Decrypt function using CryptoJS
    function decrypt(encryptedText: string, base64Key: string): string {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }



    return (
        <Form
            name='login'
            onFinish={handleSignIn}
            autoComplete='off'
            className={classes.signInForm}
            layout='vertical'
            validateTrigger='onBlur'
            requiredMark={false}
            form={form}
        >
            <Form.Item
                name='username'
                label='E-mail'
                rules={[
                    {required: true, message: 'Please enter your email!'},
                    {type: 'email', message: 'Invalid e-mail!'},
                ]}
            >
                <Input placeholder='Enter E-mail address'/>
            </Form.Item>

            <Form.Item
                name='password'
                label='Password'
                rules={[{required: true, message: 'Please enter your password!'}]}
            >
                <Input.Password placeholder='Enter Password'/>
            </Form.Item>

            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    style={{width: '100%', marginTop: '20px'}}
                    loading={isLoading}
                >
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SignInForm