import {Table} from 'antd'
import PageTitle from '../../common/PageTitle/PageTitle'
import classes from './MarketingReports.module.css'
import {ReactComponent as RedDot} from './../../../img/icons/redDot.svg'
import {ReactComponent as GreenDot} from './../../../img/icons/greenDot.svg'
import React, {useEffect, useState} from 'react'
import UploadTemplatesModal from './UploadTemplatesModal/UploadTemplatesModal'
import {MarketingReportType} from '../../../types/parametersTypes'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetMarketingTemplatesThunk,
    selectMarketingTemplates, setClosedModal,
    setTemplatesForImageUpload
} from "../../../store/parametersReducer";
import moment from "moment";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";


const MarketingReports = () => {
    return (
        <PageWithSidebar>
            <MarketingReportsComponent />
        </PageWithSidebar>
    )
}

const MarketingReportsComponent = () => {
    const dispatch = useAppDispatch()
    const [openedRecord, setOpenedRecord] = useState<MarketingReportType | null>(null)
    const marketingTemplates = useAppSelector(selectMarketingTemplates)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)

    useEffect(() => {
        dispatch(GetMarketingTemplatesThunk())
    }, [dispatch])

    const dataSource: MarketingReportType[] = Object.values(marketingTemplates)

    const onCloseModal = () => {
        setOpenedRecord(null)
        dispatch(setTemplatesForImageUpload([]))
    }

    const onCloseEmptyModal = () => {
        setOpenedRecord(null)
        dispatch(setTemplatesForImageUpload([]))
        dispatch(setClosedModal(false))
    }

    const onOpenModal = (record: any) => {
        setOpenedRecord(record)
        dispatch(setClosedModal(true))
    }




    return (
        <div className='pageWrapper'>
            <PageTitle
                title='Marketing reports'
                backLink='/parameters'
                isBackButton={true}
                backLinktext={'Parameters'}
                top={currentScreenWidth! > 768 ? '108px' : '95px'}

            />
            <div className={`${classes.tableWrapper} marketingTable`}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record?.domain}
                    onRow={(record) => ({onClick: () => onOpenModal(record)})}
                />
            </div>
            {/*// @ts-ignore*/}
            <UploadTemplatesModal openedRecord={openedRecord} closeModal={onCloseModal} domains={dataSource} closeEmptyModal={onCloseEmptyModal}/>
        </div>
    )
}

const IsUploadedColumn: React.FC<{ isUploaded: boolean }> = ({isUploaded}) => {

    return (
        <div className={classes.isUploadedColumn}>
            {isUploaded ? <><GreenDot/>Uploaded</> : <><RedDot/>Not Uploaded</>}
        </div>
    )
}
const columns: any = [
    {
        title: 'Template',
        dataIndex: 'label',
        key: 'label',
        render: () => {
            return (<div>Marketing report</div>)
        },
        sorter: (a: any, b: any) => a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase())
    },
    {
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
        render: (b: any, a: any) => {
            return (<div>{a[0].domain}</div>)
        },
        sorter: (a: any, b: any) => a[0]?.domain?.toLowerCase()?.localeCompare(b[1]?.domain?.toLowerCase())
    },
    {
        title: 'Page 1 (EN) ',
        dataIndex: 'page1EN',
        key: 'page1EN',
        render: (_: any, data: any) => {
            const currenRecord = data.find((d: any) => d.ordinalNumber === 3)
            return (
                <div>
                    <IsUploadedColumn isUploaded={currenRecord.isUploaded}/>
                    {currenRecord.dateModified && currenRecord.isUploaded && <p className={classes.date}>{moment(currenRecord.dateModified).format('YYYY.MM.DD')}</p> }
                </div>

            )
        },
        // sorter: (a:any, b:any) =>  a.isUploaded - b.isUploaded
    },
    {
        title: 'Page 2 (EN) ',
        dataIndex: 'page2EN',
        key: 'page2EN',
        render: (_: any, data: any) => {
            const currenRecord = data.find((d: any) => d.ordinalNumber === 4)
            return (
                <div>
                    <IsUploadedColumn isUploaded={currenRecord.isUploaded}/>
                    {currenRecord.dateModified && currenRecord.isUploaded && <p className={classes.date}>{moment(currenRecord.dateModified).format('YYYY.MM.DD')}</p> }
                </div>
            )
        },
        // sorter: (a:any, b:any) =>  a.isUploaded - b.isUploaded
    },
    {
        title: 'Page 1 (CZ) ',
        dataIndex: 'page1CZ',
        key: 'page1CZ',
        render: (_: any, data: any) => {
            const currenRecord = data.find((d: any) => d.ordinalNumber === 1)
            return (
                <div>
                    <IsUploadedColumn isUploaded={currenRecord.isUploaded}/>
                    {currenRecord.dateModified && currenRecord.isUploaded && <p className={classes.date}>{moment(currenRecord.dateModified).format('YYYY.MM.DD')}</p> }
                </div>
            )
        },
        // sorter: (a:any, b:any) =>  a.isUploaded - b.isUploaded
    },
    {
        title: 'Page 2 (CZ) ',
        dataIndex: 'page2CZ',
        key: 'page2CZ',
        render: (_: any, data: any) => {
            const currenRecord = data.find((d: any) => d.ordinalNumber === 2)
            return (
                <div>
                    <IsUploadedColumn isUploaded={currenRecord.isUploaded}/>
                    {currenRecord.dateModified && currenRecord.isUploaded && <p className={classes.date}>{moment(currenRecord.dateModified).format('YYYY.MM.DD')}</p> }
                </div>
            )
        },
        // sorter: (a:any, b:any) =>  a.isUploaded - b.isUploaded
    },
]
export default MarketingReports
