import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

declare const clients: Clients;

function clearCachesAndReloadClients(registration: any) {
    return caches.keys().then(cacheNames => {
        return Promise.all(cacheNames.map(cacheName => {
            return caches.delete(cacheName).then(() => {
                console.log(`Cache ${cacheName} deleted.`);
            });
        }));
    }).then(() => {
        console.log('All caches have been cleared.');
        return registration.clients.matchAll({type: 'window'});
    }).then(clients => {
        clients.forEach((client: any) => client.navigate(client.url));
    });
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
        .then(registration => {
            if ('caches' in window) {
                console.log('Attempting to clear caches...');
                clearCachesAndReloadClients(registration).catch(error => {
                    console.error('Cache clearing failed, retrying...', error);
                    setTimeout(() => {
                        clearCachesAndReloadClients(registration);
                    }, 3000);
                });
            }
        })
        .catch(error => {
            console.error('Service worker registration failed:', error);
        });
} else {
    console.log('Service Worker and Cache API are not supported in this browser.');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

