import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import PropertyFilterButton from "../../PropertiesPage/PropertyFilterButton/PropertyFilterButton";
import s from "../../PropertiesPage/Properties.module.scss";
import moreIcon from "../../../img/icons/more-vertical.svg";
import PropertyFilterModal from "../../PropertiesPage/PropertyFilterModal/PropertyFilterModal";


interface PropertiesFilterButtonsType {
    shownColumns: any[]
    onResetFilters: () => void
    onResetSorting: () => void
    gridType: string
    isActivity?: boolean
    isMobile?: boolean
}

const PropertiesFilterButtons = ({
                                     shownColumns,
                                     onResetFilters,
                                     onResetSorting,
                                     gridType,
                                     isMobile
                                 }: PropertiesFilterButtonsType) => {
    const [onOpenClearBtns, setOnOpenClearBtns] = useState(false)
    const [showPrevButton, setShowPrevButton] = useState(false);
    const outsideDivRef = useRef<HTMLDivElement>(null);
    const handleSlideNextTransitionStart = () => {
        setShowPrevButton(true);
    };

    // useEffect for tracking outside the reset Filters/Sorting click to close it , when user click outside this block
    useEffect(() => {
        const closeDivOutsideClick = (event: MouseEvent) => {
            if (outsideDivRef.current && !outsideDivRef.current.contains(event.target as Node)) {
                setOnOpenClearBtns(false);
            }
        };

        document.addEventListener('click', closeDivOutsideClick);
        return () => {
            document.removeEventListener('click', closeDivOutsideClick);
        };
    }, []);

    // shownColumns = gridType === 'All Properties/Upcoming' ? shownColumns.filter((c: any) => c?.field !== 'PROP_ADDR_STREET_ADDRESS') : shownColumns

    return (
        <>
            {shownColumns.length  ?
                !isMobile
                    ?
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '16px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%'
                            }}
                        >
                            {
                                gridType !== 'Activities Grid/Create/Update'
                                &&
                                <Swiper
                                    spaceBetween={4}
                                    slidesPerView={"auto"}
                                    loop={gridType === 'All Properties/Upcoming'}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}
                                    style={{
                                        backgroundColor: '#fff',
                                        // width: 'fit-content'
                                    }}
                                >
                                    {shownColumns.filter((col: any) => col?.width !== 0).map((c: any, index: number) => {
                                        if (index < 1) {
                                            return (
                                                <SwiperSlide
                                                    style={{
                                                        marginLeft: shownColumns.length > 12 && !showPrevButton ? '25px' : '0'
                                                    }}
                                                    key={index}
                                                >
                                                    <PropertyFilterButton
                                                        gridType={gridType}
                                                        textValue={c.field}
                                                        buttonTitle={c.headerName}
                                                    />
                                                </SwiperSlide>
                                            )
                                        } else {
                                            return (
                                                <SwiperSlide
                                                    key={index}
                                                >
                                                    <PropertyFilterButton
                                                        gridType={gridType}
                                                        textValue={c.field}
                                                        buttonTitle={c.headerName}
                                                    />
                                                </SwiperSlide>
                                            )
                                        }
                                    })}
                                    {
                                        shownColumns.length < 12
                                            ?
                                            null
                                            :
                                            <>
                                                <PrevArrow onClick={handleSlideNextTransitionStart}/>
                                                <NextArrow onClick={handleSlideNextTransitionStart}/>
                                            </>

                                    }

                                </Swiper>
                            }

                            {
                                !isMobile
                                &&
                                <div
                                    onClick={() => setOnOpenClearBtns(!onOpenClearBtns)}
                                    className={s.clearBtns}
                                    ref={outsideDivRef}
                                >
                                    <img src={moreIcon} alt="more"/>
                                    {
                                        onOpenClearBtns
                                        &&
                                        <div
                                            className={s.clearBtns__btns}
                                            style={{
                                                right: '3px'
                                            }}
                                        >
                                            {
                                                gridType !== 'Activities Grid/Create/Update'
                                                &&
                                                <div
                                                    onClick={onResetFilters}
                                                    className={s.clearFiltersBtn}
                                                >
                                                    Clear filters
                                                </div>
                                            }

                                            <div
                                                onClick={onResetSorting}
                                                className={s.clearFiltersBtn}
                                            >
                                                Clear sorting
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                        </div>
                    </div>
                    :
                    <div style={{
                        padding: '0 20px'
                    }}>
                        {shownColumns.filter((col: any) => col?.width !== 0).map((c: any, index: number) => {
                            return (
                                <>
                                    <PropertyFilterButton
                                        gridType={gridType}
                                        textValue={c.field}
                                        buttonTitle={c.headerName}
                                        key={index}
                                        isMobile={isMobile}

                                    />
                                    <PropertyFilterModal
                                        gridType={gridType}
                                        textValue={c.field}
                                        isMobile={isMobile}
                                        key={c.field}
                                    />
                                </>


                            )
                        })}
                    </div>
                :
                null
            }

        </>
    );
};


const PrevArrow = (props: any) => (
    <button className="swiper-button-prev" {...props} onClick={props.onClick}>
        <svg width="40" height="28" viewBox="0 0 40 28" style={{transform: 'rotate(180deg)'}} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1387_36614)">
                <path
                    d="M17.3996 20.4002L16.6996 19.7002L22.2996 14.1002L16.5996 8.4002L17.2996 7.7002L23.6996 14.1002L17.3996 20.4002Z"
                    fill="#424242"/>
            </g>
            <defs>
                <clipPath id="clip0_1387_36614">
                    <rect width="24" height="20" fill="white" transform="translate(8 4)"/>
                </clipPath>
            </defs>
        </svg>
    </button>
);

const NextArrow = (props: any) => (
    <button className="swiper-button-next" {...props} onClick={props.onClick}>
        <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1387_36614)">
                <path
                    d="M17.3996 20.4002L16.6996 19.7002L22.2996 14.1002L16.5996 8.4002L17.2996 7.7002L23.6996 14.1002L17.3996 20.4002Z"
                    fill="#424242"/>
            </g>
            <defs>
                <clipPath id="clip0_1387_36614">
                    <rect width="24" height="20" fill="white" transform="translate(8 4)"/>
                </clipPath>
            </defs>
        </svg>
    </button>
)

export default PropertiesFilterButtons;