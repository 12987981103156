import Sidebar from "../Sidebar/Sidebar"
import s from './PageWithSidebar.module.scss'
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCurrentScreenWidth, setCurrentScreenWidth} from "../../store/utilityReducer";


const PageWithSidebar:React.FC<{children: any, tableMargins?: string}> = ({children, tableMargins}) => {
  const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const handleResize = () => {
      dispatch(setCurrentScreenWidth(window.innerWidth))
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div
        className={s.inner}
    >
      {
          currentScreenWidth! > 768
          &&
          <Sidebar />
      }

      <div style={{width: '100%', overflowX: 'hidden',  margin: tableMargins}}>
        {children}
      </div>
    </div>
  )
}

export default PageWithSidebar
