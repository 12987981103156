import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {AsyncThunkConfig, RootState} from "./store";
import {
    ActivitiesReqDataType,
    contactsApi,
    CreatePropertyOffer,
    gridApi, PropertyActivityType,
    requirementsApi, userAPI,
    AllPropertiesReqItem, AddressPropertyUpdated, countryApi, GridReqType, templatesApi, SoldPropertyReqData, eventsApi,
} from "../app/api";
import {AppStatusType, setAppStatus, setSuccessMessage} from "./appStatusReducer";
import {GridSortItem} from "@mui/x-data-grid";
import {gridDataHelper} from "../helpers/localStorageHelper";
import moment, {Moment} from "moment";
import {PropertiesColumnType} from "../types/propertiesTypes";
import {getAbortController} from "../helpers/AbortControllerManager";
import {onSetIsBrokerageReqsGridDataLoading} from "./brokerageReqsReducer";
import {GridColumns} from "../types/commonTypes";


type GridColumnDataType = {
    COL_NAME: string
    COL_OPTIONS: string
    COL_POS: number
    COL_TITLE: string
    COL_TYPE: string
    COL_WIDTH: number
}

export type ColumnType = {
    gridColumnData: [
        {
            column: GridColumnDataType
            data_list: any[]
        }
    ],
    totalSize: number
}

export type ColumnsForFiltersType = {
    title: string
    headerName: string
    value: any
    condition: string
}

type PropertyForOfferType = {
    addText: string
    description: string | null
    photo: string | null
    propertyAddress: string | null
    propertyId: number
    short_description: string | null
}

export type ContactsTitlesType = {
    AE_LI_VALUE: string | null
}


export type PropContactsWindowModes = {
    mode: 'Opened' | 'Closed' | 'Hided'
    modeId: number
    modeName: 'Email' | 'Activity'
    modeProperties: []
}

interface InitialStateType {
    columns: GridColumns[],
    upcomingPropertiesColumnsForFilters: ColumnsForFiltersType[],
    brokeragePropertiesColumnsForFilters: ColumnsForFiltersType[],
    propertiesColumnsForFilters: ColumnsForFiltersType[],
    propertiesForOffer: PropertyForOfferType[],
    allPropertiesGridPropertiesForEmail: PropertyForOfferType[],
    propertiesColumnsForFiltersStr: ColumnsForFiltersType[],
    allPropertiesColumnsForFilters: ColumnsForFiltersType[],
    upcomingPropertiesColumnsForFiltersStr: ColumnsForFiltersType[],
    brokeragePropertiesColumnsForFiltersStr: ColumnsForFiltersType[],
    surfacesColumnsForFilters: ColumnsForFiltersType[],
    contacts: AllContactsType[],
    defaultContact: any[],
    defaultContactUnchecked: any[],
    propertiesGridHiddenColumns: ShownAndHiddenColumnsType[],
    propertiesGridShownColumns: ShownAndHiddenColumnsType[],
    surfacesGridShownColumns: ShownAndHiddenColumnsType[],
    surfacesGridHiddenColumns: ShownAndHiddenColumnsType[],
    allPropertiesGridHiddenColumns: ShownAndHiddenColumnsType[],
    allPropertiesGridShownColumns: ShownAndHiddenColumnsType[],
    allPropertiesAddressPropertiesGridShownColumns: ShownAndHiddenColumnsType[],
    emailSubject: { message: string },
    emailGreeting: string
    checkedFilterColumns: string[]
    emailBodyText: string,
    hyperLinks: string[],
    emailDomain: string,
    emailLinkText: any[],
    propertiesRows: any[],
    properties: any[],
    propertiesColumns: GridColumns[],
    surfacesGridData: any[],
    surfacesGridDataColumns: GridColumns[],
    allPropertiesColumnsWithReq: any[],
    allPropertiesColumnsUpcoming: any[],
    allPropertiesColumnsForAddressProperties: any[],
    propertiesTestColumnss: any[],
    propertiesDefaultColumnsValues: any[],
    surfacesDefaultColumnsValues: any[],
    brokeragePropertiesDefaultColumnsValues: any[],
    upcomingPropertiesDefaultColumnsValues: any[],
    allPropertiesDefaultColumnsValues: any[],
    previousTestColumnArray: any[],
    propertiesGridFiltersForArray: any[],
    upcomingPropertiesGridFiltersForArray: any[],
    surfacesGridFiltersForArray: any[],
    propertiesWithReqGridFiltersForArray: any[],
    offerToValues: string[],
    offerMessageGreeting: string
    filteredPropertiesOfferContacts: any[],
    propertiesOfferToContacts: any[],
    propertiesOfferCCContacts: any[],
    propertiesOfferBCCContacts: any[],
    propertiesPhotosForLinks: {},
    propertiesMessageBody: any,
    isSendBtnDisabled: boolean,
    isPropertiesToError: boolean,
    isPropertiesCCError: boolean,
    isPropertiesBCCError: boolean,
    currentSurface: any[],
    allPropertiesGridCheckedReqs: any[],
    surfacesForEmail: any[],
    propertyActivities: any[],
    agentAddress: string,
    propertiesGridCheckedProperties: number[],
    allPropertiesGridCheckedProperties: { propId: number, contactId: number, contactName: string,
        orgId: number,
        orgName: string }[]
    checkedSurfaces: number[],
    propertiesSortModel: GridSortItem[] | [],
    allPropertiesSortModel: GridSortItem[] | [],
    upcomingPropertiesSortModel: GridSortItem[] | [],
    surfacesSortModel: GridSortItem[] | [],
    propertiesSortingString: string | null,
    allPropertiesSortingString: string | null,
    upcomingPropertiesSortingString: string | null,
    surfacesSortingString: string | null,
    isInitialGridColumnsCall: boolean,
    isCheckReqMatchFirstTime: boolean,
    gridLoaderMessage: string,
    reqGridHeight: number,
    allPropertiesWithReq: any[],
    allPropertiesUpcoming: any[],
    allPropertiesAddressProperties: any[],
    propertiesCurrentGrid: 'All Properties/REQ' | 'All Properties/Upcoming' | 'Address Properties' | 'Properties' | 'Surfaces' | 'Map' | null
    allPropertiesCurrentReqs: AllPropertiesReqItem[],
    allPropertiesCurrentReq: AllPropertiesReqItem | {},
    allPropertiesGridWithReqGridReqId: number,
    isDataLoading: boolean,
    isAllPropertiesUpcomingLoading: boolean,
    isAllPropertiesAddressGridLoading: boolean,
    isAllPropertiesReqLoading: boolean,
    isAllPropertiesAddressModalOpened: boolean
    currentAddressPropertyData: AddressPropertyDataType | null
    countriesArray: CountryType[]
    currentPropertyMapAddressData: any
    propertyReqDataUploading: boolean
    propertyReqDataUploadingReqId: number
    contactLang: "CZ" | 'EN',
    surfacesFiltersForArray: any[],
    isUpdatingBrokerageData: boolean
    isActivityModalOpened: boolean
    previousEmailSubject: string,
    soldPropertiesPageValues: SoldPropertyPageTypes
    isCreateNewContactModalOpened: boolean
    brokeragePropertiesDefaultColumns: any[],
    brokerageReqCheckedReqs: any[],
    offerIsSending: boolean
    brokerageCreateContactReqId: number | null
    ownerContacts: any[]
    isDataUpdatedWithFilter: boolean | null
    abortController: AbortController | null
    previousContactPropsGridData: any[]
    propertiesWasNotFound: boolean | null
    contactPropsSearchedAddress: string
    contactPropsLastSearchedAddress: string
    isAddressSearchInputShown: boolean
    activitiesCheckedContactRows: any[]
    genderTitles: ContactsTitlesType[]
    jobTitles: ContactsTitlesType[]
    professionTitles: ContactsTitlesType[]
    isPropertiesDataLoading: boolean
    is404Error: boolean
    typeFilterColumnValue: string
    propContactDataWasNotFound: boolean
    propContactsRemarkEditMode: boolean
    propContactsCurrentRowPropId: number | null
    contactPropsLoadedRows: any[],
    propContactsWindowModes: PropContactsWindowModes[]
    propContactsCurrentWindowMode: 'Activity' | 'Email' | null
}

const initialState: InitialStateType = {
    properties: [],
    columns: [],
    propertiesColumnsForFilters: [],
    propertiesColumnsForFiltersStr: [],
    allPropertiesColumnsForFilters: [],
    upcomingPropertiesColumnsForFiltersStr: [],
    brokeragePropertiesColumnsForFiltersStr: [],
    upcomingPropertiesColumnsForFilters: [],
    brokeragePropertiesColumnsForFilters: [],
    propertiesForOffer: [],
    contacts: [],
    defaultContact: [],
    defaultContactUnchecked: [],
    propertiesGridHiddenColumns: [],
    propertiesGridShownColumns: [],
    allPropertiesGridShownColumns: [],
    allPropertiesGridHiddenColumns: [],
    emailSubject: {message: ''},
    emailGreeting: '',
    checkedFilterColumns: [],
    emailBodyText: '',
    hyperLinks: [],
    emailDomain: '',
    emailLinkText: [],
    propertiesRows: [],
    propertiesColumns: [],
    allPropertiesColumnsWithReq: [],
    allPropertiesColumnsUpcoming: [],
    propertiesDefaultColumnsValues: [],
    allPropertiesDefaultColumnsValues: [],
    brokeragePropertiesDefaultColumnsValues: [],
    upcomingPropertiesDefaultColumnsValues: [],
    propertiesTestColumnss: [],
    previousTestColumnArray: [],
    propertiesGridFiltersForArray: [],
    surfacesGridFiltersForArray: [],
    upcomingPropertiesGridFiltersForArray: [],
    propertiesWithReqGridFiltersForArray: [],
    surfacesFiltersForArray: [],
    offerToValues: [],
    upcomingPropertiesSortModel: [],
    offerMessageGreeting: '',
    filteredPropertiesOfferContacts: [],
    propertiesOfferToContacts: [],
    propertiesOfferCCContacts: [],
    propertiesOfferBCCContacts: [],
    propertiesPhotosForLinks: {},
    propertiesMessageBody: '',
    isSendBtnDisabled: false,
    isPropertiesToError: false,
    isPropertiesCCError: false,
    isPropertiesBCCError: false,
    currentSurface: [],
    surfacesForEmail: [],
    propertyActivities: [],
    agentAddress: '',
    propertiesGridCheckedProperties: [],
    allPropertiesGridCheckedProperties: [],
    checkedSurfaces: [],
    propertiesSortModel: [],
    propertiesSortingString: null,
    allPropertiesSortingString: null,
    upcomingPropertiesSortingString: null,
    allPropertiesSortModel: [],
    isInitialGridColumnsCall: false,
    isCheckReqMatchFirstTime: gridDataHelper.getIsCheckReqMatchFirstTime(),
    gridLoaderMessage: '',
    reqGridHeight: 800,
    allPropertiesWithReq: [],
    allPropertiesUpcoming: [],
    allPropertiesGridPropertiesForEmail: [],
    allPropertiesGridCheckedReqs: [],
    propertiesCurrentGrid: null,
    allPropertiesCurrentReqs: [],
    allPropertiesCurrentReq: {},
    allPropertiesGridWithReqGridReqId: 8038,
    isDataLoading: false,
    isAllPropertiesUpcomingLoading: false,
    isAllPropertiesReqLoading: false,
    isAllPropertiesAddressGridLoading: false,
    allPropertiesAddressProperties: [],
    allPropertiesColumnsForAddressProperties: [],
    allPropertiesAddressPropertiesGridShownColumns: [],
    isAllPropertiesAddressModalOpened: false,
    currentAddressPropertyData: null,
    countriesArray: [],
    currentPropertyMapAddressData: {},
    propertyReqDataUploading: false,
    propertyReqDataUploadingReqId: 0,
    contactLang: 'CZ',
    surfacesGridDataColumns: [],
    surfacesDefaultColumnsValues: [],
    surfacesGridData: [],
    surfacesColumnsForFilters: [],
    surfacesGridShownColumns: [],
    surfacesSortModel: [],
    surfacesSortingString: null,
    surfacesGridHiddenColumns: [],
    isUpdatingBrokerageData: false,
    isActivityModalOpened: false,
    previousEmailSubject: '',
    soldPropertiesPageValues: {
        BOOKMARK_NAME: '',
        COMPANY_ABBR: '',
        COMPANY_ABBR_FONT: '',
        COMPANY_ABBR_SIZE: '',
        COMPANY_ABBR_COLOR: '',
        COMPANY_LOGO: '',
        HEADER_TITLE: '',
        HEADER_TITLE_FONT: '',
        HEADER_TITLE_SIZE: '',
        HEADER_TITLE_COLOR: '',
        HEADER_SUBTITLE: '',
        HEADER_SUBTITLE_FONT: '',
        HEADER_SUBTITLE_SIZE: '',
        HEADER_SUBTITLE_COLOR: '',
        PROPERTY_IMAGE: '',
        PROP_DESCRIPTION: '',
        PROP_DESCRIPTION_FONT: '',
        PROP_DESCRIPTION_SIZE: '',
        PROP_DESCRIPTION_COLOR: '',
        PROP_ADDRESS: '',
        PROP_ADDRESS_FONT: '',
        PROP_ADDRESS_SIZE: '',
        PROP_ADDRESS_COLOR: '',
        FOOTER_TITLE: '',
        FOOTER_TITLE_FONT: '',
        FOOTER_TITLE_SIZE: '',
        FOOTER_TITLE_COLOR: '',
        FOOTER_SUBTITLE: '',
        FOOTER_SUBTITLE_FONT: '',
        FOOTER_SUBTITLE_SIZE: '',
        FOOTER_SUBTITLE_COLOR: '',
        CONTACT_DESCRIPTION: '',
        CONTACT_DESCRIPTION_FONT: '',
        CONTACT_DESCRIPTION_SIZE: '',
        CONTACT_DESCRIPTION_COLOR: '',
        CONTACT_TITLE_FONT: '',
        CONTACT_TITLE_SIZE: '',
        CONTACT_TITLE_COLOR: '',
        EMAIL: '',
        OFFICE: '',
        PHONE: '',
        CONTACT_FONT: '',
        CONTACT_SIZE: '',
        CONTACT_COLOR: '',
        EMAIL_TITLE: '',
        OFFICE_TITLE: '',
        PHONE_TITLE: '',
        BADGE_COLOR: '',
        BADGE_FONT: '',
        BADGE_SIZE: '',
        BADGE_TEXT: '',
    },
    isCreateNewContactModalOpened: false,
    brokeragePropertiesDefaultColumns: [],
    brokerageReqCheckedReqs: [],
    offerIsSending: false,
    brokerageCreateContactReqId: null,
    ownerContacts: [],
    isDataUpdatedWithFilter: null,
    abortController: null,
    previousContactPropsGridData: [],
    propertiesWasNotFound: null,
    contactPropsSearchedAddress: '',
    contactPropsLastSearchedAddress: '',
    isAddressSearchInputShown: false,
    activitiesCheckedContactRows: [],
    genderTitles: [
        {
            AE_LI_VALUE: 'Frau'
        },
        {
            AE_LI_VALUE: 'Herr'
        },
        {
            AE_LI_VALUE: 'Mr.'
        },
        {
            AE_LI_VALUE: 'Mrs.'
        },
        {
            AE_LI_VALUE: 'pane'
        },
        {
            AE_LI_VALUE: 'pani'
        }
    ],
    jobTitles: [],
    professionTitles: [],
    isPropertiesDataLoading: false,
    is404Error: false,
    typeFilterColumnValue: '',
    propContactDataWasNotFound: false,
    propContactsRemarkEditMode: false,
    propContactsCurrentRowPropId: null,
    contactPropsLoadedRows: [],
    propContactsWindowModes: [
        {
            mode: 'Closed',
            modeId: 1,
            modeName: 'Email',
            modeProperties: [] ,
        },
        {
            mode: 'Closed',
            modeId: 2,
            modeName: 'Activity',
            modeProperties: [] ,
        }
    ],
    propContactsCurrentWindowMode: null
}

export const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        setGridColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.columns = action.payload.map((g: any) => g && {...g, headerName: g.column.COL_TITLE,})
        },
        setGridPropertiesColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.propertiesColumns = action.payload
            state.surfacesGridDataColumns = action.payload
        },
        setAllPropertiesGridColumns: (state, action: PayloadAction<any[]>) => {
            state.allPropertiesColumnsWithReq = action.payload
            state.allPropertiesColumnsUpcoming = action.payload
            state.allPropertiesGridShownColumns = action.payload
        },
        setColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[], gridType: string }>) => {
            state.propertiesColumnsForFilters = action.payload.columns
        },
        setSurfacesColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[], gridType: string }>) => {
            state.surfacesColumnsForFilters = action.payload.columns
        },
        setWithReqColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[], gridType: string }>) => {
            if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = action.payload.columns
            }
        },
        setWithoutReqColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[], gridType: string }>) => {
            if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = action.payload.columns
            }
        },
        onChangeColumnForFilterValue: (state, action: PayloadAction<{ title: string, value: string | null, gridType: string, startValue?: string, endValue?: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.title ? {
                    ...m,
                    value: action.payload.value,
                    startValue: action.payload.startValue,
                    endValue: action.payload.endValue
                } : m)
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title || m.title === action.payload.title ? {
                    ...m,
                    value: action.payload.value,
                    startValue: action.payload.startValue,
                    endValue: action.payload.endValue
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title || m.title === action.payload.title ? {
                    ...m,
                    value: action.payload.value,
                    startValue: action.payload.startValue,
                    endValue: action.payload.endValue
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title || m.title === action.payload.title ? {
                    ...m,
                    value: action.payload.value,
                    startValue: action.payload.startValue,
                    endValue: action.payload.endValue
                } : m)
            }

        },
        onChangeColumnForFilterStartValue: (state, action: PayloadAction<{ title: string, value: string, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    startValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    startValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    startValue: action.payload.value
                } : m)
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    startValue: action.payload.value
                } : m)
            }
        },
        onChangeColumnForFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string, gridType: string }>) => {

            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    conditionValue: action.payload.value
                } : m)
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    conditionValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    conditionValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    conditionValue: action.payload.value
                } : m)
            }
        },
        onChangeColumnForFilterEndValue: (state, action: PayloadAction<{ title: string, value: string, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    endValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    endValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    endValue: action.payload.value
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.title ? {
                    ...m,
                    endValue: action.payload.value
                } : m)
            }
        },
        onChangeColumnForFilterCondition: (state, action: PayloadAction<{ title: string, condition: string, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.title === action.payload.title ? {
                    ...m,
                    condition: action.payload.condition
                } : m)
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.title === action.payload.title ? {
                    ...m,
                    condition: action.payload.condition
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.title === action.payload.title ? {
                    ...m,
                    condition: action.payload.condition
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.title === action.payload.title ? {
                    ...m,
                    condition: action.payload.condition
                } : m)
            }
        },
        onSetFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    isModalBtnOpened: action.payload.isModalOpened
                } : {...m, isModalBtnOpened: false})
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    isModalBtnOpened: action.payload.isModalOpened
                } : {...m, isModalBtnOpened: false})
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName || m.title === action.payload.headerName ? {
                    ...m,
                    isModalBtnOpened: action.payload.isModalOpened
                } : {...m, isModalBtnOpened: false})
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    isModalBtnOpened: action.payload.isModalOpened
                } : {...m, isModalBtnOpened: false})
            }
        },
        onSetFiltersButtonsSelected: (state, action: PayloadAction<{ headerName: string, isFilterSelected: boolean, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName || m.title === action.payload.headerName ? {
                    ...m,
                    isFilterSelected: action.payload.isFilterSelected
                } : m)
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName || m.title === action.payload.headerName ? {
                    ...m,
                    isFilterSelected: action.payload.isFilterSelected
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName || m.title === action.payload.headerName ? {
                    ...m,
                    isFilterSelected: action.payload.isFilterSelected
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName || m.title === action.payload.headerName ? {
                    ...m,
                    isFilterSelected: action.payload.isFilterSelected
                } : m)
            }
        },
        onResetFiltersButtonsSelected: (state, action: PayloadAction<string>) => {
            if (action.payload === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                    ...m,
                    isFilterSelected: false,
                    isModalBtnOpened: false,
                    value: '',
                    startValue: '',
                    endValue: '',
                    conditionValue: ''
                })
            } else if (action.payload === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                    ...m,
                    isFilterSelected: false,
                    isModalBtnOpened: false,
                    value: '',
                    startValue: '',
                    endValue: '',
                    conditionValue: ''
                })
            } else if (action.payload === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.title !== 'STATUS' ? {
                    ...m,
                    isFilterSelected: false,
                    isModalBtnOpened: false,
                    value: '',
                    startValue: '',
                    endValue: '',
                    conditionValue: ''
                } : m)
            } else if (action.payload === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                    ...m,
                    isFilterSelected: false,
                    isModalBtnOpened: false,
                    value: '',
                    startValue: '',
                    endValue: '',
                    conditionValue: ''
                })
            }
        },
        onResetFilterValue: (state, action: PayloadAction<{ headerName: string, value: null, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesColumnsForFilters = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
                state.propertiesColumnsForFiltersStr = state.propertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/REQ') {
                state.brokeragePropertiesColumnsForFilters = state.brokeragePropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
                state.brokeragePropertiesColumnsForFiltersStr = state.brokeragePropertiesColumnsForFiltersStr.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
            }
            else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesColumnsForFilters = state.upcomingPropertiesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
                state.upcomingPropertiesColumnsForFiltersStr = state.upcomingPropertiesColumnsForFiltersStr.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
            }
            else if (action.payload.gridType === 'Surfaces') {
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
                state.surfacesColumnsForFilters = state.surfacesColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                    ...m,
                    value: action.payload.value,
                } : m)
            }
        },
        setPropertiesForEmail: (state, action: PayloadAction<any>) => {
            state.propertiesForOffer.push({...action.payload, addText: ''});
        },
        setSurfacesForEmail: (state, action: PayloadAction<any>) => {
            state.surfacesForEmail.push({...action.payload, addText: ''})
        },
        deleteSurfaceForEmail: (state, action: PayloadAction<number>) => {
            state.surfacesForEmail = state.surfacesForEmail.filter((p: any) => Number(p.surfaceId) !== Number(action.payload))
        },
        clearSurfacesForEmail: (state, action: PayloadAction<[]>) => {
            state.surfacesForEmail = action.payload
        },
        onRemoveSurfacesFromOffer: (state, action: PayloadAction<number>) => {
            state.surfacesForEmail = state.surfacesForEmail.filter((s: any) => s.propertyId !== Number(action.payload))
            state.checkedSurfaces = state.checkedSurfaces.filter((s: any) => s !== Number(action.payload))
        },
        setShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[], gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming' || action.payload.gridType === 'Address Properties') {
                state.allPropertiesGridShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesGridShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
            }

        },
        setHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[], gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridHiddenColumns = action.payload.columns
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming' || action.payload.gridType === 'Address Properties') {
                state.allPropertiesGridHiddenColumns = action.payload.columns
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesGridHiddenColumns = action.payload.columns
            }
        },
        onChangeShownColumnsChecked: (state, action: PayloadAction<{ field: string, checked: boolean, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridShownColumns = state.propertiesGridShownColumns.map((c: any) => c.field === action.payload.field ? {
                    ...c,
                    checked: action.payload.checked
                } : c)
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming') {
                state.allPropertiesGridShownColumns = state.allPropertiesGridShownColumns.map((c: any) => c.field === action.payload.field ? {
                    ...c,
                    checked: action.payload.checked
                } : c)
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesGridShownColumns = state.surfacesGridShownColumns.map((c: any) => c.field === action.payload.field ? {
                    ...c,
                    checked: action.payload.checked
                } : c)
            }
        },
        onSetCheckedFilterColumns: (state, action: PayloadAction<string>) => {
            state.checkedFilterColumns = [...state.checkedFilterColumns, action.payload]
        },
        onGetEmailHyperlinks: (state, action: PayloadAction<string>) => {
            state.hyperLinks = [...state.hyperLinks, action.payload]
        },
        onChangeEmailBodyText: (state, action: PayloadAction<string>) => {
            state.emailBodyText = action.payload
        },
        onSetProperties: (state, action: PayloadAction<{ properties: any[], gridType: string }>) => {
            state.properties = action.payload.properties
        },
        onSetUpcomingProperties: (state, action: PayloadAction<{ properties: any[], gridType?: string }>) => {
            state.allPropertiesUpcoming = action.payload.properties
        },
        onSetPropertiesWithReq: (state, action: PayloadAction<{ properties: any[], gridType?: string }>) => {
            console.log(action.payload)
            state.allPropertiesWithReq = action.payload.properties
        },
        onSetSurfaces: (state, action: PayloadAction<{ properties: any[], gridType: string }>) => {
            state.surfacesGridData = action.payload.properties
        },
        onChangeShownColumnsFilters: (state, action: PayloadAction<{ headerName: string, isFilterSelected: boolean, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridShownColumns = state.propertiesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    isFilterSelected: action.payload.isFilterSelected
                } : c)
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming') {
                state.allPropertiesGridShownColumns = state.allPropertiesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    isFilterSelected: action.payload.isFilterSelected
                } : c)
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesGridShownColumns = state.surfacesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    isFilterSelected: action.payload.isFilterSelected
                } : c)
            }
        },
        onChangeShownColumnsFilteredArray: (state, action: PayloadAction<{ headerName: string, filteredArray: any[], gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridShownColumns = state.propertiesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    filteredArray: action.payload.filteredArray
                } : c)
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming') {
                state.allPropertiesGridShownColumns = state.allPropertiesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    filteredArray: action.payload.filteredArray
                } : c)
            } else if (action.payload.gridType === 'Surfaces') {
                state.surfacesGridShownColumns = state.surfacesGridShownColumns.map((c: any) => c.headerName === action.payload.headerName ? {
                    ...c,
                    filteredArray: action.payload.filteredArray
                } : c)
            }
        },
        onSetPropertiesTestPreviousColumns: (state, action: PayloadAction<any[]>) => {
            state.previousTestColumnArray = [...state.previousTestColumnArray, action.payload]
        },
        onResetPropertiesTestPreviousColumns: (state, action: PayloadAction<any[]>) => {
            state.previousTestColumnArray = action.payload
        },
        onSetPropertiesGridFilters: (state, action: PayloadAction<{ textValue: string, filtersArray: any[] }>) => {
            action.payload.filtersArray?.forEach((a: any) => {
                if (a.type === 'class java.lang.String') {
                    state.properties = state.propertiesDefaultColumnsValues.filter((p: any) => p[action.payload.textValue] !== null).filter((obj: any) => obj[action.payload.textValue].toLowerCase().startsWith(a.value.toLowerCase()) || obj[action.payload.textValue].toLowerCase().includes(a.value.toLowerCase()));
                }
            })
        },
        onFilterPropertiesGrid: (state, action: PayloadAction<{ gridFilters: any[], gridType: string }>) => {
            state.propertiesGridFiltersForArray = action.payload.gridFilters
        },
        onFilterUpcomingPropertiesGrid: (state, action: PayloadAction<{ gridFilters: any[], gridType: string }>) => {
            state.upcomingPropertiesGridFiltersForArray = action.payload.gridFilters
        },
        onFilterPropertiesWithReqGrid: (state, action: PayloadAction<{ gridFilters: any[], gridType: string }>) => {
            state.propertiesWithReqGridFiltersForArray = action.payload.gridFilters
        },
        onFilterSurfacesGrid: (state, action: PayloadAction<{ gridFilters: any[], gridType: string }>) => {
            state.surfacesFiltersForArray = action.payload.gridFilters
        },
        onResetPropertiesGridColumns: (state, action: PayloadAction<any[]>) => {
            state.propertiesColumns = action.payload
            state.surfacesGridDataColumns = action.payload
        },
        onSetOfferToValuesInitial: (state, action: PayloadAction<string>) => {
            state.offerToValues = [action.payload]
        },
        onAddOfferToValues: (state, action: PayloadAction<string>) => {
            state.offerToValues = [...state.offerToValues, action.payload]
        },
        onDeleteOfferToValues: (state, action: PayloadAction<string>) => {
            state.offerToValues = state.offerToValues.filter((o: string) => o !== action.payload)
        },
        onChangeOfferMessageGreeting: (state, action: PayloadAction<string>) => {
            state.offerMessageGreeting = action.payload
        },
        onSetFilteredPropertiesOfferContacts: (state, action: PayloadAction<any[]>) => {
            state.filteredPropertiesOfferContacts = action.payload
        },
        onSetFilteredPropertiesOfferToContacts: (state, action: PayloadAction<any[]>) => {
            state.propertiesOfferToContacts = action.payload
        },
        onSetFilteredPropertiesOfferCCContacts: (state, action: PayloadAction<any[]>) => {
            state.propertiesOfferCCContacts = action.payload
        },
        onSetFilteredPropertiesOfferBCCContacts: (state, action: PayloadAction<any[]>) => {
            state.propertiesOfferBCCContacts = action.payload
        },
        onSetEmailLinksImages: (state, action: PayloadAction<{ prop_id?: number, photo: string }>) => {
            state.propertiesForOffer = state.propertiesForOffer.map((p: any) => p.propertyId === action.payload.prop_id ? {
                ...p,
                photo: action.payload.photo
            } : p)
            state.allPropertiesGridPropertiesForEmail = state.allPropertiesGridPropertiesForEmail.map((p: any) => p.propertyId === action.payload.prop_id ? {
                ...p,
                photo: action.payload.photo
            } : p)
        },
        onSetEmailLinksAddText: (state, action: PayloadAction<{
            prop_id: number, addText: string,
            gridType: 'Properties' | 'All Properties/REQ' | 'All Properties/Upcoming' | 'Brokerage/Requirements'
        }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesForOffer = state.propertiesForOffer.map((p: any) => p.propertyId === action.payload.prop_id ? {
                    ...p,
                    addText: action.payload.addText
                } : p)
            } else {
                state.allPropertiesGridPropertiesForEmail = state.allPropertiesGridPropertiesForEmail.map((p: any) => p.propertyId === action.payload.prop_id ? {
                    ...p,
                    addText: action.payload.addText
                } : p)
            }

        },
        onSetSurfaceEmailLinksImages: (state, action: PayloadAction<{ surfaceId: number, photo: string }>) => {
            state.surfacesForEmail = state.surfacesForEmail.map((p: any) => Number(p.surfaceId) === Number(action.payload.surfaceId) ? {
                ...p,
                photo: action.payload.photo
            } : p)
        },
        onSetSurfaceEmailLinksAddText: (state, action: PayloadAction<{ surfaceId: number, addText: string }>) => {
            state.surfacesForEmail = state.surfacesForEmail.map((p: any) => Number(p.surfaceId) === Number(action.payload.surfaceId) ? {
                ...p,
                addText: action.payload.addText
            } : p)
        },
        onSetPropertiesMessageBody: (state, action: PayloadAction<any>) => {
            state.propertiesMessageBody = action.payload
        },
        onClearPropertiesMessageBody: (state) => {
            state.propertiesMessageBody = ''
        },
        onSetIsSendBtnDisabled: (state, action: PayloadAction<boolean>) => {
            state.isSendBtnDisabled = action.payload
        },
        onSetPropertiesOfferFormError: (state, action: PayloadAction<{ fieldType: string, isError: boolean }>) => {
            if (action.payload.fieldType === 'To') {
                state.isPropertiesToError = action.payload.isError
            } else if (action.payload.fieldType === 'CC') {
                state.isPropertiesCCError = action.payload.isError
            } else if (action.payload.fieldType === 'BCC') {
                state.isPropertiesBCCError = action.payload.isError
            }
        },
        onSetSurfaceOpen: (state, action: PayloadAction<{ surfaces: any[], propId: string }>) => {
            state.properties = state.properties.map((p: any) => {
                return (
                    Number(p.PROP_ID) === Number(action.payload.propId) ? {...p, surfaces: action.payload.surfaces} : p
                )
            })
        },
        onSetSurfaceIsOpened: (state, action: PayloadAction<{ isOpened: boolean, propId: string }>) => {
            state.properties = state.properties.map((p: any) => {
                return (
                    Number(p.PROP_ID) === Number(action.payload.propId) ? {...p, isOpened: action.payload.isOpened} : p
                )
            })
        },
        onSetAllSurfaceClosed: (state) => {
            state.properties = state.properties.map((p: any) => p && {...p, isOpened: false})
        },
        onClearPropertyActivities: (state) => {
            state.propertyActivities = []
        },
        onChangeColumnsGridPosition: (state, action: PayloadAction<{ columns: [string, ShownAndHiddenColumnsType], gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesGridShownColumns = state.propertiesGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming' || action.payload.gridType === 'Address Properties') {
                state.allPropertiesGridShownColumns = state.allPropertiesGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
            } else {
                state.surfacesGridShownColumns = state.surfacesGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
            }
        },
        onChangeColumnsGridWidth: (state, action: PayloadAction<{ columns: any, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                const currentStateColumns = state.propertiesGridShownColumns
                Object.entries(action.payload.columns).forEach((item: any) => {
                    const columnName = item[0];
                    const gridColumnParams = item[1];
                    const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                    if (currentColumn && gridColumnParams && gridColumnParams.width) {
                        currentColumn.width = gridColumnParams.width;
                    }
                });
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'All Properties/Upcoming' || action.payload.gridType === 'Address Properties') {
                const currentStateColumns = state.allPropertiesGridShownColumns
                Object.entries(action.payload.columns).forEach((item: any) => {
                    const columnName = item[0];
                    const gridColumnParams = item[1];
                    const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                    if (currentColumn && gridColumnParams && gridColumnParams.width) {
                        currentColumn.width = gridColumnParams.width;
                    }
                });
            } else {
                const currentStateColumns = state.surfacesGridShownColumns
                Object.entries(action.payload.columns).forEach((item: any) => {
                    const columnName = item[0];
                    const gridColumnParams = item[1];
                    const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                    if (currentColumn && gridColumnParams && gridColumnParams.width) {
                        currentColumn.width = gridColumnParams.width;
                    }
                });
            }
        },
        onAddCheckedProperties: (state, action: PayloadAction<{ property: number, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.propertiesGridCheckedProperties.push(action.payload.property)
            } else {
                state.propertiesGridCheckedProperties = state.propertiesGridCheckedProperties.filter((c: any) => c !== action.payload.property)
            }
        },
        onAddAllPropertiesCheckedProperties: (state, action: PayloadAction<{ property: number, isChecked: boolean, contactId?: number, contactName?: string, orgId?: number, orgName?: string,  gridType: 'All Properties/REQ' | 'All Properties/Upcoming' }>) => {
            if (action.payload.isChecked) {
                state.allPropertiesGridCheckedProperties.push({
                    propId: action.payload.property,
                    contactId: action.payload.contactId!!,
                    contactName: action.payload.contactName!!,
                    orgId: action.payload.orgId!!,
                    orgName: action.payload.orgName!!,
                })
            } else {
                state.allPropertiesGridCheckedProperties = state.allPropertiesGridCheckedProperties
                    .filter((c: { propId: number, contactId: number }) =>
                        !(Number(c.propId) === Number(action.payload.property) && Number(c.contactId) === Number(action.payload.contactId))
                    );
            }

        },
        onAddAllPropertiesCheckedReqs: (state, action: PayloadAction<{ propertyItem: { req_id: number, property_id: number }, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.brokerageReqCheckedReqs.push({
                    req_id: action.payload.propertyItem.req_id,
                    property_id: action.payload.propertyItem.property_id
                })
            } else {
                state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.filter((c: any) => c.req_id !== action.payload.propertyItem.req_id)
            }
        },
        onClearCheckedProperties: (state) => {
            state.propertiesGridCheckedProperties = []
            state.allPropertiesGridCheckedReqs = []
        },
        onDeleteCheckedPropertyFromOffer: (state, action: PayloadAction<number>) => {
            state.propertiesGridCheckedProperties = state.propertiesGridCheckedProperties.filter((c: any) => c !== action.payload)
            state.allPropertiesGridCheckedReqs = []
        },
        onDeleteCheckedReqsFromOffer: (state, action: PayloadAction<number>) => {
            // state.allPropertiesGridCheckedReqs = state.allPropertiesGridCheckedReqs.filter((c: any) => Number(c.REQ_ID) !== action.payload)
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.filter((c: any) => Number(c.REQ_ID) !== action.payload)
        },
        onAddCheckedSurfaces: (state, action: PayloadAction<{ surface: number, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.checkedSurfaces.push(action.payload.surface)
            } else {
                state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => c !== action.payload.surface)
            }
        },
        onClearCheckedSurfaces: (state) => {
            state.checkedSurfaces = []
        },
        onDeleteCheckedSurfacesFromOffer: (state, action: PayloadAction<number>) => {
            state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => c !== action.payload)
        },
        onSetLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[], gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesSortModel = action.payload.sort_model
            } else if (action.payload.gridType === 'All Properties/REQ') {
                state.allPropertiesSortModel = action.payload.sort_model
            } else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesSortModel = action.payload.sort_model
            } else {
                state.surfacesSortModel = action.payload.sort_model
            }
        },
        onChangeLocalSortModel: (state, action: PayloadAction<{ sort_model_item: GridSortItem, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesSortModel = [...state.propertiesSortModel, action.payload.sort_model_item]
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'Address Properties') {
                state.allPropertiesSortModel = [...state.allPropertiesSortModel, action.payload.sort_model_item]
            } else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesSortModel = [...state.upcomingPropertiesSortModel, action.payload.sort_model_item]
            } else {
                state.surfacesSortModel = [...state.surfacesSortModel, action.payload.sort_model_item]
            }
            // return {
            //     ...state,
            //     sortModel: [...state.sortModel, action.payload]
            // }
        },
        onSetSortingString: (state, action: PayloadAction<{ sorting_string: string | null, gridType: string }>) => {
            if (action.payload.gridType === 'Properties') {
                state.propertiesSortingString = action.payload.sorting_string
            } else if (action.payload.gridType === 'All Properties/REQ' || action.payload.gridType === 'Address Properties') {
                state.allPropertiesSortingString = action.payload.sorting_string
            } else if (action.payload.gridType === 'All Properties/Upcoming') {
                state.upcomingPropertiesSortingString = action.payload.sorting_string
            } else {
                state.surfacesSortingString = action.payload.sorting_string
            }

        },
        onSetIsInitialGridColumnsCall: (state, action: PayloadAction<boolean>) => {
            state.isInitialGridColumnsCall = action.payload
        },
        onSetIsCheckReqMatchFirstTime: (state, action: PayloadAction<boolean>) => {
            state.isCheckReqMatchFirstTime = action.payload
        },
        onSetPropertiesGridLoaderMessage: (state, action: PayloadAction<string>) => {
            state.gridLoaderMessage = action.payload
        },
        onSetReqGridHeight: (state, action: PayloadAction<number>) => {
            state.reqGridHeight = action.payload
        },
        setAllPropertiesGridPropertiesForEmail: (state, action: PayloadAction<any>) => {
            if (action.payload.gridType === 'All Properties/REQ') {
                // state.allPropertiesGridPropertiesForEmail = [{...action.payload, addText: ''}]
                state.allPropertiesGridPropertiesForEmail.push({...action.payload, addText: ''})
            } else {
                state.allPropertiesGridPropertiesForEmail.push({...action.payload, addText: ''});
            }

        },
        deletePropertyEmail: (state, action: PayloadAction<number>) => {
            state.propertiesForOffer = state.propertiesForOffer.filter((p: any) => p.propertyId !== action.payload)
        },
        deleteAllPropertiesPropertyEmail: (state, action: PayloadAction<{ propId: number, contactId?: number, gridType: string }>) => {
            if (action.payload.gridType === 'All Properties/Upcoming') {
                state.allPropertiesGridPropertiesForEmail = state.allPropertiesGridPropertiesForEmail.filter((c: any) =>
                    !(Number(c.propertyId) === Number(action.payload.propId) && Number(c.contactId) === Number(action.payload.contactId))
                )
            } else {
                state.allPropertiesGridPropertiesForEmail = state.allPropertiesGridPropertiesForEmail.filter((p: any) => p.propertyId !== action.payload)
            }

        },
        clearPropertiesForEmail: (state, action: PayloadAction<[]>) => {
            state.propertiesForOffer = action.payload
            state.propertiesGridCheckedProperties = action.payload
            state.checkedSurfaces = action.payload
            state.allPropertiesGridPropertiesForEmail = action.payload
            state.allPropertiesGridCheckedReqs = action.payload
            state.allPropertiesGridCheckedProperties = action.payload
            state.brokerageReqCheckedReqs = action.payload
            state.activitiesCheckedContactRows = action.payload
        },
        clearContactsPropertiesForEmail: (state, action: PayloadAction<[]>) => {
            state.allPropertiesGridPropertiesForEmail = action.payload
            state.allPropertiesGridCheckedReqs = action.payload
            state.allPropertiesGridCheckedProperties = action.payload
        },
        onSetAllPropertiesPropertyReqs: (state, action: PayloadAction<{ reqs: AllPropertiesReqItem[], propId: string }>) => {
            state.allPropertiesWithReq = state.allPropertiesWithReq.map((p: any) => {
                return (
                    Number(p.PROP_ID) === Number(action.payload.propId) ? {...p, reqs: action.payload.reqs} : p
                )
            })
        },
        onSetIsReqOpened: (state, action: PayloadAction<{ isOpened: boolean, propId: string }>) => {
            state.allPropertiesWithReq = state.allPropertiesWithReq.map((p: any) => {
                return (
                    Number(p.PROP_ID) === Number(action.payload.propId) ? {...p, isOpened: action.payload.isOpened} : p
                )
            })
        },
        onDeleteAllPropertiesCheckedPropertyFromOffer: (state, action: PayloadAction<{ propId: number, contactId?: number, gridType: string }>) => {
            if (action.payload.gridType === 'All Properties/Upcoming') {
                state.allPropertiesGridCheckedProperties = state.allPropertiesGridCheckedProperties.filter((c: any) =>
                    !(Number(c.propId) === Number(action.payload.propId) && Number(c.contactId) === Number(action.payload.contactId))
                )
            } else {
                state.allPropertiesGridCheckedProperties = state.allPropertiesGridCheckedProperties.filter((c: any) => c.propertyId !== action.payload.propId)
            }
        },
        onSetCurrentReq: (state, action: PayloadAction<{ reqId: number, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.brokerageReqCheckedReqs.push({
                    ...state.allPropertiesCurrentReqs.filter((r: any) => r.REQ_ID === action.payload.reqId)[0],
                    isEmptyContactTo: true,
                    defaultContacts: [],
                    emailGreeting: '',
                    uncheckedContacts: []
                })
            } else {
                state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.filter((r: any) => r.REQ_ID !== action.payload.reqId)
                if (state.brokerageReqCheckedReqs.length === 0) {
                    state.propertiesForOffer = []
                    state.brokerageReqCheckedReqs = []
                    state.allPropertiesGridPropertiesForEmail = []
                }
            }
            // state.allPropertiesCurrentReq = state.allPropertiesCurrentReqs.filter((r: any) => r.REQ_ID === action.payload)

        },
        onRemoveBrokerageReqContactToError: (state, action: PayloadAction<{ reqId: number, isEmptyContactTo: boolean }>) => {
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.map((r: BrokerageCheckedReq) => r.REQ_ID === action.payload.reqId ? {
                ...r,
                isEmptyContactTo: action.payload.isEmptyContactTo
            } : r)
        },
        onSetBrokerageOfferDefaultContacts: (state, action: PayloadAction<{ reqId: number, defaultContacts: any[] }>) => {
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.map((r: BrokerageCheckedReq) => r.REQ_ID === action.payload.reqId
                ? {...r, defaultContacts: action.payload.defaultContacts ? [action.payload.defaultContacts] : []} : r)
        },
        onSetBrokerageOfferContacts: (state, action: PayloadAction<{ reqId: number, contacts: any[] }>) => {
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.map((r: BrokerageCheckedReq) => r.REQ_ID === action.payload.reqId
                ? {...r, contacts: action.payload.contacts ? [action.payload.contacts] : []} : r)
        },
        onSetBrokerageOfferUncheckedContacts: (state, action: PayloadAction<{ reqId: number, uncheckedContacts: any[] }>) => {
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.map((r: BrokerageCheckedReq) => r.REQ_ID === action.payload.reqId
                ? {
                    ...r,
                    uncheckedContacts: action.payload.uncheckedContacts ? action.payload.uncheckedContacts : []
                } : r)
        },
        onSetBrokerageOffersGreeting: (state, action: PayloadAction<{ reqId: number, emailGreeting: string }>) => {
            state.brokerageReqCheckedReqs = state.brokerageReqCheckedReqs.map((r: BrokerageCheckedReq) => r.REQ_ID === action.payload.reqId ? {
                ...r,
                emailGreeting: action.payload.emailGreeting
            } : r)
        },
        onSetAllPropertiesWithReqGridReqId: (state, action: PayloadAction<number>) => {
            state.allPropertiesCurrentReq = state.allPropertiesCurrentReqs.filter((r: any) => r.REQ_ID === action.payload)
        },
        onSetAllPropertiesGridCurrentReqId: (state, action: PayloadAction<number>) => {
            state.allPropertiesGridWithReqGridReqId = action.payload
        },
        onChangeCurrentGridTab: (state, action: PayloadAction<'All Properties/REQ' | 'All Properties/Upcoming' | 'Address Properties' | 'Properties' | 'Surfaces' | 'Map'>) => {
            state.propertiesCurrentGrid = action.payload
        },
        onChangeIsDataLoadingState: (state, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload
        },
        onSetAllPropertiesUpcomingLoading: (state, action: PayloadAction<boolean>) => {
            state.isAllPropertiesUpcomingLoading = action.payload
        },
        onSetAllPropertiesReqLoading: (state, action: PayloadAction<boolean>) => {
            state.isAllPropertiesReqLoading = action.payload
        },
        onSetAllPropertiesAddressGridLoading: (state, action: PayloadAction<boolean>) => {
            state.isAllPropertiesAddressGridLoading = action.payload
        },
        onChangeIsAddressModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isAllPropertiesAddressModalOpened = action.payload
        },
        onSetCurrentAddressPropertyData: (state, action: PayloadAction<AddressPropertyDataType>) => {
            state.currentAddressPropertyData = action.payload
        },
        onSetPropertyReqDataUploading: (state, action: PayloadAction<boolean>) => {
            state.propertyReqDataUploading = action.payload
        },
        onSetPropertyReqDataUploadingReqId: (state, action: PayloadAction<number>) => {
            state.propertyReqDataUploadingReqId = action.payload
        },
        onSetIsBrokerageDataUpdating: (state, action: PayloadAction<boolean>) => {
            state.isUpdatingBrokerageData = action.payload
        },
        onSetIsActivityModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isActivityModalOpened = action.payload
        },
        onHandleViewOptionDragStart: (state, action: PayloadAction<any>) => {
            state.propertiesGridShownColumns.splice(action.payload.col_pos, 1)
        },
        onHandleViewOptionDragEnd: (state, action: PayloadAction<{ column: any, draggedItem: any }>) => {
            state.propertiesGridShownColumns.splice(action.payload.column.col_pos, 0, action.payload.draggedItem)
        },
        onSetPreviousEmailSubject: (state, action: PayloadAction<string>) => {
            state.previousEmailSubject = action.payload
        },
        onSetEmailSubject: (state, action: PayloadAction<string>) => {
            state.emailSubject.message = action.payload
        },
        onSetAllPropertiesAddressPropertiesEmpty: (state) => {
            state.allPropertiesAddressProperties = []
            state.allPropertiesColumnsForAddressProperties = []
        },
        onSetCreateContactModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isCreateNewContactModalOpened = action.payload
        },
        onChangeOfferIsSending: (state, action: PayloadAction<boolean>) => {
            state.offerIsSending = action.payload
        },
        onChangeBrokerageCreateContactReqId: (state, action: PayloadAction<number | null>) => {
            state.brokerageCreateContactReqId = action.payload
        },
        onAddOwnerContact: (state, action: PayloadAction<any>) => {
            state.ownerContacts.push(action.payload)
            state.ownerContacts = state.ownerContacts.reduce((acc: any, current: any) => {
                if (!acc.seen[current.value]) {
                    acc.seen[current.value] = true;
                    acc.result.push(current);
                }
                return acc;
            }, {seen: {}, result: []}).result
        },
        onDeleteOwnerContact: (state, action: PayloadAction<any>) => {
            state.ownerContacts = state.ownerContacts.filter((c: any) => c.value !== action.payload.value)
        },
        onClearOwnerContacts: (state) => {
            state.ownerContacts = []
        },
        onSetOwnerContacts: (state, action: PayloadAction<any>) => {
            state.ownerContacts = action.payload
        },
        onSetIsDataUpdatedWithFilter: (state, action: PayloadAction<boolean | null>) => {
            state.isDataUpdatedWithFilter = action.payload
        },
        setAbortController(state, action) {
            state.abortController = action.payload;
        },
        clearAbortController(state) {
            state.abortController = null;
        },
        onSetContactPropertiesWasNotFound: (state, action: PayloadAction<boolean | null>) => {
            state.propertiesWasNotFound = action.payload
        },
        onSetContactPropertiesSearchedAddress: (state, action: PayloadAction<string>) => {
            state.contactPropsSearchedAddress = action.payload
        },
        onSetContactPropertiesLastSearchedAddress: (state, action: PayloadAction<string>) => {
            state.contactPropsLastSearchedAddress = action.payload
        },
        onSetContactPropertiesIsSearchInputShown: (state, action: PayloadAction<boolean>) => {
            state.isAddressSearchInputShown = action.payload
        },
        onSetActivitiesContactRows: (state, action: PayloadAction<{ row: any, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.activitiesCheckedContactRows.push(action.payload.row)
            } else {
                state.activitiesCheckedContactRows = state.activitiesCheckedContactRows.filter((c: any) => c.PROP_REF !== action.payload.row.PROP_REF)

            }
        },
        onSetIsPropertiesDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isPropertiesDataLoading = action.payload
        },
        onSetIs404Error: (state, action: PayloadAction<boolean>) => {
            state.is404Error = action.payload
        },
        onSetTypeColumnFilterValue: (state, action: PayloadAction<string>) => {
            state.typeFilterColumnValue = action.payload
        },
        onSetPropContactDataWasNotFound: (state, action: PayloadAction<boolean>) => {
            state.propContactDataWasNotFound = action.payload
        },
        onSetPropContactsRemarkEditMode(state, action: PayloadAction<boolean>) {
            state.propContactsRemarkEditMode = action.payload
        },
        onSetPropContactsCurrentRowPropId(state, action: PayloadAction<number | null>) {
            state.propContactsCurrentRowPropId = action.payload
        },
        onSetPropContactsLoadedRows(state, action: PayloadAction<any[]>) {
            console.log(action.payload)
            state.contactPropsLoadedRows = action.payload
        },
        onSetDefaultPropContactsRows(state, action: PayloadAction<any[]>) {
            state.upcomingPropertiesDefaultColumnsValues = action.payload
        },
        onChangeWindowModesMode(state, action: PayloadAction<{modeName: 'Activity' | 'Email', mode: 'Opened' | 'Closed' | 'Hided'}>) {
            state.propContactsWindowModes = state.propContactsWindowModes.map((m) => m.modeName === action.payload.modeName ? {...m, mode: action.payload.mode} : m)
        },
        onChangeWindowModes(state, action: PayloadAction<{modeName: 'Activity' | 'Email', mode: 'Opened' | 'Closed' | 'Hided'}>) {
            state.propContactsWindowModes = state.propContactsWindowModes.map((m) => m.modeName === action.payload.modeName ? {...m, mode: action.payload.mode} : m)
        },
        onSetCurrentWindow(state, action: PayloadAction<'Activity' | 'Email' | null>) {
            state.propContactsCurrentWindowMode = action.payload
        },
        resetAllPropertiesState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetPropertiesGridThunk.fulfilled, (state, action) => {
                state.properties = action.payload
                const properties = action.payload.map((innerArr: PropertiesColumnType[], index: number) => {
                    if (innerArr !== null) {
                        const obj: PropertiesColumnType = {
                            COL_NAME: "",
                            COL_OPTIONS: "",
                            COL_POS: 0,
                            COL_TITLE: "",
                            COL_TYPE: "",
                            COL_VALUE: 0,
                            COL_WIDTH: 0,
                            id: 0,
                            isFiltered: false,
                            surfaces: [],
                            isOpened: false,
                        };
                        innerArr.forEach((currentValue: PropertiesColumnType) => {
                            if (currentValue.COL_NAME === 'PROP_ID') {
                                const foundObject = innerArr.find((obj: any) => obj.COL_NAME === "SURFACE_EXISTED");
                                obj['withSurface'] = foundObject?.COL_VALUE === 1
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                obj['width'] = 0
                            } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['surfaces'] = [];
                        obj['isOpened'] = false;

                        return obj;
                    }
                });
                state.properties = properties

                state.propertiesColumns = action.payload[0]
                state.propertiesDefaultColumnsValues = properties
            })
            // get surface grid data
            .addCase(GetSurfaceGridThunk.fulfilled, (state, action) => {
                state.surfacesGridData = action.payload
                const surfaces = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_NAME === 'PROP_ID') {
                                const foundObject = innerArr.find((obj: any) => obj.COL_NAME === "SURFACE_EXISTED");
                                obj['withSurface'] = foundObject.COL_VALUE === 1
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                obj['width'] = 0
                            } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['surfaces'] = [];
                        obj['isOpened'] = false;
                        obj['key'] = index;

                        return obj;
                    }
                });
                state.surfacesGridData = surfaces

                state.surfacesGridDataColumns = action.payload[0]
                state.surfacesDefaultColumnsValues = surfaces
            })
            .addCase(GetPropertiesGridContacts.fulfilled, (state, action) => {
                state.contacts = action.payload
            })
            .addCase(GetPropertiesEmailSubject.fulfilled, (state, action) => {
                state.emailSubject = action.payload
                state.previousEmailSubject = action.payload.message
            })
            .addCase(GetPropertiesEmailBody.fulfilled, (state, action) => {
                state.emailBodyText = action.payload
            })
            .addCase(GetPropertiesEmailDomain.fulfilled, (state, action) => {
                state.emailDomain = action.payload
            })
            .addCase(GetPropertiesEmailLinkText.fulfilled, (state, action) => {
                state.emailLinkText = action.payload
            })
            .addCase(GetPropertiesEmailGreeting.fulfilled, (state, action) => {
                state.emailGreeting = action.payload
            })
            .addCase(GetPropertiesGridContactsByReqId.fulfilled, (state, action) => {
                state.defaultContact = action.payload
            })
            .addCase(GetPropertiesGridContactsUncheckedByReqId.fulfilled, (state, action) => {
                state.defaultContactUnchecked = action.payload
            })
            .addCase(GetPropertiesEmailPhotosForLinks.fulfilled, (state, action) => {
                state.propertiesPhotosForLinks = action.payload
            })
            .addCase(GetPropertiesSurface.fulfilled, (state, action) => {
                state.currentSurface = action.payload
            })
            .addCase(GetPropertyActivities.fulfilled, (state, action) => {
                state.propertyActivities = action.payload
            })
            .addCase(GetPropertyAgentAddress.fulfilled, (state, action) => {
                state.agentAddress = action.payload
            })
            .addCase(GetAllPropertiesWithREQGridThunk.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length > 0) {
                    state.allPropertiesWithReq = action.payload;

                    const properties = action.payload.map((innerArr: any, index: number) => {
                        if (innerArr !== null) {
                            const obj: any = {};
                            innerArr.forEach((currentValue: any) => {
                                if (currentValue.COL_NAME === 'PROP_ID') {
                                    const foundObject = innerArr.find((obj: any) => obj.COL_NAME === "SURFACE_EXISTED");
                                    obj['withSurface'] = foundObject?.COL_VALUE === 1;
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                                } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                    obj['width'] = 0;
                                } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                        ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                        : null;
                                } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                        ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                        : null;
                                } else {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                                }
                            });
                            obj['id'] = index;
                            obj['isFiltered'] = false;
                            obj['reqs'] = [];
                            obj['isOpened'] = false;

                            return obj;
                        }
                        return null; // Return null for `null` innerArr values
                    }).filter(item => item !== null); // Filter out any nulls from the resulting array

                    state.allPropertiesWithReq = properties;
                    state.allPropertiesColumnsWithReq = action.payload[0]; // Safe to access because of the check above
                    state.allPropertiesDefaultColumnsValues = properties;
                    state.brokeragePropertiesDefaultColumnsValues = properties;
                } else {
                    // Handle cases where action.payload is not an array or is empty
                    state.allPropertiesWithReq = [];
                    state.allPropertiesColumnsWithReq = [];
                    state.allPropertiesDefaultColumnsValues = [];
                    state.brokeragePropertiesDefaultColumnsValues = [];
                    console.warn('GetAllPropertiesWithREQGridThunk returned an invalid payload:', action.payload);
                }
            })
            .addCase(GetAllPropertiesWithREQGridDefaultColumnsThunk.fulfilled, (state, action) => {
                state.allPropertiesColumnsWithReq = action.payload[0]
            })
            .addCase(GetAllPropertiesWithoutREQGridThunk.fulfilled, (state, action) => {
                state.previousContactPropsGridData = action.payload
                state.allPropertiesUpcoming = action.payload
                const properties = action.payload
                    .map((innerArr: any, index: number) => {
                        if (innerArr !== null) {
                            const obj: any = {};
                            innerArr.forEach((currentValue: any) => {
                                if (currentValue.COL_NAME === 'PROP_REF') {
                                    const foundObject = innerArr.find((obj: any) => obj.COL_NAME === "SURFACE_EXISTED");
                                    obj['withSurface'] = foundObject?.COL_VALUE === 1
                                    obj[currentValue.COL_NAME] = parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'));
                                } else if (currentValue.COL_NAME === "PE_REF" || currentValue.COL_NAME === "CO_REF") {
                                    obj[currentValue.COL_NAME] = parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'));
                                } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                    obj['width'] = 0
                                } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                                } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                                } else {
                                    obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                                }

                            });
                            obj['id'] = index;
                            obj['isFiltered'] = false;
                            obj['reqs'] = [];
                            obj['isOpened'] = false;

                            return obj;
                        }
                    });
                state.allPropertiesUpcoming = properties

                state.allPropertiesColumnsUpcoming = action.payload[0]
                state.allPropertiesDefaultColumnsValues = properties
                state.upcomingPropertiesDefaultColumnsValues = properties
            })
            //
            .addCase(GetAllPropertiesAddressPropertiesGridThunk.fulfilled, (state, action) => {
                const properties = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? Number(currentValue.COL_VALUE.toString().replace('.', '')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        return obj;
                    }
                });
                state.allPropertiesAddressProperties = properties
                // state.allPropertiesDefaultColumnsValues = properties
                const propertiesColumns = action.payload[0].concat({
                    COL_NAME: "EMPTY",
                    COL_OPTIONS: null,
                    COL_POS: 1,
                    COL_TITLE: "",
                    COL_TYPE: null,
                    COL_VALUE: null,
                    COL_WIDTH: 800
                }).concat({
                    COL_NAME: "MAP_PIN",
                    COL_OPTIONS: null,
                    COL_POS: 4,
                    COL_TITLE: "Map Pin",
                    COL_TYPE: null,
                    COL_VALUE: null,
                    COL_WIDTH: 800
                })
                state.allPropertiesColumnsForAddressProperties = propertiesColumns.map((g: any, index: number) => {
                    if (g.COL_NAME === 'EMPTY') {
                        return {
                            field: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            width: 20,
                            dataType: g.COL_TYPE,
                            col_pos: 0,
                            col_value: g.COL_VALUE,
                            col_options: g.COL_OPTIONS,
                        }
                    } else if (g.COL_NAME === 'REMARKS') {
                        return {
                            field: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            width: 180,
                            dataType: g.COL_TYPE,
                            col_pos: 4,
                            col_value: g.COL_VALUE,
                            col_options: g.COL_OPTIONS,
                        }
                    } else {
                        return {
                            field: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            width: 180,
                            dataType: g.COL_TYPE,
                            col_pos: g.COL_POS,
                            col_value: g.COL_VALUE,
                            col_options: g.COL_OPTIONS,
                        }
                    }


                })
            })
            .addCase(GetPropertiesCountries.fulfilled, (state, action) => {

                state.countriesArray = action.payload
            })
            .addCase(GetPropertiesCurrentPropertyMapAddress.fulfilled, (state, action) => {

                state.currentPropertyMapAddressData = action.payload
            })
            .addCase(GetPropertiesContactLang.fulfilled, (state, action) => {

                state.contactLang = action.payload
            })
            .addCase(GetSoldPropertiesPageValues.fulfilled, (state, action) => {

                state.soldPropertiesPageValues = action.payload
            })
            .addCase(GetAllPropertiesGridCurrentPropertyReq.fulfilled, (state, action) => {
                state.allPropertiesCurrentReqs = action.payload
            })
            .addCase(GetContactsProfessionTitles.fulfilled, (state, action) => {
                state.professionTitles = action.payload
            })
            .addCase(GetContactsJobTitles.fulfilled, (state, action) => {
                state.jobTitles = action.payload
            })

    }
})

export const selectPropertiesColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.properties.propertiesColumnsForFilters
export const selectUpcomingPropertiesColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.properties.upcomingPropertiesColumnsForFilters
export const selectPropertiesWithReqColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.properties.brokeragePropertiesColumnsForFilters
export const selectPropertiesForEmail = (state: RootState): PropertyForOfferType[] => state.properties.propertiesForOffer
export const selectContactsForEmail = (state: RootState): AllContactsType[] => state.properties.contacts
export const selectPropertiesGridHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.propertiesGridHiddenColumns
export const selectPropertiesGridShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.propertiesGridShownColumns
export const selectAllPropertiesGridShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.allPropertiesGridShownColumns
export const selectAllPropertiesGridHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.allPropertiesGridHiddenColumns
export const selectEmailSubject = (state: RootState): { message: string } => state.properties.emailSubject
export const selectEmailBody = (state: RootState): any => state.properties.emailBodyText
export const selectEmailDomain = (state: RootState): any => state.properties.emailDomain
export const selectEmailGreeting = (state: RootState): any => state.properties.emailGreeting
export const selectEmailLinkText = (state: RootState): any => state.properties.emailLinkText
export const selectDefaultContact = (state: RootState): any => state.properties.defaultContact
export const selectDefaultContactChecked = (state: RootState): any => state.properties.defaultContactUnchecked
export const selectPropertiesTest = (state: RootState): any => state.properties.properties
export const selectPropertiesTestColumns = (state: RootState): any => state.properties.propertiesColumns
export const selectPropertiesGridDefaultColumnsValues = (state: RootState): any => state.properties.propertiesDefaultColumnsValues
export const selectAllPropertiesGridDefaultColumnsValues = (state: RootState): any => state.properties.allPropertiesDefaultColumnsValues
export const selectPropertiesGridFiltersForArray = (state: RootState): any => state.properties.propertiesGridFiltersForArray
export const selectUpcomingPropertiesGridFiltersForArray = (state: RootState): any => state.properties.upcomingPropertiesGridFiltersForArray
export const selectPropertiesWithReqGridFiltersForArray = (state: RootState): any => state.properties.propertiesWithReqGridFiltersForArray
export const selectOfferMessageGreeting = (state: RootState): any => state.properties.offerMessageGreeting
export const selectFilteredPropertiesOfferToContacts = (state: RootState): any => state.properties.propertiesOfferToContacts
export const selectFilteredPropertiesOfferCCContacts = (state: RootState): any => state.properties.propertiesOfferCCContacts
export const selectFilteredPropertiesOfferBCCContacts = (state: RootState): any => state.properties.propertiesOfferBCCContacts
export const selectPropertiesMessageBody = (state: RootState): any => state.properties.propertiesMessageBody
export const selectIsSendBtnDisabled = (state: RootState): any => state.properties.isSendBtnDisabled
export const selectIsPropertiesToError = (state: RootState): any => state.properties.isPropertiesToError
export const selectIsPropertiesCCError = (state: RootState): any => state.properties.isPropertiesCCError
export const selectIsPropertiesBCCError = (state: RootState): any => state.properties.isPropertiesBCCError
export const selectSurfacesForEmail = (state: RootState): any => state.properties.surfacesForEmail
export const selectPropertyActivities = (state: RootState): any => state.properties.propertyActivities
export const selectPropertyAgentAddress = (state: RootState): any => state.properties.agentAddress
export const selectPropertiesGridCheckedProperties = (state: RootState): any => state.properties.propertiesGridCheckedProperties
export const selectAllPropertiesGridCheckedProperties = (state: RootState): { propId: number, contactId: number, contactName: string,
    orgId: number,
    orgName: string }[] => state.properties.allPropertiesGridCheckedProperties
export const selectCheckedSurfaces = (state: RootState): any => state.properties.checkedSurfaces
export const selectPropertiesGridLocalSortModel = (state: RootState): GridSortItem[] => state.properties.propertiesSortModel
export const selectPropertiesGridSortingRule = (state: RootState): string | null => state.properties.propertiesSortingString
export const selectAllPropertiesGridLocalSortModel = (state: RootState): GridSortItem[] => state.properties.allPropertiesSortModel
export const selectUpcomingPropertiesGridLocalSortModel = (state: RootState): GridSortItem[] => state.properties.upcomingPropertiesSortModel
export const selectIsGridColumnsInitialCall = (state: RootState): boolean => state.properties.isInitialGridColumnsCall
export const selectPropertiesGridLoaderMessage = (state: RootState): string => state.properties.gridLoaderMessage
export const selectAllPropertiesWithReq = (state: RootState): any => state.properties.allPropertiesWithReq
export const selectAllPropertiesWithUpcoming = (state: RootState): any => state.properties.allPropertiesUpcoming
export const selectAllPropertiesColumnsWithReq = (state: RootState): any => state.properties.allPropertiesColumnsWithReq
export const selectAllPropertiesColumnsUpcoming = (state: RootState): any => state.properties.allPropertiesColumnsUpcoming
export const selectAllPropertiesGridPropertiesForEmail = (state: RootState): PropertyForOfferType[] => state.properties.allPropertiesGridPropertiesForEmail
export const selectAllPropertiesGridCheckedReqs = (state: RootState): any => state.properties.allPropertiesGridCheckedReqs
export const selectAllPropertiesCurrentGrid = (state: RootState): any => state.properties.propertiesCurrentGrid
export const selectAllPropertiesCurrentReq = (state: RootState): any => state.properties.allPropertiesCurrentReq
export const selectAllPropertiesGridWithReqGridReqId = (state: RootState): any => state.properties.allPropertiesGridWithReqGridReqId
export const selectIsLoadingData = (state: RootState): boolean => state.properties.isDataLoading
export const selectIsAllPropertiesUpcomingLoading = (state: RootState): boolean => state.properties.isAllPropertiesUpcomingLoading
export const selectIsAllPropertiesReqLoading = (state: RootState): boolean => state.properties.isAllPropertiesReqLoading
export const selectIsAllPropertiesAddressPropertiesLoading = (state: RootState): boolean => state.properties.isAllPropertiesAddressGridLoading
export const selectAllPropertiesAddressProperties = (state: RootState): any[] => state.properties.allPropertiesAddressProperties
export const selectAllPropertiesColumnsForAddressProperties = (state: RootState): any[] => state.properties.allPropertiesColumnsForAddressProperties
export const selectIsAllPropertiesAddressModalOpened = (state: RootState): boolean => state.properties.isAllPropertiesAddressModalOpened
export const selectCurrentAddressPropertyData = (state: RootState): AddressPropertyDataType | null => state.properties.currentAddressPropertyData
export const selectCountriesArray = (state: RootState): CountryType[] => state.properties.countriesArray
export const selectCurrentPropertyMapAddressData = (state: RootState): any => state.properties.currentPropertyMapAddressData
export const selectPropertyReqDataUploading = (state: RootState): boolean => state.properties.propertyReqDataUploading
export const selectPropertyReqDataUploadingReqId = (state: RootState): number => state.properties.propertyReqDataUploadingReqId
export const selectPropertyContactLang = (state: RootState): "CZ" | 'EN' => state.properties.contactLang
export const selectIsBrokerageDataUpdating = (state: RootState): boolean => state.properties.isUpdatingBrokerageData
export const selectPreviousEmailSubject = (state: RootState): string => state.properties.previousEmailSubject
export const selectCreateContactModalOpened = (state: RootState): boolean => state.properties.isCreateNewContactModalOpened
export const selectBrokeragePropertiesDefaultColumns = (state: RootState): any[] => state.properties.brokeragePropertiesDefaultColumns
export const brokerageReqCheckedReqs = (state: RootState): BrokerageCheckedReq[] => state.properties.brokerageReqCheckedReqs
export const selectOfferIsSending = (state: RootState): boolean => state.properties.offerIsSending
export const selectBrokerageCreateContactReqId = (state: RootState): null | number => state.properties.brokerageCreateContactReqId
export const selectOwnerContacts = (state: RootState): any[] => state.properties.ownerContacts
export const selectIsDataUpdatedWithFilter = (state: RootState): boolean | null => state.properties.isDataUpdatedWithFilter
export const selectContactPropsPreviousData = (state: RootState): any[] => state.properties.previousContactPropsGridData
export const selectContactPropertiesWasNotFound = (state: RootState): boolean | null => state.properties.propertiesWasNotFound
export const selectContactPropertiesSearchedAddress = (state: RootState): string => state.properties.contactPropsSearchedAddress
export const selectContactPropertiesLastSearchedAddress = (state: RootState): string => state.properties.contactPropsLastSearchedAddress
export const selectContactPropertiesIsSearchInputShown = (state: RootState): boolean => state.properties.isAddressSearchInputShown
export const selectIsPropertiesDataLoading = (state: RootState): boolean => state.properties.isPropertiesDataLoading
export const selectIs404Error = (state: RootState): boolean => state.properties.is404Error
export const selectPropContactDataWasNotFound = (state: RootState): boolean => state.properties.propContactDataWasNotFound
export const selectUpcomingPropertiesDefaultColumnValues = (state: RootState) : any => state.properties.upcomingPropertiesDefaultColumnsValues
export const selectBrokeragePropertiesDefaultColumnValues = (state: RootState) : any => state.properties.brokeragePropertiesDefaultColumnsValues
export const selectPropContactsRemarkEditMode = (state: RootState) : boolean => state.properties.propContactsRemarkEditMode
export const selectPropContactsCurrentPropId = (state: RootState) : number | null => state.properties.propContactsCurrentRowPropId
export const selectPropContactsLoadedRows = (state: RootState) : any[] => state.properties.contactPropsLoadedRows
export const selectPropContactsWindowModes = (state: RootState) : PropContactsWindowModes[] => state.properties.propContactsWindowModes
export const selectPropContactsCurrentWindowMode = (state: RootState) : "Email" | "Activity" | null => state.properties.propContactsCurrentWindowMode
//Surfaces data
export const selectSurfacesGridData = (state: RootState): any[] => state.properties.surfacesGridData
export const selectSurfacesGridDataColumns = (state: RootState): any[] => state.properties.surfacesGridDataColumns
export const selectSurfacesColumnsForFilters = (state: RootState): any[] => state.properties.surfacesColumnsForFilters
export const selectSurfacesGridFiltersForArray = (state: RootState): any => state.properties.surfacesFiltersForArray
export const selectSurfacesDefaultGridColumns = (state: RootState): any => state.properties.surfacesDefaultColumnsValues
export const selectSurfacesGridShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.surfacesGridShownColumns
export const selectSurfacesGridHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.properties.surfacesGridHiddenColumns
export const selectSurfacesGridLocalSortModel = (state: RootState): GridSortItem[] => state.properties.surfacesSortModel
export const selectSurfacesGridSortingRule = (state: RootState): string | null => state.properties.surfacesSortingString
export const selectSurfaceTypeColumnFilterValue = (state: RootState): string => state.properties.typeFilterColumnValue
//Activities
export const selectActivitiesModalOpened = (state: RootState): boolean => state.properties.isActivityModalOpened
export const selectActivitiesCheckedContactRows = (state: RootState): any[] => state.properties.activitiesCheckedContactRows
//Sold properties
export const selectSoldPropertiesPageValues = (state: RootState): SoldPropertyPageTypes => state.properties.soldPropertiesPageValues
//Contacts
export const selectContactsGenderTitles = (state: RootState): ContactsTitlesType[] => state.properties.genderTitles
export const selectContactsJobTitles = (state: RootState): ContactsTitlesType[] => state.properties.jobTitles
export const selectContactsProfessionTitles = (state: RootState): ContactsTitlesType[] => state.properties.professionTitles


export const {
    setColumnsForFilters,
    onSetFiltersButtonsModalOpened,
    setPropertiesForEmail,
    clearPropertiesForEmail,
    deletePropertyEmail,
    setShownColumns,
    setHiddenColumns,
    onSetFiltersButtonsSelected,
    onSetProperties,
    onChangeColumnForFilterEndValue,
    onChangeColumnForFilterConditionValue,
    onSetOfferToValuesInitial,
    onDeleteOfferToValues,
    onChangeOfferMessageGreeting,
    onFilterPropertiesGrid,
    onChangeColumnForFilterStartValue,
    onChangeColumnForFilterValue,
    onResetFiltersButtonsSelected,
    onResetPropertiesGridColumns,
    onSetFilteredPropertiesOfferToContacts,
    onSetFilteredPropertiesOfferCCContacts,
    onSetFilteredPropertiesOfferBCCContacts,
    onSetEmailLinksImages,
    onSetPropertiesMessageBody,
    onSetIsSendBtnDisabled,
    onSetPropertiesOfferFormError,
    onSetSurfaceOpen,
    setSurfacesForEmail,
    deleteSurfaceForEmail,
    clearSurfacesForEmail,
    onSetSurfaceIsOpened,
    onSetAllSurfaceClosed,
    onClearPropertyActivities,
    onSetSurfaceEmailLinksImages,
    onChangeColumnsGridPosition,
    onClearCheckedProperties,
    onAddCheckedProperties,
    onDeleteCheckedPropertyFromOffer,
    onAddCheckedSurfaces,
    onClearCheckedSurfaces,
    onDeleteCheckedSurfacesFromOffer,
    setGridPropertiesColumns,
    onSetLocalSortModel,
    onSetSortingString,
    onSetIsCheckReqMatchFirstTime,
    onSetPropertiesGridLoaderMessage,
    onSetReqGridHeight,
    setAllPropertiesGridColumns,
    onSetEmailLinksAddText,
    onSetSurfaceEmailLinksAddText,
    onSetAllPropertiesPropertyReqs,
    onSetIsReqOpened,
    onAddAllPropertiesCheckedProperties,
    onAddAllPropertiesCheckedReqs,
    setAllPropertiesGridPropertiesForEmail,
    onSetCurrentReq,
    deleteAllPropertiesPropertyEmail,
    onDeleteAllPropertiesCheckedPropertyFromOffer,
    onSetAllPropertiesGridCurrentReqId,
    onChangeCurrentGridTab,
    onChangeIsDataLoadingState,
    onSetAllPropertiesUpcomingLoading,
    onSetAllPropertiesReqLoading,
    onSetAllPropertiesAddressGridLoading,
    onChangeIsAddressModalOpened,
    onSetCurrentAddressPropertyData,
    onSetPropertyReqDataUploading,
    onClearPropertiesMessageBody,
    onChangeColumnsGridWidth,
    onSetIsBrokerageDataUpdating,
    onSetIsActivityModalOpened,
    onSetAllPropertiesAddressPropertiesEmpty,
    onSetCreateContactModalOpened,
    onRemoveSurfacesFromOffer,
    onDeleteCheckedReqsFromOffer,
    onRemoveBrokerageReqContactToError,
    onSetBrokerageOfferDefaultContacts,
    onSetBrokerageOffersGreeting,
    onChangeOfferIsSending,
    onChangeBrokerageCreateContactReqId,
    onSetBrokerageOfferUncheckedContacts,
    onSetBrokerageOfferContacts,
    onAddOwnerContact,
    onClearOwnerContacts,
    onDeleteOwnerContact,
    onSetIsDataUpdatedWithFilter,
    setAbortController,
    clearAbortController,
    onSetContactPropertiesWasNotFound,
    onSetContactPropertiesSearchedAddress,
    onSetContactPropertiesIsSearchInputShown,
    onSetActivitiesContactRows,
    onSetIsPropertiesDataLoading,
    onSetIs404Error,
    onSetTypeColumnFilterValue,
    onSetPropContactDataWasNotFound,
    setSurfacesColumnsForFilters,
    onSetUpcomingProperties,
    onSetPropertiesWithReq,
    onSetSurfaces,
    onFilterSurfacesGrid,
    onFilterUpcomingPropertiesGrid,
    onFilterPropertiesWithReqGrid,
    setWithReqColumnsForFilters,
    setWithoutReqColumnsForFilters,
    onSetContactPropertiesLastSearchedAddress,
    resetAllPropertiesState,
    onSetPropContactsRemarkEditMode,
    onSetPropContactsCurrentRowPropId,
    onSetPropContactsLoadedRows,
    onSetDefaultPropContactsRows,
    onChangeWindowModesMode,
    onSetCurrentWindow,
    clearContactsPropertiesForEmail,
    onSetOwnerContacts

} = propertiesSlice.actions

export const GetPropertiesGridThunk = createAsyncThunk<any, { requestData: any, isInitialCall?: boolean }, AsyncThunkConfig>(
    'properties/getGridProperties',
    async (requestData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus(AppStatusType.loading));
            thunkAPI.dispatch(onSetIsPropertiesDataLoading(true))
            const {status, data} = await gridApi.getGridColumnDataTest(requestData.requestData);
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle});
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSortingString({sorting_string: data.sorting_rule, gridType: 'Properties'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: sortingCondition, gridType: 'Properties'}));
                } else {
                    thunkAPI.dispatch(onSetSortingString({sorting_string: null, gridType: 'Properties'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}));
                }

                thunkAPI.dispatch(onSetIsPropertiesDataLoading(false))
                thunkAPI.dispatch(onSetIs404Error(false))
                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message);
            }
        } catch (error: any) {
            if (error.response.status === 500) {
                setTimeout(() => {
                    window.location.replace('/requirements');
                }, 5000);
            } else if (error.response.status === 404) {
                thunkAPI.dispatch(onSetIs404Error(true))
            }
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);


export const GetPropertiesGridContacts = createAsyncThunk<any, void, AsyncThunkConfig>(
    'properties/getGridPropertiesContacts',
    async (ref_id, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContacts()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        }
    }
)

export const GetPropertiesGridContactsByReqId = createAsyncThunk<any, { reqId: number, gridType?: string }, AsyncThunkConfig>(
    'properties/getGridPropertiesContactsByReqId',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactsDefault(reqData.reqId)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList[0], {appStatus: AppStatusType.idle})
                if (reqData.gridType === 'All Properties/REQ') {
                    thunkAPI.dispatch(onSetBrokerageOfferDefaultContacts({
                        reqId: reqData.reqId,
                        defaultContacts: data.resultSetList[0]
                    }))
                    thunkAPI.dispatch(onSetBrokerageOfferContacts({
                        reqId: reqData.reqId, contacts: [
                            data.resultSetList[0]?.PE_EMAIL !== null
                            &&
                            {
                                value: data.resultSetList[0]?.PE_EMAIL,
                                label: `${data.resultSetList[0]?.PE_FIRST_NAME} ${data.resultSetList[0]?.PE_LAST_NAME} ${data.resultSetList[0]?.PE_EMAIL}`,
                                labelName: `${data.resultSetList[0]?.PE_FIRST_NAME} ${data.resultSetList[0]?.PE_LAST_NAME}`,
                                labelEmail: `${data.resultSetList[0]?.PE_EMAIL}`
                            },
                            data.resultSetList[0]?.PE_EMAIL2 !== null
                            &&
                            {
                                value: data.resultSetList[0]?.PE_EMAIL2,
                                label: `${data.resultSetList[0]?.PE_FIRST_NAME} ${data.resultSetList[0]?.PE_LAST_NAME} ${data.resultSetList[0]?.PE_EMAIL2}`,
                                labelName: `${data.resultSetList[0]?.PE_FIRST_NAME} ${data.resultSetList[0]?.PE_LAST_NAME}`,
                                labelEmail: `${data.resultSetList[0]?.PE_EMAIL2}`
                            }
                        ].filter((c: any) => c.value !== undefined && c !== false)
                    }))
                    if (data.resultSetList.length) {
                        thunkAPI.dispatch(onRemoveBrokerageReqContactToError({
                            reqId: reqData.reqId,
                            isEmptyContactTo: false
                        }))
                    }

                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        }
    }
)

export const GetPropertiesGridContactsUncheckedByReqId = createAsyncThunk<any, { reqId: number, gridType?: string }, AsyncThunkConfig>(
    'properties/getGridPropertiesUncheckedContactsByReqId',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactsDefaultUnchecked(reqData.reqId)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList[0], {appStatus: AppStatusType.idle})
                if (reqData.gridType === 'All Properties/REQ') {
                    thunkAPI.dispatch(onSetBrokerageOfferUncheckedContacts({
                        reqId: reqData.reqId,
                        uncheckedContacts: data.resultSetList.flat(1)
                    }))
                    if (data.resultSetList.length) {
                        thunkAPI.dispatch(onRemoveBrokerageReqContactToError({
                            reqId: reqData.reqId,
                            isEmptyContactTo: false
                        }))
                    }

                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        }
    }
)

export const GetPropertiesEmailSubject = createAsyncThunk<any, { req_id: number, user_id: string }, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailSubject',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await gridApi.getEmailSubject(requestData.req_id, requestData.user_id)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPropertiesEmailBody = createAsyncThunk<any, { req_id: number, gridType: 'All Properties/REQ' | 'All Properties/Upcoming' | 'Properties', lang: 'EN' | 'CZ' }, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailBody',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await requirementsApi.getEmailBody(requestData.gridType !== 'All Properties/Upcoming' ? requestData.req_id : null, requestData.lang)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPropertiesEmailDomain = createAsyncThunk<any, { req_id: number }, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailDomain',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await requirementsApi.getDomainForEmail(requestData.req_id)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPropertiesEmailLinkText = createAsyncThunk<any, {
    prop_id: number,
    req_id: number, propertyType: "Property" | "Surface",
    surfaceId?: string,
    lang: 'EN' | 'CZ',
    gridType: 'All Properties/REQ' | 'All Properties/Upcoming' | 'Properties' | 'Surfaces',
    currentDomain?: 'CD' | 'OR' | 'RS' | 'RR'
}, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailLLinkText',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await requirementsApi.getEmailLinkText(requestData.prop_id, requestData.req_id, requestData.lang)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList[0], {appStatus: AppStatusType.idle})
                if (requestData.gridType === 'Properties' && requestData.propertyType === 'Property') {
                    thunkAPI.dispatch(setPropertiesForEmail({
                        propertyId: data.resultSetList[0].REF,
                        // propertyTitle: a.TITLE_EN,
                        propertyAddress: data.resultSetList[0].LINE_2,
                        description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                        short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                    }))
                } else if (requestData.gridType === 'Properties' && requestData.propertyType === 'Surface') {
                    if (requestData.currentDomain !== 'OR') {
                        thunkAPI.dispatch(setSurfacesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            surfaceId: requestData.surfaceId,
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(setPropertiesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(onAddCheckedProperties({
                            property: Number(data.resultSetList[0].REF),
                            isChecked: true
                        }))
                    } else {
                        thunkAPI.dispatch(setPropertiesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(onAddCheckedProperties({
                            property: Number(data.resultSetList[0].REF),
                            isChecked: true
                        }))
                    }
                } else if (requestData.gridType === 'Surfaces' && requestData.propertyType === 'Surface') {
                    if (requestData.currentDomain !== 'OR' && requestData.currentDomain !== 'RR') {
                        thunkAPI.dispatch(setSurfacesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            surfaceId: requestData.surfaceId,
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(setPropertiesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(onAddCheckedProperties({
                            property: Number(data.resultSetList[0].REF),
                            isChecked: true
                        }))
                    } else if (requestData.currentDomain === 'RR') {
                        thunkAPI.dispatch(setSurfacesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            surfaceId: requestData.surfaceId,
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                    } else {
                        thunkAPI.dispatch(setPropertiesForEmail({
                            propertyId: data.resultSetList[0].REF,
                            // propertyTitle: a.TITLE_EN,
                            propertyAddress: data.resultSetList[0].LINE_2,
                            description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                            short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                        }))
                        thunkAPI.dispatch(onAddCheckedProperties({
                            property: Number(data.resultSetList[0].REF),
                            isChecked: true
                        }))
                    }
                } else if (requestData.gridType === 'All Properties/REQ' || 'All Properties/Upcoming') {
                    thunkAPI.dispatch(setAllPropertiesGridPropertiesForEmail({
                        propertyId: data.resultSetList[0].REF,
                        // propertyTitle: a.TITLE_EN,
                        propertyAddress: data.resultSetList[0].LINE_2,
                        description: data.resultSetList[0].ADD_INFO ? data.resultSetList[0].ADD_INFO : '',
                        gridType: requestData.gridType,
                        short_description: data.resultSetList[0].DESCR ? data.resultSetList[0].DESCR : ''
                    }))
                }
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPropertiesEmailGreeting = createAsyncThunk<any, { req_id: number }, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailGreeting',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await requirementsApi.getEmailGreeting(requestData.req_id)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetBrokerageOffersGreeting({
                    reqId: requestData.req_id,
                    emailGreeting: data.message
                }))
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreatePropertiesEmailOffer = createAsyncThunk<any, CreatePropertyOffer[] | CreatePropertyOffer, AsyncThunkConfig>(
    'properties/createPropertiesEmailOffer',
    async (requestData, thunkAPI) => {
        thunkAPI.dispatch(onChangeOfferIsSending(true))
        try {
            const {status, data} = await requirementsApi.createPropertyOffer(requestData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Offer has been sent'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(clearPropertiesForEmail([]))
            thunkAPI.dispatch(onSetIsSendBtnDisabled(false))
            thunkAPI.dispatch(setAppStatus(0))
            thunkAPI.dispatch(onChangeOfferIsSending(false))
        }
    }
)

export const ResetAllGridConfigsThunk = createAsyncThunk<any, { p_agent_ref: number, p_row_req: number | null, grid_type: null | string }, AsyncThunkConfig>(
    'properties/resetAllGridConfigs',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.resetPropertiesGrid(requestData.p_agent_ref, requestData.p_row_req, requestData.grid_type)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally {
        //     window.location.reload()
        // }
    }
)

export const GetPropertiesEmailPhotosForLinks = createAsyncThunk<any, { p_fk_type: string, p_row_fk: number, p_row_req: number, prop_id?: number, gridType?: string, surfaceId?: string }, AsyncThunkConfig>(
    'properties/getGridPropertiesEmailPhotosForLinks',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.getLinksPhotos(requestData.p_fk_type, requestData.p_row_fk, requestData.p_row_req)
            if (status === 200 && data) {

                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                if (requestData.p_fk_type === 'Property') {
                    thunkAPI.dispatch(onSetEmailLinksImages({prop_id: requestData.p_row_fk, photo: data.picture}))
                } else {

                    if (requestData.gridType === 'Properties') {
                        thunkAPI.dispatch(onSetEmailLinksImages({
                            prop_id: requestData.p_row_fk,
                            photo: data.picture
                        }))
                    }
                    thunkAPI.dispatch(onSetSurfaceEmailLinksImages({
                        surfaceId: Number(requestData.surfaceId),
                        photo: data.picture
                    }))
                }
                return data
            } else {

                if (requestData.p_fk_type === 'Surface') {

                    try {
                        const {
                            status,
                            data
                        } = await gridApi.getLinksPhotos('Property', requestData?.prop_id, requestData.p_row_req)
                        if (status === 200 && data) {
                            thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                            thunkAPI.dispatch(onSetEmailLinksImages({
                                prop_id: requestData?.prop_id,
                                photo: data.picture
                            }))

                            thunkAPI.dispatch(onSetSurfaceEmailLinksImages({
                                surfaceId: Number(requestData.surfaceId),
                                photo: data.picture
                            }))

                            return data
                        } else {
                            return thunkAPI.rejectWithValue('There is no photo for this property')
                        }
                    } catch (error: any) {
                        return thunkAPI.rejectWithValue('There is no photo for this property')
                    }
                }
                return thunkAPI.rejectWithValue('There is no photo for this property')
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue('There is no photo for this property')
        }
    }
)

export const GetPropertiesSurface = createAsyncThunk<any, { lang: string, prop_id: string, req_id: string }, AsyncThunkConfig>(
    'properties/getGridPropertiesSurface',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.getPropertiesSurface(requestData.lang, requestData.prop_id, requestData.req_id)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetSurfaceOpen({surfaces: data.resultSetList, propId: requestData.prop_id}))
                thunkAPI.dispatch(onSetSurfaceIsOpened({isOpened: true, propId: requestData.prop_id}))
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetPropertyActivities = createAsyncThunk<PropertyActivityType[], ActivitiesReqDataType, AsyncThunkConfig>(
    'properties/getPropertyActivities',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.getPropertyActivities(requestData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetPropertyAgentAddress = createAsyncThunk<string, string, AsyncThunkConfig>(
    'properties/getPropertyAgentAddress',
    async (user_ref, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await userAPI.getAgentAddress(user_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
            }
        } catch (error: any) {
            console.log(error)
        }
    }
)

export const CheckIfContactIsInBlackListThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
    'properties/checkIfContactIsInBlackList',
    async (email, thunkAPI) => {
        try {
            const {
                data
            } = await contactsApi.checkIfContactIsInBlackList(email)
            thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            return data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

//
export const GetAllPropertiesWithREQGridThunk = createAsyncThunk<any, any, AsyncThunkConfig>(
    'properties/getGridAllPropertiesWithREQ',
    async (requestData, thunkAPI) => {
        const abortCtrl = getAbortController();
        thunkAPI.dispatch(setAbortController(abortCtrl));
        thunkAPI.dispatch(setAppStatus(AppStatusType.loading));
        thunkAPI.dispatch(onSetAllPropertiesReqLoading(true))
        try {
            //replace it with actual AllProperties api request
            const {
                status,
                data
            } = await gridApi.getAllPropertiesWithREQGridData(requestData.requestData, {signal: abortCtrl.signal});
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle});
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'All Properties/REQ'
                    }));
                    thunkAPI.dispatch(onSetLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'All Properties/REQ'
                    }));
                } else {
                    thunkAPI.dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/REQ'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}));
                }


                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message);
            }
        } catch (error: any) {
            // if (error.response.status === 500 || error.response.status === 404) {
            //     thunkAPI.dispatch(setAllPropertiesGridColumns([]))
            //     setTimeout(() => {
            //         window.location.replace('/requirements');
            //     }, 5000);
            // }
            if (error.message === "canceled") {
            } else {
                thunkAPI.dispatch(setAllPropertiesGridColumns([]))
                return thunkAPI.rejectWithValue(error?.response?.data?.message);
            }
        } finally {
            thunkAPI.dispatch(onChangeIsDataLoadingState(false))
            thunkAPI.dispatch(onSetAllPropertiesReqLoading(false))
        }
    }
);


export const GetAllPropertiesWithREQGridDefaultColumnsThunk = createAsyncThunk<any, any, AsyncThunkConfig>(
    'properties/getGridAllPropertiesWithREQDefaultColumns',
    async (requestData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus(AppStatusType.loading));
            // thunkAPI.dispatch(onSetAllPropertiesReqLoading(true))
            //replace it with actual AllProperties api request
            const {status, data} = await gridApi.getAllPropertiesWithREQGridData(requestData.requestData);
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle});
                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message);
            }
        } catch (error: any) {
            // if (error.response.status === 500 || error.response.status === 404) {
            //     thunkAPI.dispatch(setAllPropertiesGridColumns([]))
            //     setTimeout(() => {
            //         window.location.replace('/requirements');
            //     }, 5000);
            // }
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        } finally {
            // thunkAPI.dispatch(onChangeIsDataLoadingState(false))
            // thunkAPI.dispatch(onSetAllPropertiesReqLoading(false))
        }
    }
);


export const GetAllPropertiesWithoutREQGridThunk = createAsyncThunk<any, { requestData: any }, AsyncThunkConfig>(
    'properties/getGridAllPropertiesWithoutREQ',
    async ({requestData}, thunkAPI) => {
        const abortCtrl = getAbortController();
        thunkAPI.dispatch(setAbortController(abortCtrl));
        const state: any = thunkAPI.getState()
        thunkAPI.dispatch(setAppStatus(AppStatusType.loading))
        thunkAPI.dispatch(onSetContactPropertiesWasNotFound(false))
        thunkAPI.dispatch(onSetAllPropertiesUpcomingLoading(true))
        try {
            const response = await gridApi.getAllPropertiesWithoutREQGridData(requestData, {signal: abortCtrl.signal});
            const {status, data} = response;

            if (status === 200 && data) {
                thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}));
                thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}));
                thunkAPI.dispatch(onSetContactPropertiesWasNotFound(false))
                thunkAPI.dispatch(onSetContactPropertiesIsSearchInputShown(true))
                if (data.sorting_rule) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: string) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'All Properties/Upcoming'
                    }));
                    thunkAPI.dispatch(onSetLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'All Properties/Upcoming'
                    }));
                } else {
                    thunkAPI.dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/Upcoming'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}));
                }

                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message || 'Unknown error');
            }
        } catch (error: any) {
            if (error.message === "canceled") {
                if (state.properties.previousContactPropsGridData.length) {
                    thunkAPI.dispatch(onSetContactPropertiesWasNotFound(null))
                    return thunkAPI.fulfillWithValue(state.properties.previousContactPropsGridData, {appStatus: AppStatusType.idle})
                }
                else{
                    thunkAPI.dispatch(onSetContactPropertiesWasNotFound(null))
                }
            }
                // else if(error.response.status === 404){
                //     thunkAPI.dispatch(onSetPropContactDataWasNotFound(true))
                //     return thunkAPI.rejectWithValue(error?.response?.data?.message);
            // }
            else {
                thunkAPI.dispatch(onSetContactPropertiesWasNotFound(true))
                return thunkAPI.rejectWithValue(error?.response?.data?.message);
            }
        } finally {
            thunkAPI.dispatch(onChangeIsDataLoadingState(false));
            thunkAPI.dispatch(onSetAllPropertiesUpcomingLoading(false));
            thunkAPI.dispatch(clearAbortController());  // Clear the controller after use
            setTimeout(() => {
                thunkAPI.dispatch((setAppStatus(0)))
            }, 3000)
        }
    }
);


export const GetAllPropertiesAddressPropertiesGridThunk = createAsyncThunk<any, {
    date_from: Moment | string, date_to: Moment | string
}, AsyncThunkConfig>(
    'properties/getAddressPropertiesGrid',
    async (requestData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus(AppStatusType.loading));
            thunkAPI.dispatch((onSetAllPropertiesAddressGridLoading(true)))

            //replace it with actual AllProperties api request
            const {status, data} = await gridApi.getAllPropertiesAddressGridData(requestData);
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle});
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'Address Properties'
                    }));
                    thunkAPI.dispatch(onSetLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'Address Properties'
                    }));
                } else {
                    thunkAPI.dispatch(onSetSortingString({sorting_string: null, gridType: 'Address Properties'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Address Properties'}));
                }


                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message);
            }
        } catch (error: any) {
            if (error.response.status === 500 || error.response.status === 404) {
                thunkAPI.fulfillWithValue([], {appStatus: AppStatusType.idle});
                thunkAPI.dispatch(onSetAllPropertiesAddressPropertiesEmpty())
            }
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        } finally {
            thunkAPI.dispatch(onChangeIsDataLoadingState(false))
            thunkAPI.dispatch((onSetAllPropertiesAddressGridLoading(false)))
        }
    }
);


export const UpdateAddressGridAddressProperty = createAsyncThunk<void, AddressPropertyUpdated, AsyncThunkConfig>(
    'properties/updateAddressGridAddressProperty',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await gridApi.updateAddressProperty(requestData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Property address has been updated'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(onChangeIsAddressModalOpened(false))
            window.location.reload()
            // thunkAPI.dispatch(GetAllPropertiesAddressPropertiesGridThunk())
        }
    }
)


export const GetPropertiesCountries = createAsyncThunk<CountryType[], void, AsyncThunkConfig>(
    'properties/getGridPropertiesCountries',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await countryApi.getAllCountries()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.countryList, {appStatus: AppStatusType.idle})
                return data.countryList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        }
    }
)


export const GetPropertiesCurrentPropertyMapAddress = createAsyncThunk<any, any, AsyncThunkConfig>(
    'properties/getPropertiesCurrentPropertyMapAddress',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.getCurrentAddressData(requestData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const CheckIsPropertyReqDataRelevantThunk = createAsyncThunk<void, number, AsyncThunkConfig>(
    'properties/checkIsPropertyReqDataRelevant',
    async (gridReqId, thunkAPI) => {
        thunkAPI.dispatch(onSetPropertyReqDataUploading(true))
        try {
            const {
                data,
                status
            } = await requirementsApi.checkIsGridReqMatch(gridReqId)
            if (status === 200) {
                thunkAPI.dispatch(setAppStatus(2))
                thunkAPI.dispatch(setSuccessMessage('Data updated successfully'))
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetIs404Error(false))
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            setTimeout(() => {
                thunkAPI.dispatch(setAppStatus(0))
                thunkAPI.dispatch(setSuccessMessage(''))
            }, 3000)
            thunkAPI.dispatch(onSetPropertyReqDataUploading(false))
        }
    }
)

export const CheckIsPropertyBrokerageReqDataRelevantThunk = createAsyncThunk<void, number, AsyncThunkConfig>(
    'properties/checkIsPropertyBrokerageReqDataRelevant',
    async (prop_id, thunkAPI) => {
        thunkAPI.dispatch(onSetIsBrokerageReqsGridDataLoading(true))
        try {
            const {
                data,
                status
            } = await requirementsApi.checkIsGridBrokerageReqDataMatch(prop_id)
            if (status === 200) {
                thunkAPI.dispatch(setAppStatus(2))
                thunkAPI.dispatch(setSuccessMessage('Data updated successfully'))
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetIs404Error(false))
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            setTimeout(() => {
                thunkAPI.dispatch(setAppStatus(0))
                thunkAPI.dispatch(setSuccessMessage(''))
            }, 3000)
        }
    }
)


export const GetPropertiesContactLang = createAsyncThunk<"CZ" | 'EN', number, AsyncThunkConfig>(
    'properties/getPropertiesContactLang',
    async (reqId, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await contactsApi.getContactLang(reqId)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetSurfaceGridThunk = createAsyncThunk<any, { requestData: GridReqType }, AsyncThunkConfig>(
    'properties/getSurfaceGridData',
    async (requestData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus(AppStatusType.loading));
            thunkAPI.dispatch(onSetIsPropertiesDataLoading(true))
            const {status, data} = await gridApi.getGridSurfaceData(requestData.requestData);
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle});
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetSortingString({sorting_string: data.sorting_rule, gridType: 'Surfaces'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: sortingCondition, gridType: 'Surfaces'}));
                } else {
                    thunkAPI.dispatch(onSetSortingString({sorting_string: null, gridType: 'Surfaces'}));
                    thunkAPI.dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Surfaces'}));
                }
                thunkAPI.dispatch(onSetIsPropertiesDataLoading(false))
                thunkAPI.dispatch(onSetIs404Error(false))

                return data.grid_column_data;
            } else {
                return thunkAPI.rejectWithValue(data?.message);
            }
        } catch (error: any) {
            if (error.response.status === 500) {
                setTimeout(() => {
                    window.location.replace('/requirements');
                }, 5000);
            } else if (error.response.status === 404) {
                thunkAPI.dispatch(onSetIs404Error(true))
            }
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);


export const GetSoldPropertiesPageValues = createAsyncThunk<any, SoldPropertyReqData, AsyncThunkConfig>(
    'properties/getSoldPropertiesPageValues',
    async (reqId, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await templatesApi.soldPropertiesTemplate(reqId)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList[0], {appStatus: AppStatusType.idle})
                return data.resultSetList[0]
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetAllPropertiesGridCurrentPropertyReq = createAsyncThunk<AllPropertiesReqItem[], string, AsyncThunkConfig>(
    'properties/getAllPropertiesGridCurrentPropertyReq',
    async (prop_ref, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await gridApi.getAllPropertiesGridCurrentPropertyReq(prop_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetAllPropertiesPropertyReqs({reqs: data.resultSetList, propId: prop_ref}))
                thunkAPI.dispatch(onSetIsReqOpened({isOpened: true, propId: prop_ref}))
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateBrokerageReqsLetterForOwner = createAsyncThunk<any, CreatePropertyOffer, AsyncThunkConfig>(
    'properties/createBrokerageReqsLetterForOwner',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.sendLetterToOwner(requestData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Letter has been sent'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(clearPropertiesForEmail([]))
            thunkAPI.dispatch(onSetIsSendBtnDisabled(false))
            thunkAPI.dispatch(setAppStatus(0))
        }
    }
)


export const GetBrokerageIsReqExisted = createAsyncThunk<number,
    { prop_ref: number; user_ref: number }, // Argument type for the payload creator.
    AsyncThunkConfig // Extra configuration for the thunk.
    >(
    'properties/getBrokerageIsReqExisted',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await requirementsApi.brokerageIsReqExisted(reqData.prop_ref, reqData.user_ref);
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle});
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetContactsProfessionTitles = createAsyncThunk<ContactsTitlesType[],
    void, // Argument type for the payload creator.
    AsyncThunkConfig // Extra configuration for the thunk.
    >(
    'properties/getContactsProfessionTitles',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactsProfessionTitles()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle});
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetContactsJobTitles = createAsyncThunk<ContactsTitlesType[],
    void, // Argument type for the payload creator.
    AsyncThunkConfig // Extra configuration for the thunk.
    >(
    'properties/getContactsJobTitles',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactsJobPositions()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle});
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);


export interface AddressPropertyDataType {
    "REF": number
    "PK_PROPERTY_ADDRESS": number
    "PROP_CNTRY_CODE": string
    "ADDR_CITY": string
    "ADDR_STREET": string
    "ADDR_ZIP": string
    "AGENT": string
    "REMARKS": string
    "DATE_CREATED": string
    "DATE_MODIFIED": string
    "id": number
    ADDR_STREET_ADDRESS: string
    ADDR_QUARTER: string
}


export interface CountryType {
    "cntry_address_validation": number
    "cntry_app_nf_level": number
    "cntry_app_nf_sortorder": number
    "cntry_code": string
    "cntry_desc_default": string
    "cntry_desc_local": string
    "cntry_desc_original": string
    "cntry_fk_address_format": string
    "cntry_fk_continent": number
    "cntry_fk_language": number
    "cntry_houseno_connect": string
    "cntry_nf_usage_count": number
    "cntry_parent": number
    "cntry_phonecode": string
    "cntry_postbox_alias": string
    "cntry_sign": string
    "cntry_state_admin_level1": string
    "cntry_state_admin_level2": string
    "cntry_state_admin_level3": string
    "cntry_state_admin_level4": string
    "pk_country": number
}


export interface SoldPropertyPageTypes {
    BOOKMARK_NAME: string,
    COMPANY_ABBR: string,
    COMPANY_ABBR_FONT: string,
    COMPANY_ABBR_SIZE: string,
    COMPANY_ABBR_COLOR: string,
    COMPANY_LOGO: string,
    HEADER_TITLE: string,
    HEADER_TITLE_FONT: string,
    HEADER_TITLE_SIZE: string,
    HEADER_TITLE_COLOR: string,
    HEADER_SUBTITLE: string,
    HEADER_SUBTITLE_FONT: string,
    HEADER_SUBTITLE_SIZE: string,
    HEADER_SUBTITLE_COLOR: string,
    PROPERTY_IMAGE: string,
    PROP_DESCRIPTION: string,
    PROP_DESCRIPTION_FONT: string,
    PROP_DESCRIPTION_SIZE: string,
    PROP_DESCRIPTION_COLOR: string,
    PROP_ADDRESS: string,
    PROP_ADDRESS_FONT: string,
    PROP_ADDRESS_SIZE: string,
    PROP_ADDRESS_COLOR: string,
    FOOTER_TITLE: string,
    FOOTER_TITLE_FONT: string,
    FOOTER_TITLE_SIZE: string,
    FOOTER_TITLE_COLOR: string,
    FOOTER_SUBTITLE: string,
    FOOTER_SUBTITLE_FONT: string,
    FOOTER_SUBTITLE_SIZE: string,
    FOOTER_SUBTITLE_COLOR: string,
    CONTACT_DESCRIPTION: string,
    CONTACT_DESCRIPTION_FONT: string,
    CONTACT_DESCRIPTION_SIZE: string,
    CONTACT_DESCRIPTION_COLOR: string,
    CONTACT_TITLE_FONT: string,
    CONTACT_TITLE_SIZE: string,
    CONTACT_TITLE_COLOR: string,
    EMAIL: string,
    OFFICE: string,
    PHONE: string,
    CONTACT_FONT: string,
    CONTACT_SIZE: string,
    CONTACT_COLOR: string,
    EMAIL_TITLE: string,
    OFFICE_TITLE: string,
    PHONE_TITLE: string
    BADGE_COLOR: string
    BADGE_FONT: string
    BADGE_SIZE: string
    BADGE_TEXT: string
}

export interface BrokerageCheckedReq {
    CO_EMAIL: string | null
    CO_NAME: string | null
    CO_REF: number | null
    CREATED_BY: string | null
    DATE_CREATED: string | null
    DATE_MODIFIED: string | null
    DATE_OFFERED: string | null
    DOMAIN: 'RR' | 'OR' | 'CD' | 'RS'
    EMAIL_LIST: string | null
    INFORMER: string | null
    MODIFIED_BY: string | null
    OFFER_STATUS: string | null
    REF_PERSON: number | null
    REMARKS: string | null
    REQ_ID: number | null
    isEmptyContactTo: boolean
    defaultContacts: any[],
    emailGreeting: string,
    contacts: any[]
}

export interface AllContactsType {
    PE_EMAIL: string | null
    PE_FIRST_NAME: string | null
    PE_LAST_NAME: string | null
}

export interface ShownAndHiddenColumnsType {
    checked: boolean
    col_options: null | string | number
    col_pos: number | null
    col_value: null | string | number
    dataType: null | string
    field: null | string
    headerName: null | string
    id: number | null
    isFilterSelected: boolean
    key: number | null
    width: number | null
    withSurface: undefined | boolean
}

export default propertiesSlice.reducer