import React from 'react';
import {ReactComponent as Logo} from './../../img/logo.svg'
import s from './Terms.module.scss'

const Terms = () => {
    const location = window.location.href
    console.log(location.split('/termsAndConditions')[0])
    return (
        <div className={s.inner}>
            <div className={s.header}>
                <Logo className={s.logo}/>
                <p className={s.header__text}>PRIMO PORTAL</p>
            </div>
            <div>
                <h1>Terms and Conditions</h1>
                <div className={s.item}>
                    <h3>IMPRINT</h3>
                    <p>
                        Gesellschaft zur Förderung der Eigenvermarktung mbH develops hardware, software, and online
                        services for the real estate industry. Managing Director and person responsible for the content
                        on this page is <span>Lars Boettger.</span>
                    </p>
                </div>
                <div className={s.item}>
                    <h3>REGISTERED OFFICE, POSTAL ADDRESS, AND DELIVERIES:</h3>
                    <p>
                        Gesellschaft zur Förderung der Eigenvermarktung mbH<br/>
                        Borselstraße 7<br/>
                        22765 Hamburg<br/>
                        Phone: 040/377 075-500<br/>
                        E-mail: <a href="mailto:info@primoportal.de">info@primoportal.de</a>
                    </p>
                </div>
                <div className={s.item}>
                    <h3>INVOICE ADDRESS:</h3>
                    <p>
                        Gesellschaft zur Förderung der Eigenvermarktung mbH<br/>
                        Borselstraße 7<br/>
                        22765 Hamburg<br/>
                        VAT ID: DE285529266<br/>
                        E-mail: <a href="mailto:info@primoportal.de">info@primoportal.de</a>
                    </p>
                </div>
                <div className={s.item}>
                    <h3>DISCLAIMER - LEGAL INFORMATION</h3>
                    <p>
                        Registered in the Commercial Register at the Hamburg Local Court under HRB 124764
                    </p>
                </div>
                <div className={s.item}>
                    <h3>1. LIMITATION OF LIABILITY</h3>
                    <p>
                        The content of this website is created with the utmost care. However, the provider does not
                        guarantee the accuracy, completeness, and timeliness of the provided content. The use of the
                        content of the website is at the user's own risk. Posts identified by name reflect the opinion
                        of the respective author and not necessarily the opinion of the provider. Mere use of the
                        provider's website does not establish any contractual relationship between the user and the
                        provider.
                    </p>
                </div>
                <div className={s.item}>
                    <h3>2. EXTERNAL LINKS</h3>
                    <p>
                        This website contains links to third-party websites ("external links"). These websites are the
                        responsibility of their respective operators. The provider checked the external content for
                        possible legal violations when first creating the external links. At that time, no legal
                        violations were apparent. The provider has no influence on the current and future design and
                        content of linked pages. The inclusion of external links does not imply that the provider adopts
                        the content behind the reference or link as its own. Continuous monitoring of external links by
                        the provider without concrete indications of legal violations is not reasonable. However, if the
                        provider becomes aware of legal violations, such external links will be deleted immediately.
                    </p>
                </div>
                <div className={s.item}>
                    <h3>3. COPYRIGHT AND PERFORMANCE RIGHTS</h3>
                    <p>
                        The content published on this website is subject to German copyright and performance protection
                        laws. Any use not permitted by German copyright and performance protection laws requires the
                        prior written consent of the provider or respective rights holder. This applies particularly to
                        the reproduction, editing, translation, storage, processing, or playback of content in databases
                        or other electronic media and systems. Content and rights of third parties are identified as
                        such. Unauthorized reproduction or distribution of individual content or complete pages is not
                        permitted and is punishable by law. Only the making of copies and downloads for personal,
                        private, and non-commercial use is allowed.<br/>
                        The presentation of this website within external frames is only permissible with written
                        permission.<br/>
                        You can find our privacy policy statement here: <a href="/privacyPolicy" target={'_blank'}>{location.split('/termsAndConditions')[0]}/privacyPolicy</a><br/>
                        Source: Sample disclaimer from Juraforum.de in cooperation with <a href="https://www.experten-branchenbuch.de/" target={'_blank'}>Experten-Branchenbuch.de</a><span></span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;