import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as More} from './../../../img/icons/notifications_threeDots.svg'
import s from './DefaultNotification.module.scss'
import activityS from './../../PropertiesPage/Properties.module.scss'
import NotificationItem from "../NotificationItem/NotificationItem";
import NotificationsActions from "../NotificationsActions/NotificationsActions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetEventsById,
    GetEventsByStatus,
    onDefaultNotificationDatePickerOpened,
    onHeaderNotificationDatePickerOpened,
    onNotificationDatePickerOpened,
    onSetIsDataLoading,
    selectActivityCurrentEvent,
    selectActivityCurrentNotification,
    selectActivityDefaultNotification, selectActivityEvents,
    selectCurrentEventTabStatus,
    selectDefaultNotificationDatePickerOpened,
    selectIsDataLoading,
    UpdateEventStatus,
} from "../../../store/eventsReducer";
import moment, {Moment} from 'moment';
import {selectUserData} from "../../../store/userReducer";
import {DatePicker} from "rsuite";
import {FacebookCircularProgress} from "../../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {Empty} from "antd";


type DefaultNotificationPropsType = {
    visibleEvents: any[]
}

const DefaultNotification = ({visibleEvents}: DefaultNotificationPropsType) => {
    const [openedMenu, setOpenedMenu] = useState(false)
    const outsideDivRef = useRef<HTMLDivElement>(null);
    const currentEvent = useAppSelector(selectActivityCurrentEvent)
    const currentNotification = useAppSelector(selectActivityCurrentNotification)
    const defaultNotification = useAppSelector(selectActivityDefaultNotification)
    const dispatch = useAppDispatch()
    const eventStatus = useAppSelector(selectCurrentEventTabStatus)
    const userData = useAppSelector(selectUserData)
    const [selectedDate, setSelectedDate] = useState(null);
    let activityEvents = useAppSelector(selectActivityEvents)
    // const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
    const isDatePickerOpened = useAppSelector(selectDefaultNotificationDatePickerOpened)
    const isDataLoading = useAppSelector(selectIsDataLoading)
    // useEffect for tracking outside the reset Filters/Sorting click to close it , when user click outside this block
    useEffect
    (() => {
        const closeDivOutsideClick = (event: MouseEvent) => {
            if (outsideDivRef.current && !outsideDivRef.current.contains(event.target as Node)) {
                setOpenedMenu(false);
            }
        };

        document.addEventListener('click', closeDivOutsideClick);
        return () => {
            document.removeEventListener('click', closeDivOutsideClick);
        };
    }, []);


    const onOpenMenu = () => {
        setOpenedMenu(!openedMenu)
        dispatch(onNotificationDatePickerOpened(false))
        dispatch(onHeaderNotificationDatePickerOpened(false))
    }

    const onUpdateEventStatus = (status: string) => {
        if (status !== 'edit data' && status !== 'edit domain' && status !== 'edit contact') {
            dispatch(onSetIsDataLoading(true))
            if (defaultNotification !== null) {
                dispatch(UpdateEventStatus({
                    eventId: Number(defaultNotification!.event_id),
                    requestData: {
                        action_date: moment(defaultNotification.action_date),
                        event_status: {
                            eventStatus: status,
                            description: defaultNotification !== null && defaultNotification!.event_status !== null ? defaultNotification!.event_status.description : ''
                        },
                        is_read: defaultNotification.is_read
                    }
                }))
                    .then(() => {
                        if (eventStatus !== 'All Notifications') {
                            dispatch(GetEventsByStatus({
                                user_ref: Number(userData.user_ref),
                                event_status: [eventStatus]
                            }))
                        } else {
                            dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                        }
                    })
                    .then(() => {
                        dispatch(GetEventsById(Number(defaultNotification!.event_id)))
                            .then(() => {
                                dispatch(onSetIsDataLoading(false))
                            })
                    })
            } else {
                dispatch(onSetIsDataLoading(true))
                dispatch(UpdateEventStatus({
                    eventId: Number(currentNotification!.event_id),
                    requestData: {
                        action_date: moment(currentNotification!.action_date),
                        event_status: {
                            eventStatus: status,
                            description: currentNotification !== null && currentNotification!.event_status !== null ? currentNotification!.event_status.description : ''
                        },
                        is_read: currentNotification!.is_read
                    }
                }))
                    .then(() => {
                        if (eventStatus !== 'All Notifications') {
                            dispatch(GetEventsByStatus({
                                user_ref: Number(userData.user_ref),
                                event_status: [eventStatus]
                            }))
                        } else {
                            dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                        }
                    })
                    .then(() => {
                        dispatch(GetEventsById(Number(currentNotification!.event_id)))
                            .then(() => {
                                dispatch(onSetIsDataLoading(false))
                            })
                    })
            }
        } else if (status === 'edit data') {
            // setIsDatePickerOpened(true)
            dispatch(onDefaultNotificationDatePickerOpened(true))
            dispatch(onNotificationDatePickerOpened(false))

        }


    }

    const onDateChange = () => {
        dispatch(onDefaultNotificationDatePickerOpened(false))
        if (selectedDate) {
            dispatch(onSetIsDataLoading(true))

            dispatch(UpdateEventStatus({
                eventId: Number(defaultNotification !== null ? Number(defaultNotification!.event_id) : Number(currentNotification!.event_id)),
                requestData: {
                    action_date: moment(selectedDate),
                    event_status: {
                        eventStatus: defaultNotification !== null && defaultNotification!.event_status !== null ? defaultNotification!.event_status.eventStatus : currentNotification !== null && currentNotification!.event_status !== null ? currentNotification!.event_status.eventStatus : '',
                        description: defaultNotification !== null && defaultNotification!.event_status !== null ? defaultNotification!.event_status.description : currentNotification !== null && currentNotification!.event_status !== null ? currentNotification!.event_status.description : ''
                    },
                    is_read: defaultNotification !== null ? defaultNotification.is_read : currentNotification!.is_read
                }
            }))
                .then(() => {
                    if (eventStatus !== 'All Notifications') {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [eventStatus]}))

                    } else {
                        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                    }
                })
                .then(() => {
                    dispatch(GetEventsById(Number(defaultNotification !== null ? defaultNotification.event_id : currentNotification!.event_id)))
                })
                .then(() => {
                    dispatch(onSetIsDataLoading(false))
                })
        } else {
            console.warn('Please select a date');
        }

    }
    const handleDateSelect = (date: any) => {
        setSelectedDate(date);
    };

    const onDatePickerClose = () => {
        dispatch(onNotificationDatePickerOpened(false))
        dispatch(onHeaderNotificationDatePickerOpened(false))
        dispatch(onDefaultNotificationDatePickerOpened(false))
    }

    const predefinedBottomRanges = [
        {
            label: 'Close',
            value: () => onDatePickerClose
        },
    ];

    const parsedMoment: Moment = defaultNotification!?.action_date ? moment(defaultNotification!?.action_date) : moment();
    const defaultValue: Date = parsedMoment.toDate();


    console.log(currentEvent)


    return (
        <div
            className={`${s.inner} defaultNotification`}
            style={{
                position: 'relative'
            }}
        >
            <div className={s.inner__top}>
                <p>Notification</p>
                <div
                    onClick={onOpenMenu}
                    className={s.more}
                    ref={outsideDivRef}
                >
                    {
                        eventStatus !== 'sent' && defaultNotification!?.event_status !== null && defaultNotification!?.event_status.isInfo !== 1
                        && visibleEvents && visibleEvents.length
                            ?
                            <More/>
                            :
                            null
                    }

                    {
                        openedMenu && defaultNotification!?.event_status !== null && defaultNotification!?.event_status.isInfo !== 1
                        &&
                        <NotificationsActions
                            onChangeStatus={onUpdateEventStatus}
                            isDefaultNotification={true}
                            event={defaultNotification}
                            currentEventStatus={defaultNotification !== null && defaultNotification!.event_status !== null ? defaultNotification!.event_status.eventStatus : currentNotification !== null && currentNotification!.event_status !== null ? currentNotification!.event_status.eventStatus : ''}
                        />
                    }
                </div>
                {
                    isDatePickerOpened
                    &&
                    <>
                        {/* @ts-ignore */}
                        <DatePicker defaultValue={defaultValue} open={isDatePickerOpened} appearance={'subtle'} onChangeCalendarDate={(value) => handleDateSelect(value)} placement={'leftStart'} onOk={onDateChange} onClose={() => dispatch(onDefaultNotificationDatePickerOpened(false))} ranges={predefinedBottomRanges}
                        />
                    </>

                }
            </div>
            {isDataLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                    }}
                >
                    <FacebookCircularProgress size={30}/>
                </div>
            )}
            {
                visibleEvents.length
                    ?
                    <>
                        <div>
                            {
                                defaultNotification !== null
                                    ?
                                    <NotificationItem
                                        status={defaultNotification!?.event_status !== null ? defaultNotification!?.event_status.eventStatus : ''}
                                        validTill={defaultNotification!?.action_date}
                                        activityNumber={defaultNotification!?.activity_ref}
                                        withActions={false} eventStatus={eventStatus}
                                        isRead={defaultNotification!?.is_read}
                                        description={defaultNotification!?.event_status !== null ? defaultNotification!?.event_status.description : ''}
                                        isLoading={isDataLoading}
                                        eventDescription={defaultNotification!?.event_template !== null ? defaultNotification!?.event_template : null}
                                        event={defaultNotification}
                                        eventComment={null}
                                    />
                                    :
                                    <NotificationItem
                                        status={currentNotification !== null && currentNotification!?.event_status !== null ? currentNotification!?.event_status.eventStatus : ''}
                                        validTill={currentNotification !== null ? currentNotification!?.action_date : ''}
                                        activityNumber={currentNotification !== null ? currentNotification!?.activity_ref : ''}
                                        withActions={false} eventStatus={eventStatus}
                                        isRead={currentNotification !== null ? currentNotification!?.is_read : 1}
                                        description={currentNotification !== null && currentNotification!?.event_status !== null ? currentNotification!?.event_status.description : ''}
                                        isLoading={isDataLoading}
                                        eventDescription={currentNotification !== null && currentNotification!?.event_template !== null ? currentNotification!?.event_template : null}
                                        event={currentNotification!}
                                        eventComment={null}
                                    />
                            }

                        </div>
                        {
                            currentEvent !== null
                            &&
                            <div>
                                <div>
                                    <p className={s.activityDetails}>Activity details</p>
                                    <div className={activityS.activityInner}>
                                        <div className={activityS.activityDescText}>
                                            <div style={{
                                                display: 'flex'
                                            }}>
                                                <div className={activityS.activityDescText__upper}>
                                                    <p>{currentEvent && currentEvent !== null ? currentEvent!.REF : ''}</p>
                                                    {/*<p>{testOpenedActivity.message}</p>*/}
                                                </div>
                                                <div className={activityS.activityDescText__lower}>
                                                    <p>{currentEvent && currentEvent !== null ? currentEvent!.CREATED_BY : ''}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className={activityS.activityDescText__date}>{currentEvent && currentEvent !== null ? moment(currentEvent!.DATE_CREATED).format('DD.MM.YYYY') : ''}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className={activityS.activityInner__sendDate}>Send
                                                date: {currentEvent && currentEvent !== null ? moment(currentEvent!.DATE_CREATED).format('DD.MM.YYYY') : ''}</p>
                                        </div>
                                        {
                                            currentEvent && currentEvent !== null && currentEvent!.REMARKS !== null
                                            &&
                                            <div className={activityS.activityInner__message}>
                                                <p className={activityS.activityInner__messageTitle}>Message</p>
                                                <div className={activityS.activityInner__messageBody}>
                                                    <p>
                                                        {currentEvent && currentEvent !== null ? currentEvent!.REMARKS : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80%'
                    }}>
                        <Empty/>
                    </div>

            }

        </div>
    );
};

export default DefaultNotification;