import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetBrokerageReqsGridData,
    onBrokerageReqsResetFiltersButtonsSelected,
    onChangeBrokerageReqsGridColumnsPosition,
    onChangeBrokerageReqsGridColumnsWidth, onSetBrokerageGridLoaderMessage,
    onSetBrokerageReqs, onSetBrokerageReqsGridSortingString,
    onSetBrokerageReqsLocalSortModel,
    onSetFiltersForBrokerageReqsGrid, onSetIsBrokerageReqsGridDataLoading, selectBrokerageGridMessage,
    selectBrokerageHiddenColumns,
    selectBrokerageReqCheckedProperties, selectBrokerageReqsColumnsForFilters,
    selectBrokerageReqsDefaultColumns,
    selectBrokerageShownColumns,
    selectBrokerageSortingString,
    selectBrokerageSortModel,
    selectGridDataColumns,
    selectGridDataRows, selectIsBrokerageGridDataLoading,
    selectProperty,
    setBrokerageReqsGridColumns,
    setBrokerageReqsGridColumnsForFilters,
    setBrokerageReqsHiddenColumns,
    setBrokerageReqsShownColumns,
} from "../../store/brokerageReqsReducer";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import Box from "@mui/material/Box";
import {DataGridPro} from "@mui/x-data-grid-pro";
import s from "../RequirementsPage/Requirements.module.scss";
import {Checkbox, Empty} from "antd";
import {InputLabel, LinearProgress} from "@mui/material";
import commonS from "../RequirementsPage/CommonTableStyles.module.scss";
import searchIcon from "../../img/icons/searchTable.svg";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";

import {
    brokerageReqCheckedReqs,
    GetAllPropertiesGridCurrentPropertyReq,
    GetPropertiesContactLang,
    GetPropertiesEmailBody, GetPropertiesEmailDomain,
    GetPropertiesEmailGreeting,
    GetPropertiesEmailLinkText,
    GetPropertiesEmailPhotosForLinks,
    GetPropertiesEmailSubject,
    GetPropertiesGridContacts,
    GetPropertiesGridContactsByReqId, GetPropertiesGridContactsUncheckedByReqId,
    onClearPropertyActivities,
    onSetAllPropertiesGridCurrentReqId,
    onSetCurrentReq,
    onSetReqGridHeight,
    ResetAllGridConfigsThunk,
    selectAllPropertiesCurrentReq,
    selectBrokerageCreateContactReqId,
    selectCreateContactModalOpened,
    selectOfferIsSending,
    selectPropertyContactLang,
} from "../../store/propertiesReducer";
import PropertiesOfferModal from "../PropertiesPage/PropertiesOfferModal/PropertiesOfferModal";
import PageTitle from "../common/PageTitle/PageTitle";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {debounce} from "lodash";
import {selectUserData} from "../../store/userReducer";
import {numericComparator} from "../../helpers/sortingDataHelper";
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import {gridApi} from "../../app/api";
import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import CreateContactModal from "../PropertiesCommonComponents/CreateContactModal/CreateContactModal";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";




const BrokerageReqs = () => {
    return (
        <PageWithSidebar>
            <BrokerageReqsPageComponent/>
        </PageWithSidebar>
    )
}


const BrokerageReqsPageComponent = () => {
    const dispatch = useAppDispatch()
    const selectedBrokerageProperty = useAppSelector(selectProperty)
    const gridDataColumns = useAppSelector(selectGridDataColumns)
    const gridDataRows = useAppSelector(selectGridDataRows)
    const [searchTerm, setSearchTerm] = useState('')
    const isGridDataLoading = useAppSelector(selectIsBrokerageGridDataLoading)
    const brokerageReqCheckedProperties = useAppSelector(selectBrokerageReqCheckedProperties)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const divRef = useRef(null)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const [hiddenModal, setHiddenModal] = useState(false)
    const allPropertiesCurrentReq = useAppSelector(selectAllPropertiesCurrentReq)
    const defaultBrokerageReqsGridColumns = useAppSelector(selectBrokerageReqsDefaultColumns)
    const userData = useAppSelector(selectUserData)
    const sortingRule = useAppSelector(selectBrokerageSortingString)
    const [checked, setChecked] = useState<any>([]);
    const shownColumns = useAppSelector(selectBrokerageShownColumns)
    const hiddenColumns = useAppSelector(selectBrokerageHiddenColumns)
    const [filtersShown, setFiltersShown] = useState(false)
    const [localSortModel, setLocalSortModel] = useState({});
    const sortModel = useAppSelector(selectBrokerageSortModel)
    const gridLoaderMessage = useAppSelector(selectBrokerageGridMessage)
    const offerIsSending = useAppSelector(selectOfferIsSending)
    const isCreateModalOpened = useAppSelector(selectCreateContactModalOpened)
    const reqIdForContactCreation = useAppSelector(selectBrokerageCreateContactReqId)
    const brokerageColumnsForFilter = useAppSelector(selectBrokerageReqsColumnsForFilters)
    const brokerageReqsDefaultColumns = useAppSelector(selectBrokerageReqsDefaultColumns)
    useEffect(() => {
        dispatch(onSetBrokerageGridLoaderMessage('Loading'))
        dispatch(GetBrokerageReqsGridData({
            p_agent_ref: Number(userData.user_ref),
            p_prop_ref: Number(selectedBrokerageProperty.PROP_ID!),
            p_sort_order: sortingRule
        }))
            .then(() => {
                dispatch(GetAllPropertiesGridCurrentPropertyReq(String(selectedBrokerageProperty.PROP_ID!)))

            })
    }, [dispatch, selectedBrokerageProperty])

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    let columns = gridDataColumns.map((g: any) => {
        if (g.COL_NAME === 'Actions') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: 0,
                dataType: g.COL_TYPE,
                col_pos: 7,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        }


    })

    useEffect(() => {
        dispatch(setBrokerageReqsShownColumns({
            columns: columns.filter((c: any) => c?.width !== 0).map((c: any, index: number) => {
                if (c.dataType === 'class java.math.BigDecimal') {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: c.width,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                        sortComparator: numericComparator
                    }
                } else {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: c.width,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                    }
                }


            })
        }))
        dispatch(setBrokerageReqsHiddenColumns({
            columns: columns.filter((c: any) => c?.width === 0).map((c: any, index: number) => {
                if (c.dataType === 'class java.math.BigDecimal') {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: 0,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                        sortComparator: numericComparator
                    }
                } else {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: 0,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                    }
                }
            })
        }))
        setChecked(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))

        // eslint-disable-next-line
    }, [dispatch, gridDataColumns])

    const onResetFilters = () => {
        dispatch(onSetBrokerageReqs(defaultBrokerageReqsGridColumns))
        dispatch(onBrokerageReqsResetFiltersButtonsSelected())
        dispatch(onSetFiltersForBrokerageReqsGrid({gridFilters: []}))
        sessionStorageGridFilters.removeBrokeragePropertiesGridFilters()
    }

    const onResetSorting = () => {
        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}))
        setLocalSortModel({})
    }


    // useEffect(() => {
    //     dispatch(setBrokerageReqsGridColumnsForFilters({
    //         columns: gridDataColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {
    //
    //             return {
    //                 title: g.COL_NAME,
    //                 headerName: g.COL_TITLE,
    //                 value: null,
    //                 condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
    //                 dataType: g.COL_TYPE,
    //                 isModalBtnOpened: false,
    //                 isFilterSelected: false,
    //                 filterable: false,
    //             }
    //         })
    //     }))
    // }, [dispatch, gridDataColumns])


    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getBrokeragePropertiesGridFilters()
        if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
            dispatch(setBrokerageReqsGridColumnsForFilters({
                columns: gridDataColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE,
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
            dispatch(onSetFiltersForBrokerageReqsGrid({gridFilters: filterColumnsFromSessionStorage}))
        }
        dispatch(setBrokerageReqsGridColumnsForFilters({
            columns: gridDataColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {

                return {
                    title: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    value: null,
                    condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                    dataType: g.COL_TYPE,
                    isModalBtnOpened: false,
                    isFilterSelected: false,
                    filterable: false,
                }
            })
        }))
    }, [dispatch, gridDataColumns])


    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getBrokeragePropertiesGridFilters()
        if (filterColumnsFromSessionStorage) {
            gridFiltering(filterColumnsFromSessionStorage, brokerageReqsDefaultColumns, brokerageColumnsForFilter, 'Brokerage/Requirements', dispatch)
        }
    }, [dispatch, brokerageReqsDefaultColumns])


    const rows = gridDataRows.filter((row: any) => {
        const entries = Object.entries(row);

        const useStartsWith = searchTerm.endsWith('%');

        const processedSearchTerm = useStartsWith ? searchTerm.slice(0, -1).toLowerCase() : searchTerm.toLowerCase();

        return entries.some(([key, value]) => {
            if (key !== 'MODIFIED_BY') {
                const stringValue = String(value).toLowerCase();

                return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(processedSearchTerm);
            }
            return false;
        });
    });

    useEffect(() => {
        if (brokerageReqCheckedProperties.length) {
            dispatch(GetPropertiesContactLang(brokerageReqCheckedProperties[0]))
        }
    }, [dispatch, brokerageReqCheckedProperties])

    useEffect(() => {
        dispatch(GetPropertiesGridContacts())
    }, [dispatch])

    useEffect(() => {
        if (allPropertiesCurrentReq.length) {
            dispatch(GetPropertiesGridContactsByReqId(allPropertiesCurrentReq[0]?.REQ_ID))
        }


    }, [dispatch, allPropertiesCurrentReq])


    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const onHideModal = () => {
        setHiddenModal(!hiddenModal)
    }

    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };


    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }
    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };


    const onResetConfigs = () => {
        dispatch(setBrokerageReqsShownColumns({columns: []}))
        dispatch(setBrokerageReqsGridColumns([]))
        setSearchTerm('')
        // setSortModel([])
        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}));
        setLocalSortModel({})
        dispatch(onSetIsBrokerageReqsGridDataLoading(true))
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: null,
            grid_type: 'BROKERAGE_REQS'
        }))
            .then(() => {
                dispatch(GetBrokerageReqsGridData({
                    p_agent_ref: Number(userData.user_ref),
                    p_prop_ref: Number(selectedBrokerageProperty.PROP_ID!),
                    p_sort_order: sortingRule
                }))
            })
        dispatch(onSetBrokerageGridLoaderMessage('Reset data is in progress'))
    }


    const onSaveGridConfigs = () => {
        let hiddenColumnsResult;
        let shownColumnsResult;
        if (hiddenColumns.length) {
            hiddenColumnsResult = hiddenColumns.map((yItem: any, index: number) => {
                let xMatch = gridDataColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: 0, COL_POS: shownColumns.length + index}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!hiddenColumns.length) {
            hiddenColumnsResult = []
        }
        if (shownColumns.length) {
            shownColumnsResult = shownColumns.map((yItem: any) => {
                let xMatch = gridDataColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: yItem.width}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!shownColumns.length) {
            shownColumnsResult = shownColumns
        }
        const shownColumnsResultFinal = shownColumnsResult?.map((g: any, index: number) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: index,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        })
        const hiddenColumnsResultFinal = hiddenColumnsResult?.map((g: any) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: g.COL_POS,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        }).filter((c: any) => c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED')

        function mergeArrays(hiddenColumns: any, shownColumns: any) {
            const result = [];
            for (const hiddenColumn of hiddenColumns) {
                const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
                if (matchingObjS) {
                    hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
                }
                result.push(hiddenColumn);
            }
            for (const shownColumn of shownColumns) {
                if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
                    result.push(shownColumn);
                }
            }
            return result;
        }

        const sortConditionStrArray = Object.keys(localSortModel).map(key => {
            // @ts-ignore
            return {"field": localSortModel[key].field, "sort": localSortModel[key].sort};
        });
        const sortConditionStr = sortConditionStrArray.filter((i: any) => i.field !== 'ACTIVITY_EXISTED')?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ');
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REQ_ID DESC',
            grid_type: 'BROKERAGE_REQS',
        }
        dispatch(onSetIsBrokerageReqsGridDataLoading(true))
        gridApi.updateGridColumnData(req)
            .then(() => {
                dispatch(GetBrokerageReqsGridData({
                    p_agent_ref: Number(userData.user_ref),
                    p_prop_ref: Number(selectedBrokerageProperty.PROP_ID!),
                    p_sort_order: sortingRule
                }))
            })
            .catch((e) => {
                console.log(e)
            })
        dispatch(setBrokerageReqsShownColumns({columns: []}))
        dispatch(setBrokerageReqsGridColumns([]))
        dispatch(onSetBrokerageReqsGridSortingString({sorting_string: null}));
        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}));
        dispatch(onSetBrokerageGridLoaderMessage('Saving data is in progress'))
    }
    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])
    const handleSortModelChange = (newModel: any) => {
        const newLocalModel: any = {...localSortModel};
        newModel.forEach((model: any) => {
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
        const mergedArray = [...sortModel, ...newModel];

        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);

        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            };
            mergedArray[index] = updatedObject;
        }

        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: mergedArray}));
    };

    // We have custom column position in our state, so default function, provided MUI data grid doesn't fit our app. So we get the column order from state and then sort them in our state in redux
    const handleStateChange = (params: any) => {
        // Access the grid state
        dispatch(onChangeBrokerageReqsGridColumnsPosition({columns: params.columns.all}))
        dispatch(onChangeBrokerageReqsGridColumnsWidth({columns: params.columns.lookup}))
    };


    // useEffect(() => {
    //     dispatch(GetPropertiesEmailDomain({req_id: gridRowId}))
    //
    // }, [gridRowId, dispatch])

    return (
        <div style={{
            padding: '20px 20px'
        }}>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >

                <div className={commonS.tableInner} style={{
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    {
                        offerIsSending
                        &&
                        <Box sx={{ width: '100%', position: 'absolute', top: '0', left: '0' }}>
                            <LinearProgress />
                        </Box>
                    }

                    <PageTitle
                        backLinktext={`${selectedBrokerageProperty.PROP_ID} ${selectedBrokerageProperty.ADDR_STREET !== null ? `-${selectedBrokerageProperty.ADDR_STREET}` : ''}`}
                        isBackButton={true}
                        backLink={'/properties'}
                        top={'20px'}
                    />
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <h1 className={s.tableTitle} style={{
                                margin: '30px 0 7px 0'
                            }}>Requirements</h1>
                            <PropertiesHeaderButtons
                                onSetFiltersShown={handleFiltersShownClick}
                                onResetConfigs={onResetConfigs}
                                onSaveGridConfigs={onSaveGridConfigs}
                                isDisabledSaveBtn={false}
                                gridType={'Brokerage/Requirements'}
                                resetFilters={() => {}}
                            />
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>
                            <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                                <img src={searchIcon} alt="search"/>
                            </InputLabel>
                            <input
                                className={s.search_input}
                                type="text"
                                value={searchTerm}
                                placeholder={'Search by REQ_ID, Addr street...'}
                                onChange={handleSearchInputChange}
                            />

                            <div>
                                {searchTerm !== ''
                                    &&
                                    <p
                                        onClick={() => setSearchTerm('')}
                                        style={{
                                            marginBottom: '0',
                                            fontSize: '12px',
                                            color: '#9CA3AF',
                                            cursor: "pointer"
                                        }}>x</p>
                                }
                            </div>
                        </div>
                        <PropertiesFilterButtons
                            shownColumns={shownColumns}
                            onResetFilters={onResetFilters}
                            onResetSorting={onResetSorting}
                            gridType={'Brokerage/Requirements'}
                        />
                        <div style={{
                            position: 'relative',
                            width: '100%',
                        }}>
                            {shownColumns.map((c: any) => {
                                return (
                                    <PropertyFilterModal
                                        gridType={'Brokerage/Requirements'}
                                        textValue={c.field}
                                    />
                                )
                            })}
                        </div>
                        {
                            isGridDataLoading
                                ?
                                <div style={{
                                    height: gridContentHeight + 115,
                                    width: '400px',
                                    margin: '0 auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        height: '300px',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}>
                                        <Lottie
                                            // @ts-ignore
                                            config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                        />
                                    </div>

                                    <p style={{
                                        textAlign: 'center'
                                    }}>{gridLoaderMessage}</p>
                                </div>
                                :

                                shownColumns.length
                                    ?
                                    <Box sx={{position: 'relative', width: '100%'}}>
                                        <>
                                            <PropertiesViewOptions
                                                checked={checked}
                                                filtersShown={filtersShown}
                                                setChecked={(checkedArray: string[]) => setChecked(checkedArray)}
                                                setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                                gridType={'Brokerage/Requirements'}
                                            />
                                            <DataGridPro
                                                sx={{
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: 'none',
                                                    borderRadius: '0px',
                                                    // "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                    //     display: "none"
                                                    // },
                                                    "& .MuiDataGrid-cellContent": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: '#111827'
                                                    },
                                                    "& .MuiDataGrid-columnHeaderTitle": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 600,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiDataGrid-row:hover": {
                                                        backgroundColor: 'transparent'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected": {
                                                        backgroundColor: 'transparent'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected:hover": {
                                                        backgroundColor: 'transparent'
                                                    },
                                                    "& .MuiDataGrid-cell:focus": {
                                                        outline: 'none'
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer": {
                                                        color: '#454545',
                                                        position: 'absolute',
                                                        top: '-50px',
                                                        right: '0px'
                                                    },
                                                    // "& .MuiButtonBase-root": {
                                                    //     fontFamily: 'Inter, sans-serif !important',
                                                    //     fontWeight: 600,
                                                    //     color: '#6B7280',
                                                    //     fontSize: '12px',
                                                    //     lineHeight: '20px',
                                                    // },
                                                    "& .MuiTablePagination-displayedRows": {
                                                        margin: '0',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 400,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiPagination-root": {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: '7px 0px'
                                                    },
                                                    "& .MuiButtonBase-root.Mui-selected": {
                                                        backgroundColor: '#F9FAFB',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                        width: '40px',
                                                        height: '40px',
                                                        margin: 0,
                                                        borderRight: '1px solid #D0D5DD',
                                                        borderLeft: '1px solid #D0D5DD',
                                                        borderTop: 'none',
                                                        borderBottom: 'none',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 500,
                                                        color: '#1D2939',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiPagination-ul": {
                                                        border: '1px solid #D0D5DD',
                                                        borderRadius: '8px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                        borderRadius: '0px',
                                                        border: 'none'
                                                    },
                                                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                                                        backgroundColor: '#F9FAFB'
                                                    },
                                                    // "& .MuiDataGrid-virtualScrollerRenderZone": {
                                                    //     width: '100%'
                                                    // },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        overflowY: 'scroll !important',
                                                        height: `${gridContentHeight - 60}px !important`,
                                                        minHeight: height < 800 ? '460px' : 'auto',
                                                    },
                                                    // "& .MuiDataGrid-virtualScrollerContent": {
                                                    //     height: `${gridContentHeight + 100}px !important`
                                                    // },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: '#FCFDFE'
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: 'none'
                                                    },
                                                    "& .MuiDataGrid-columnHeader--moving": {
                                                        backgroundColor: 'transparent'
                                                    }
                                                }}
                                                // apiRef={apiRef}
                                                getRowId={e => e.REQ_ID}
                                                columns={shownColumns.map((column: any, index: number) => ({
                                                    ...column,
                                                    renderCell: (params: any) =>
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={rows}
                                                                    key={index}
                                                                    dataType={column.dataType}

                                                        />,
                                                    renderHeader: (params: any) => {
                                                        return (
                                                            <div style={{
                                                                display: 'flex',
                                                            }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName === 'ACTIVITY_EXISTED'
                                                                ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                            </div>
                                                        );
                                                    },
                                                }))}
                                                rows={rows}
                                                {...rows}
                                                getRowClassName={getRowClassName}
                                                headerHeight={39}
                                                onStateChange={handleStateChange}
                                                hideFooterSelectedRowCount
                                                // onFilterModelChange={handleFilterModelChange}
                                                onSortModelChange={handleSortModelChange}
                                                sortingOrder={['desc', 'asc']}
                                                sortModel={Object.values(localSortModel)}
                                                autoPageSize={true}
                                                // rowHeight={rowHeight}
                                                autoHeight={true}
                                                components={{
                                                    Footer: () =>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}>
                                                                {/*<p*/}
                                                                {/*    className={s.footerText}*/}
                                                                {/*>*/}
                                                                {/*    Rows per page:*/}
                                                                {/*</p>*/}
                                                                {/*<Select*/}
                                                                {/*    value={rowsPerPage}*/}
                                                                {/*    onChange={handleRowsPerPageChange}*/}
                                                                {/*    className={s.rowsPerPage}*/}
                                                                {/*>*/}
                                                                {/*    {rowsPerPageOptions.map((option) => (*/}
                                                                {/*        <Select.Option key={option} value={option}>*/}
                                                                {/*            {option}*/}
                                                                {/*        </Select.Option>*/}
                                                                {/*    ))}*/}
                                                                {/*</Select>*/}
                                                            </div>
                                                            {/*<Pagination*/}
                                                            {/*    count={Math.ceil(rows.length / rowsPerPage)}*/}
                                                            {/*    page={page}*/}
                                                            {/*    onChange={handlePageChange}*/}
                                                            {/*    variant="outlined"*/}
                                                            {/*    shape="rounded"*/}
                                                            {/*/>*/}
                                                            {/*<p className={s.footerText}>*/}
                                                            {/*    Total requirements: {rows.length}*/}
                                                            {/*</p>*/}
                                                        </div>,
                                                    // Row: CustomRow
                                                }}
                                            />
                                        </>

                                    </Box>
                                    :
                                    <div style={{
                                        height: `${gridContentHeight + 150}px`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Empty/>
                                    </div>

                        }
                    </div>
                </div>
                <PropertiesOfferModal
                    height={400}
                    hiddenModal={hiddenModal}
                    gridContentHeight={gridContentHeight - 60}
                    onHideModal={onHideModal}
                    divRef={divRef}
                    gridType={'All Properties/REQ'}
                    contactLang={contactLang}
                    brokeragePropId={selectedBrokerageProperty.PROP_ID!}
                    isBrokerage={true}
                />
            </div>
            {
                isCreateModalOpened
                &&
                <CreateContactModal reqId={reqIdForContactCreation!!} gridType={'All Properties/REQ'}/>
            }
        </div>

    );
};


const CustomCell = React.memo(({
                                   field,
                                   value,
                               }: any) => {
    const dispatch = useAppDispatch()
    const contactLang = useAppSelector(selectPropertyContactLang)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const checkedReqs = useAppSelector(brokerageReqCheckedReqs)
    const selectedBrokerageProperty = useAppSelector(selectProperty)
    const userData = useAppSelector(selectUserData)
    if (field === 'REQ_ID') {
        const handleCheckReqs = (value: any) => {
            dispatch(onClearPropertyActivities())
            setDisabledCheckbox(true)
            if (value.target.checked === true) {
                dispatch(onSetCurrentReq({reqId: value.target.value, isChecked: value.target.checked}))
                if (!checkedReqs.length) {
                    dispatch(GetPropertiesEmailLinkText({
                        prop_id: Number(value.target.name),
                        req_id: Number(value.target.value),
                        propertyType: 'Property',
                        lang: contactLang,
                        gridType: 'All Properties/REQ'
                    }))
                    dispatch(GetPropertiesEmailBody({
                        req_id: Number(value.target.value),
                        gridType: 'All Properties/REQ',
                        lang: contactLang
                    }))
                    dispatch(GetPropertiesEmailPhotosForLinks({
                        p_fk_type: 'Property',
                        p_row_fk: Number(value.target.name),
                        p_row_req: Number(value.target.value)
                    }))
                    dispatch(GetPropertiesEmailSubject({
                        req_id: Number(value.target.value),
                        user_id: userData.user_ref
                    }))
                    dispatch(GetPropertiesEmailDomain({req_id: value.target.value}))
                }
                dispatch(GetPropertiesEmailGreeting({req_id: Number(value.target.value)}))
                dispatch(GetPropertiesGridContactsByReqId({
                    reqId: Number(value.target.value),
                    gridType: 'All Properties/REQ'
                }))
                dispatch(GetPropertiesGridContactsUncheckedByReqId({
                    reqId: Number(value.target.value),
                    gridType: 'All Properties/REQ'
                }))


            } else {
                dispatch(onSetCurrentReq({reqId: value.target.value, isChecked: value.target.checked}))
            }
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
            dispatch(onSetAllPropertiesGridCurrentReqId(value.target.value))
        }

        const reqIdsArray = checkedReqs.map((item: any) => item.REQ_ID);
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div>
                        <Checkbox
                            onChange={handleCheckReqs}
                            name={String(selectedBrokerageProperty.PROP_ID!)}
                            value={value}
                            checked={reqIdsArray.includes(Number(value))}
                            style={{
                                marginRight: '8px'
                            }}
                            disabled={disabledCheckbox}
                        />
                        <span>{value}</span>
                    </div>
                </div>
            </div>

        )
    } else {

        return <div>{value}</div>;


    }
});


export default BrokerageReqs;

