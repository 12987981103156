import React, {useEffect, useState} from 'react';
import PageTitle from "../../common/PageTitle/PageTitle";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    onChangeCreateContactFormField,
    onChangeCreateReqCurrentStep, onSetIsOrganizationStepSkipped,
    selectOrganizationStepSkipped,
    selectRequirementsContactsForCreation,
    selectRequirementsCreateReqCurrentStep,
    selectRequirementsOrganizationForCreation,
} from "../../../store/requirementsReducer";
import s from "./CreateContactForReq.module.scss";
import {message, Radio, RadioChangeEvent, Select} from 'antd';
import {GetContactsJobTitles, GetPropertiesCountries,} from "../../../store/propertiesReducer";
import CreateContactStep from "../CreateContactStep/CreateContactStep";
import CreateOrganizationStep from "../CreateOrganizationStep/CreateOrganizationStep";
import CreateReqStep from "../CreateReqStep/CreateReqStep";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
import {selectUserData} from "../../../store/userReducer";
import CreationModal from "../../common/CreationModal/CreationModal";
import FormStepsSelect from "../CreateReqMobileSelect/CreateReqMobileSelect";


const CreateContactForReq = () => {
    return (
        <PageWithSidebar>
            <CreateContactForReqComponent/>
        </PageWithSidebar>
    )
}
const {Option} = Select;

const CreateContactForReqComponent = () => {
    const dispatch = useAppDispatch()
    const formStep = useAppSelector(selectRequirementsCreateReqCurrentStep)
    const isCreateOrgStepSkipped = useAppSelector(selectOrganizationStepSkipped)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const userData = useAppSelector(selectUserData)
    const [openedDomainModal, setOpenedDomainModal] = useState<boolean>(false)
    const contactsForCreation = useAppSelector(selectRequirementsContactsForCreation)
    const organizationFormFields = useAppSelector(selectRequirementsOrganizationForCreation)
    const organizationId = gridDataHelper.getCurrentSearchedCompanyId()
    const [skipOrgModalOpened, setSkipOrgModalOpened] = useState(organizationId === false || organizationId === null)
    const [repeatedSkipOrgModalOpened, setRepeatedSkipOrgModalOpened] = useState(false)


    useEffect(() => {
        if (!skipOrgModalOpened) {
            if (!isCreateOrgStepSkipped) {
                if (contactsForCreation[0].pe_department === null || contactsForCreation[0].pe_department === 'null') {
                    if (userData.department === null || userData.department === 'null') {
                        setOpenedDomainModal(true)
                    } else {
                        gridDataHelper.setCurrentCreateReqDomain(userData.department)
                        dispatch(onChangeCreateContactFormField({
                            value: userData.department,
                            inputName: 'pe_department',
                            contactId: 0
                        }))
                    }
                }
            } else {
                if (contactsForCreation[0].pe_department === null || contactsForCreation[0].pe_department === 'null') {
                    if (
                        (userData.department === null && gridDataHelper.getCurrentCreateReqDomain() === null)
                        ||
                        (userData.department === 'null' && gridDataHelper.getCurrentCreateReqDomain() === 'null')
                        ||
                        (userData.department === 'null' && gridDataHelper.getCurrentCreateReqDomain() === null)
                        ||
                        (userData.department === null && gridDataHelper.getCurrentCreateReqDomain() === 'null')
                    ) {
                        setOpenedDomainModal(true)
                    } else {
                        dispatch(onChangeCreateContactFormField({
                            value: gridDataHelper.getCurrentCreateReqDomain(),
                            inputName: 'pe_department',
                            contactId: 0
                        }))
                    }
                }
            }
        }
    }, [userData.department, isCreateOrgStepSkipped, skipOrgModalOpened, contactsForCreation[0].pe_department])

    const formSteps = [
        {
            title: 'Create contact',
            img: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity={formStep === 'Create contact' ? '1' : "0.6"}>
                    <path
                        d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M20 8V14M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z"
                        stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>,
            arrowImg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        },
        // {
        //     title: 'Create organization',
        //     img: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //         <g opacity={formStep === 'Create organization' ? '1' : "0.6"}>
        //             <path
        //                 d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
        //                 stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        //         </g>
        //     </svg>,
        //     arrowImg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        //         <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
        //               strokeLinejoin="round"/>
        //     </svg>
        // },
        ...(!isCreateOrgStepSkipped ? [{
            title: 'Create organization',
            img: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity={formStep === 'Create organization' ? '1' : "0.6"}>
                    <path
                        d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                        stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>,
            arrowImg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        }] : []),
        {
            title: 'Create requirement',
            img: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity={formStep === 'Create requirement' ? '1' : "0.6"}>
                    <path d="M18 20V10M12 20V4M6 20V14" stroke="#0707FA" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </g>
            </svg>,
            arrowImg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        }
    ];


    const onChangeCurrentStep = (currentStep: string) => {
        if (currentStep === 'Create requirement') {
            const requiredFields = ['pe_first_name', 'pe_last_name', 'pe_email', 'pe_lang_index']
            // @ts-ignore
            const invalidContacts = contactsForCreation.filter(contact => requiredFields.some(field => !contact[field]));
            if (!isCreateOrgStepSkipped && (invalidContacts.length > 0 || (organizationFormFields.co_name === null || organizationFormFields.co_street_address === null || organizationFormFields.co_city === null || organizationFormFields.co_zip === null))) {
                message.warn('Please fill fields in create contact and organization forms to view create requirement form', 10)
            } else {
                if (invalidContacts.length > 0) {
                    message.warn('Please fill fields in create contact to view create requirement form', 10)
                } else {
                    dispatch(onChangeCreateReqCurrentStep(currentStep))
                }

            }
        } else {
            dispatch(onChangeCreateReqCurrentStep(currentStep))
        }

    }

    useEffect(() => {
        dispatch(GetContactsJobTitles())
        dispatch(GetPropertiesCountries())
    }, [dispatch])

    const handleChange = (e: RadioChangeEvent) => {
        gridDataHelper.setCurrentCreateReqDomain(e.target.value)
        dispatch(onChangeCreateContactFormField({value: e.target.value, inputName: 'pe_department', contactId: 0}))
    };


    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'initial';
        };
    }, []);


    const onSkipOrganizationStep = () => {
        // dispatch(onSetIsOrganizationStepSkipped(true))
        if (repeatedSkipOrgModalOpened) {
            document.body.style.overflow = 'initial';
        }
        setSkipOrgModalOpened(false)
        document.body.style.overflow = 'initial';
        setRepeatedSkipOrgModalOpened(false)
        setTimeout(() => {
            dispatch(onSetIsOrganizationStepSkipped(true))
        }, 1000)
    }


    const onCreateOrgStepConfirm = () => {
        if (repeatedSkipOrgModalOpened) {
            onChangeCurrentStep('Create organization')
        }
        setRepeatedSkipOrgModalOpened(false)
        setSkipOrgModalOpened(false)
        document.body.style.overflow = 'initial';
        setTimeout(() => {
            dispatch(onSetIsOrganizationStepSkipped(false))
        }, 1000)
    }


    const onDomainChoose = () => {
        document.body.style.overflow = 'initial';
        setOpenedDomainModal(false)
    }


    return (
        <div className={s.inner}>
            {
                formStep === 'Create contact'
                    ?
                    <PageTitle
                        backLinktext={`Back`}
                        isBackButton={true}
                        backLink={'/requirements'}
                        top={'125px'}
                        left={currentScreenWidth!! > 1024 ? '185px' : currentScreenWidth!! > 768 ? '100px' : '20px'}
                    />
                    :
                    formStep === 'Create organization'
                        ?
                        <div onClick={() => onChangeCurrentStep('Create contact')}>
                            <PageTitle
                                backLinktext={`Back`}
                                isBackButton={true}
                                backLink={'/requirements'}
                                top={'125px'}
                                left={currentScreenWidth!! > 1024 ? '185px' : currentScreenWidth!! > 768 ? '100px' : '20px'}
                                isLinkDisabled={true}
                            />
                        </div>
                        :
                        formStep === 'Create requirement' && isCreateOrgStepSkipped
                            ?
                            <div onClick={() => onChangeCurrentStep('Create contact')}>
                                <PageTitle
                                    backLinktext={`Back`}
                                    isBackButton={true}
                                    backLink={'/requirements'}
                                    top={'125px'}
                                    left={currentScreenWidth!! > 1024 ? '185px' : currentScreenWidth!! > 768 ? '100px' : '20px'}
                                    isLinkDisabled={true}
                                />
                            </div>
                            :
                            <div onClick={() => onChangeCurrentStep('Create organization')}>
                                <PageTitle
                                    backLinktext={`Back`}
                                    isBackButton={true}
                                    backLink={'/requirements'}
                                    top={'125px'}
                                    left={currentScreenWidth!! > 1024 ? '185px' : currentScreenWidth!! > 768 ? '100px' : '20px'}
                                    isLinkDisabled={true}
                                />
                            </div>

            }
            {
                currentScreenWidth!! > 480
                    ?
                    <div className={s.formSteps} style={{
                        marginLeft: '0',
                        justifyContent: isCreateOrgStepSkipped ? 'flex-start' : 'space-between'
                    }}>
                        {
                            formSteps.map((step, i) => {
                                return (
                                    <div
                                        onClick={() => onChangeCurrentStep(step.title)}
                                        key={i}
                                        style={{
                                            marginRight: isCreateOrgStepSkipped && step.title === 'Create contact' ? '30%' : '0'
                                        }}
                                    >
                                        <span className={s.formSteps__icon}>
                                            {step.img}
                                        </span>
                                        <p className={s.formSteps__title} style={{
                                            opacity: formStep === step.title ? '1' : "0.6"
                                        }}>{step.title}</p>
                                        <span className={s.formSteps__arrowImg}>
                                            {step.arrowImg}
                                        </span>
                                    </div>

                                )
                            })
                        }
                    </div>
                    :
                    <FormStepsSelect formSteps={formSteps}/>
            }

            {
                formStep === 'Create contact'
                    ?
                    <CreateContactStep/>
                    :
                    formStep === 'Create organization'
                        ?
                        <CreateOrganizationStep/>
                        :
                        <CreateReqStep/>
            }
            {
                skipOrgModalOpened
                &&
                <CreationModal
                    title={'Create Organization'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg" style={{marginBottom: '20px'}}>
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <g clipPath="url(#clip0_3984_47994)">
                            <path
                                d="M33 37V35C33 33.9391 32.5786 32.9217 31.8284 32.1716C31.0783 31.4214 30.0609 31 29 31H21C19.9391 31 18.9217 31.4214 18.1716 32.1716C17.4214 32.9217 17 33.9391 17 35V37M39 37V35C38.9993 34.1137 38.7044 33.2528 38.1614 32.5523C37.6184 31.8519 36.8581 31.3516 36 31.13M32 19.13C32.8604 19.3503 33.623 19.8507 34.1676 20.5523C34.7122 21.2539 35.0078 22.1168 35.0078 23.005C35.0078 23.8932 34.7122 24.7561 34.1676 25.4577C33.623 26.1593 32.8604 26.6597 32 26.88M29 23C29 25.2091 27.2091 27 25 27C22.7909 27 21 25.2091 21 23C21 20.7909 22.7909 19 25 19C27.2091 19 29 20.7909 29 23Z"
                                stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3984_47994">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    }
                    onCancel={onSkipOrganizationStep}
                    onSave={onCreateOrgStepConfirm}
                    loading={false}
                    withMargin={false}
                    customButtons={false}
                    buttonOkText={'Create'}
                    buttonCancelText={'Skip'}
                    isHiddenBtn={false}
                >

                    <p className={s.skipOrgText}>Would you like to create an organization for this contact, or skip this
                        step?</p>


                </CreationModal>

            }
            {
                openedDomainModal
                &&
                <CreationModal
                    title={'Select Domain'}
                    image={''}
                    onCancel={() => {
                    }}
                    onSave={onDomainChoose}
                    loading={false}
                    withMargin={false}
                    customButtons={true}
                    buttonOkText={'Confirm'}
                    buttonCancelText={'Cancel'}
                    isHiddenBtn={true}
                >
                    <p className={s.modalText}>Before you start creating requirements, select a domain</p>
                    <div style={{
                        width: '100%'
                    }}>
                        <Radio.Group onChange={handleChange} style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Radio value="OR">OR</Radio>
                            <Radio value="RS">RS</Radio>
                            <Radio value="CD">CD</Radio>
                            <Radio value="RR">RR</Radio>
                        </Radio.Group>
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default CreateContactForReq;