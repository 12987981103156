import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {companiesApi, gridApi} from "../app/api";

interface InitialStateType {
    currentScreenWidth: number | null
    isMobileFiltersOpened: boolean
    companySources: {id: number, value: string}[]
    languages: {id: number, value: string}[]
    propertyTypes: {id: number, value: string}[]
    prahaCityParts:  {id: number, value: string, checked: boolean}[]
    accomidationTypes:  {id: number, value: string}[]
    reconstructionTypes: {id: number, value: string}[]
    currenciesTypes: {id: number, value: string}[]
    surfaceUsages: {id: number, value: string}[]
}

const initialState: InitialStateType = {
    currentScreenWidth: 0,
    isMobileFiltersOpened: false,
    companySources: [],
    languages: [],
    propertyTypes: [],
    prahaCityParts: [],
    accomidationTypes: [],
    reconstructionTypes: [],
    currenciesTypes: [],
    surfaceUsages: []
}


export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        setCurrentScreenWidth: (state, action: PayloadAction<number>) => {
            state.currentScreenWidth = action.payload
        },
        setIsMobileFiltersOpened: (state, action: PayloadAction<boolean>) => {
            state.isMobileFiltersOpened = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCompanySources.fulfilled, (state, action) => {
                state.companySources = action.payload
            })
            .addCase(GetLanguages.fulfilled, (state, action) => {
                state.languages = action.payload
            })
            .addCase(GetPropertyTypes.fulfilled, (state, action) => {
                state.propertyTypes = action.payload
            })
            .addCase(GetPrahaCityParts.fulfilled, (state, action) => {
                state.prahaCityParts = action.payload
            })
            .addCase(GetAccomidationTypes.fulfilled, (state, action) => {
                state.accomidationTypes = action.payload
            })
            .addCase(GetReconstructionTypes.fulfilled, (state, action) => {
                state.reconstructionTypes = action.payload
            })
            .addCase(GetCurrenciesTypes.fulfilled, (state, action) => {
                state.currenciesTypes = action.payload
            })
            .addCase(GetSurfaceUsagesTypes.fulfilled, (state, action) => {
                state.surfaceUsages = action.payload
            })
    }
})

export const {setCurrentScreenWidth, setIsMobileFiltersOpened} = utilitySlice.actions

export const selectCurrentScreenWidth = (state: RootState): number | null => state.utility.currentScreenWidth
export const selectIsMobileFiltersOpened = (state: RootState): boolean => state.utility.isMobileFiltersOpened
export const selectCompanySources = (state: RootState): {id: number, value: string}[] => state.utility.companySources
export const selectLanguages = (state: RootState): {id: number, value: string}[] => state.utility.languages
export const selectPropertyTypes = (state: RootState): {id: number, value: string}[] => state.utility.propertyTypes
export const selectPrahaCityParts = (state: RootState): {id: number, value: string, checked: boolean}[] => state.utility.prahaCityParts
export const selectAccomidationTypes = (state: RootState): {id: number, value: string}[] => state.utility.accomidationTypes
export const selectReconstructionTypes = (state: RootState): {id: number, value: string}[] => state.utility.reconstructionTypes
export const selectCurrenciesTypes = (state: RootState): {id: number, value: string}[] => state.utility.currenciesTypes
export const selectSurfaceUsagesTypes = (state: RootState): {id: number, value: string}[] => state.utility.surfaceUsages

export const GetCompanySources = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getCompanySources',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getCompanySources()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetLanguages = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getCompanyLanguages',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getLanguages()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPropertyTypes = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getPropertyTypes',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getPropertyTypes()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetPrahaCityParts = createAsyncThunk<{id: number, value: string, checked: boolean}[], void, AsyncThunkConfig>(
    'utility/getPrahaCityParts',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getPrahaCityParts()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetAccomidationTypes = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getAccomidationTypes',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getAccomidationTypes()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetReconstructionTypes = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getReconstructionTypes',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getReconstructionTypes()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetCurrenciesTypes = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getCurrenciesTypes',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getCurrencies()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetSurfaceUsagesTypes = createAsyncThunk<{id: number, value: string}[], void, AsyncThunkConfig>(
    'utility/getSurfaceUsages',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await companiesApi.getSurfaceUsages()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.catalogs, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data.catalogs)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default utilitySlice.reducer