import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetRequirementsByCompanyIdThunk,
    GetRequirementsByContactIdThunk,
    onChangeCreateContactFormField,
    onSetIsOrganizationStepSkipped,
    selectIsReqContactsAndCompaniesLoading,
    selectOrganizationName,
    selectOrganizationStepSkipped,
    selectRequirementsSearchedByCompany,
    selectRequirementsSearchedByContacts
} from "../../../store/requirementsReducer";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import s from './../Requirements.module.scss'
import {Box, InputLabel, Pagination} from '@mui/material';
import searchIcon from "../../../img/icons/searchTable.svg";
import {Lottie} from '@crello/react-lottie';
import {DataGridPro, GridRow, useGridApiRef} from '@mui/x-data-grid-pro';
import {debounce} from "../../../customFunctions/debounceScreenHeightChange";
import {GetPropertiesGridContacts, onSetReqGridHeight, selectContactsForEmail} from "../../../store/propertiesReducer";
import {Radio, RadioChangeEvent, Select} from "antd";
import animatedLoader from "../../../img/Loader.json";
import PageTitle from "../../common/PageTitle/PageTitle";
import {useNavigate} from "react-router";
import {
    selectOrganizationId,
    selectRequirementsSearchedContact,
} from "../../../store/activitesGrid";
import CreationModal from '../../common/CreationModal/CreationModal';
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
import {selectUserData} from "../../../store/userReducer";

const ReqsSearchedByContacts = () => {
    return (
        <PageWithSidebar>
            <ReqsSearchedByContactsComponent/>
        </PageWithSidebar>
    )
}


const ReqsSearchedByContactsComponent = () => {
    const isCreateOrgStepSkipped = useAppSelector(selectOrganizationStepSkipped)
    const requirementsSearchedByContacts = useAppSelector(selectRequirementsSearchedByContacts)
    const requirementsSearchedByCompany = useAppSelector(selectRequirementsSearchedByCompany)
    const requirements = isCreateOrgStepSkipped ? requirementsSearchedByCompany : requirementsSearchedByContacts
    const currentContactRef = gridDataHelper.getCurrentSearchedContactId()
    const dispatch = useAppDispatch()
    const [searchTerm, setSearchTerm] = useState('')
    const [height, setHeight] = useState(window.innerHeight)
    const [gridContentHeight, setGridContentHeight] = useState(height - 340)
    const rowsPerPageOptions = [100, 150, 200];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const navigate = useNavigate()
    const [openedDomainModal, setOpenedDomainModal] = useState<boolean>(false)
    const contact = useAppSelector(selectRequirementsSearchedContact)
    const organizationName = useAppSelector(selectOrganizationName)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const userData = useAppSelector(selectUserData)
    const organizationId = useAppSelector(selectOrganizationId)
    const isLoading = useAppSelector(selectIsReqContactsAndCompaniesLoading)

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (isCreateOrgStepSkipped) {
            dispatch(GetRequirementsByCompanyIdThunk(organizationId!!))
        } else {
            dispatch(GetRequirementsByContactIdThunk(currentContactRef))
        }

    }, [currentContactRef, dispatch, organizationId, isCreateOrgStepSkipped])

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    }

    const columns = [
        {
            field: 'REF',
            headerName: 'REQ',
            flex: 0.1,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                            color: '#111827',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.REF}
                    </div>)

            }
        },
        {
            field: 'NAME',
            headerName: 'Name',
            flex: 0.2,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                            color: '#111827',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.NAME}
                    </div>)

            }
        },
        {
            field: 'DATE_MODIFIED',
            headerName: 'Date',
            flex: 0.2,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                            color: '#111827',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.DATE_MODIFIED}
                    </div>)

            }
        },
        {
            field: 'REMARKS',
            headerName: 'Remarks',
            flex: 0.2,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                            color: '#111827',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.REMARKS}
                    </div>)

            }
        }
    ]

    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    }
    const apiRef = useGridApiRef()

    function handleRowsPerPageChange(event: any) {
        console.log(event)
        setRowsPerPage(event)
    }

    const [page, setPage] = React.useState(1);


    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    let rows = requirements.filter((row: any) => {
        const entries = Object.entries(row);
        return entries.some(([key, value]) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
    });


    useEffect(() => {
        dispatch(GetPropertiesGridContacts())
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleChange = (e: RadioChangeEvent) => {
        gridDataHelper.setCurrentCreateReqDomain(e.target.value)
        dispatch(onChangeCreateContactFormField({value: e.target.value, inputName: 'pe_department', contactId: 0}))
    };

    const onCreateReq = () => {
        if (!isCreateOrgStepSkipped) {
            navigate('/requirements/create-req')
            // gridDataHelper.setCurrentCreateReqDomain(userData.department)
            // dispatch(onChangeCreateContactFormField({ value: userData.department, inputName : 'pe_department', contactId: 0 }))
        } else {
            navigate('/requirements/create-req-contact')
            dispatch(onSetIsOrganizationStepSkipped(true))
            // gridDataHelper.setCurrentCreateReqDomain(userData.department)
            // dispatch(onChangeCreateContactFormField({ value: userData.department, inputName : 'pe_department', contactId: 0 }))
        }
    }


    const onOpenCreateReq = () => {
        if (userData.department === null || userData.department === 'null') {
            setOpenedDomainModal(true)
        } else {
            if (!isCreateOrgStepSkipped) {
                navigate('/requirements/create-req')
                gridDataHelper.setCurrentCreateReqDomain(userData.department)
                dispatch(onChangeCreateContactFormField({
                    value: userData.department,
                    inputName: 'pe_department',
                    contactId: 0
                }))
            } else {
                navigate('/requirements/create-req-contact')
                gridDataHelper.setCurrentCreateReqDomain(userData.department)
                dispatch(onChangeCreateContactFormField({
                    value: userData.department,
                    inputName: 'pe_department',
                    contactId: 0
                }))
            }
        }
    }

    return (
        <div style={{
            marginTop: '30px'
        }}>
            <PageTitle
                backLinktext={`Back`}
                isBackButton={true}
                backLink={'/requirements'}
                top={'110px'}
                left={currentScreenWidth!! > 768 ? '70px' : '20px'}
            />
            <div className={s.tableInner} style={{
                border: "none"
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: currentScreenWidth!! > 768 ? 'row' : 'column'
                }}>
                    {
                        isCreateOrgStepSkipped
                            ?
                            <h1 className={s.tableTitle}>List of requirements for the
                                "{organizationName}"</h1>
                            :
                            <h1 className={s.tableTitle}>List of requirements for the
                                "{contact ? contact!.company!! : 'company'}", {contact ? contact!.name : 'contact'}</h1>
                    }

                    <div
                        style={{
                            marginRight: '50px',
                            marginLeft: currentScreenWidth!! > 768 ? '0px' : '20px',
                            marginBottom: currentScreenWidth!! > 768 ? '0px' : '16px',
                            marginTop: currentScreenWidth!! > 768 ? '0px' : '9px',
                        }}
                        onClick={onOpenCreateReq}
                    >
                        <PrimaryButton
                            text={'Create REQ'}
                            isAddButton={true}
                            width={'155px'}
                            height={currentScreenWidth!! > 768 ? '32px' : '40px'}
                        />
                    </div>

                </div>
                <div className={s.mobileHeader}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        marginLeft: '17px'
                    }}>
                        <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                            <img src={searchIcon} alt="search"/>
                        </InputLabel>
                        <input
                            className={s.search_input}
                            type="text"
                            value={searchTerm}
                            placeholder={'Search by REQ, Name, Contacts'}
                            onChange={handleSearchInputChange}
                        />

                        <div>
                            {searchTerm !== ''
                                &&
                                <p
                                    onClick={() => setSearchTerm('')}
                                    style={{
                                        marginBottom: '0',
                                        fontSize: '12px',
                                        color: '#9CA3AF',
                                        cursor: "pointer"
                                    }}>x</p>
                            }
                        </div>
                    </div>
                </div>
                {
                    isLoading
                        ?
                        <div style={{
                            height: gridContentHeight + 115,
                            width: '400px',
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                height: '300px',
                                width: '100%',
                                textAlign: 'center'
                            }}>
                                <Lottie
                                    // @ts-ignore
                                    config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                />
                            </div>

                            <p style={{
                                textAlign: 'center'
                            }}>Loading</p>
                        </div>
                        :
                        <Box sx={{position: 'relative', width: '100%', padding: '0 10px'}}>
                            <>
                                <DataGridPro
                                    getRowClassName={getRowClassName}
                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected:hover": {
                                            backgroundColor: 'transparent'
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-50px',
                                            right: '0px'
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            margin: '0',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 400,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiPagination-root": {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '7px 0px'
                                        },
                                        "& .MuiButtonBase-root.Mui-selected": {
                                            backgroundColor: '#F9FAFB',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                            width: '40px',
                                            height: '40px',
                                            margin: 0,
                                            borderRight: '1px solid #D0D5DD',
                                            borderLeft: '1px solid #D0D5DD',
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 500,
                                            color: '#1D2939',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiPagination-ul": {
                                            border: '1px solid #D0D5DD',
                                            borderRadius: '8px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                            borderRadius: '0px',
                                            border: 'none'
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll !important',
                                            height: `${gridContentHeight - 60}px !important`,
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: '#FCFDFE'
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: 'none'
                                        },
                                        "& .MuiDataGrid-columnHeader--moving": {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    apiRef={apiRef}
                                    getRowId={e => e.REF}
                                    columns={columns}
                                    rows={rows}
                                    {...rows}
                                    headerHeight={39}
                                    // onStateChange={handleStateChange}
                                    hideFooterSelectedRowCount
                                    // onSortModelChange={handleSortModelChange}
                                    sortingOrder={['desc', 'asc']}
                                    // sortModel={Object.values(localSortModel)}
                                    autoPageSize={true}
                                    rowHeight={25}
                                    autoHeight={true}
                                    components={{
                                        Footer: () =>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}>
                                                {
                                                    currentScreenWidth! > 768
                                                    &&
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                        <p
                                                            className={s.footerText}
                                                        >
                                                            Rows per page:
                                                        </p>
                                                        <Select
                                                            value={rowsPerPage}
                                                            onChange={handleRowsPerPageChange}
                                                            className={s.rowsPerPage}
                                                        >
                                                            {rowsPerPageOptions.map((option) => (
                                                                <Select.Option key={option} value={option}>
                                                                    {option}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                }
                                                <Pagination
                                                    count={Math.ceil(rows.length / rowsPerPage)}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                                {
                                                    currentScreenWidth! > 768
                                                    &&
                                                    <p className={s.footerText}>
                                                        Total requirements: {rows.length}
                                                    </p>
                                                }
                                            </div>,
                                        Row: CustomRow
                                    }}
                                />
                            </>

                        </Box>


                }
            </div>
            {
                openedDomainModal
                &&
                <CreationModal
                    title={'Select Domain'}
                    image={''}
                    onCancel={() => setOpenedDomainModal(false)}
                    onSave={onCreateReq}
                    loading={false}
                    withMargin={false}
                    customButtons={true}
                    buttonOkText={'Confirm'}
                    buttonCancelText={'Cancel'}
                    width={'435px'}
                >
                    <p className={s.modalText}>Before you start creating requirements, select a domain</p>
                    <div style={{
                        width: '100%'
                    }}>
                        <Radio.Group onChange={handleChange} style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Radio value="OR">OR</Radio>
                            <Radio value="RS">RS</Radio>
                            <Radio value="CD">CD</Radio>
                            <Radio value="RR">RR</Radio>
                        </Radio.Group>
                    </div>
                </CreationModal>
            }
        </div>
    );
};


const CustomRow = (props: any) => {
    const [height, setHeight] = useState(50);
    const [dragging, setDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startHeight, setStartHeight] = useState(0);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const divRef = useRef(null);
    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", resize);
            document.addEventListener("mouseup", stopResize);
        } else {
            document.removeEventListener("mousemove", resize);
            document.removeEventListener("mouseup", stopResize);
        }
        return () => {
            document.removeEventListener("mousemove", resize);
            document.removeEventListener("mouseup", stopResize);
        };
        // eslint-disable-next-line
    }, [dragging]);

    const startResize = (e: any) => {
        setDragging(true);
        setStartY(e.clientY);
        // @ts-ignore
        setStartHeight(divRef.current.offsetHeight);
    };

    const stopResize = () => {
        setDragging(false);
    };

    const resize = (e: any) => {
        const delta = e.clientY - startY;
        setHeight(startHeight + delta);
    };


    const onTableRowClick = (e: any) => {
        // console.log(e)
        // gridDataHelper.setRowId(e.rowId)
        // gridDataHelper.setRowTitle(e.row.CO_NAME)
        // gridDataHelper.setReqGridDomain(e.row.DOMAIN)
        // navigate('/requirements/properties')
        // gridDataHelper.setReqGridRowData(e.row)
        // dispatch(onSetCurrentModalTab('General'))
    }

    const {className, row, ...other} = props;

    return (
        <div
            ref={divRef}
            className="resizable-div"
            // className={`${className} custom-row`}
            style={{height: `${height}px`}}
            onDoubleClick={() => {
                onTableRowClick(props)
            }}
            {...other}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                height: '100%'
            }}>
                <GridRow {...props} />
            </div>

            <div className="handle handle-bottom" onMouseDown={startResize}></div>
        </div>
    );
};


export default ReqsSearchedByContacts;