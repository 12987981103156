import React, {useEffect, useLayoutEffect, useState} from 'react';
import NotificationItem from "./NotificationItem/NotificationItem";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import s from './Notifications.module.scss'
import {debounce} from "lodash";
import DefaultNotification from "./DefaultNotification/DefaultNotification";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    ActivityEventType,
    ContactByEventId,
    GetEventsByActivityId, GetEventsById,
    GetEventsByStatus, onAddContacts, onAddContactsForUpdate,
    onChangeSelectedDatesRange, onClearAddContacts,
    onOpenContactsModal, onOpenDomainModal,
    onRemoveContacts,
    onSetActivityCurrentNotification, onSetCurrentEvent,
    onSetCurrentEventTabStatus,
    onSetDefaultNotification, onSetDefaultNotificationWhenEventWithNoActivityRef, selectActivityCurrentNotification,
    selectActivityEvents,
    selectContactsByEventId, selectCurrentEventId, selectCurrentEventTabStatus,
    selectDatesRange,
    selectIsContactsModalOpened,
    selectIsDataLoading, selectIsDomainModalOpened, selectUpdatedContacts,
    UpdateContactByEventId, UpdateDomainByEventId
} from "../../store/eventsReducer";
import {selectUserData} from "../../store/userReducer";
import {DateRangePicker} from "rsuite";
import {addMonths, addDays, startOfWeek ,endOfWeek, subDays, startOfMonth, endOfMonth } from 'date-fns';
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {Empty, message, Select} from "antd";
import CreationModal from "../common/CreationModal/CreationModal";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {
    GetActivitiesContactsThunk,
    onChangeModalActivityFormField,
    onClearActivitiesContacts,
    onClearSearchedCompaniesViaContact,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSwitchInputsMode, selectActivityFormContacts,
    selectIsInputsSwitched, selectOrganizationId
} from "../../store/activitesGrid";
import {
    GetAllCompaniesThunk,
    onClearCompanies,
    onSetIsCompaniesLoading,
    selectAllCompanies, selectIsCompaniesLoading
} from "../../store/companies";
import switchImg from "../../img/icons/switcher.png";
import {GetPropertiesGridContacts, selectContactsForEmail} from "../../store/propertiesReducer";
import Tooltip from "../Tooltip/Tooltip";


const Notifications = () => {
    return (
        <PageWithSidebar>
            <NotificationsComponent/>
        </PageWithSidebar>
    )
}


const NotificationsComponent = () => {
    const [notificationPriority, setNotificationPriority] = useState('All Notifications')
    const notificationButtonTitles = ['All Notifications', 'action_required', 'hold', 'confirmed', 'cancelled', 'sent']
    const [hoveredItem, setHoveredItem] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 140);
    let activityEvents = useAppSelector(selectActivityEvents)
    const userData = useAppSelector(selectUserData)
    const isDataLoading = useAppSelector(selectIsDataLoading)
    const [isLocalDataLoading, setIsLocalDataLoading] = useState(false)
    const selectedDateRange = useAppSelector(selectDatesRange)
    const [visibleActivities, setVisibleActivities] = useState<any[]>([])
    const isContactsModalOpened = useAppSelector(selectIsContactsModalOpened)
    const isDomainModalOpened = useAppSelector(selectIsDomainModalOpened)
    let contactsByEventId = useAppSelector(selectContactsByEventId)
    const currentEventId = useAppSelector(selectCurrentEventId)
    const contactsForUpdate = useAppSelector(selectUpdatedContacts)
    const [domain, setDomain] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const switched = useAppSelector(selectIsInputsSwitched)
    const companies = useAppSelector(selectAllCompanies)
    const formContacts = useAppSelector(selectActivityFormContacts)
    const isCompaniesLoading = useAppSelector(selectIsCompaniesLoading)
    let typingTimeout: any;
    const currentEvent = useAppSelector(selectActivityCurrentNotification)
    const currentEventStatus = useAppSelector(selectCurrentEventTabStatus)
    const companyId = useAppSelector(selectOrganizationId)
    const contactsWithoutOrganizationSearched = useAppSelector(selectContactsForEmail)

    const domainNames = [
        {value: 'CD', label: 'ČINŽOVNÍ DŮM'},
        {value: 'RS', label: 'E&V-PRAGUE RESIDENTIAL'},
        {value: 'RR', label: 'E&V-PRAGUE RENTAL'},
        {value: 'OR', label: 'OFFICE-RENT'},
    ]


    // layout effect to always adapt grid height to the user screen device height
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    useEffect(() => {
        dispatch(GetPropertiesGridContacts())
    }, [dispatch])

    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },
        {
            label: 'All time',
            value: [new Date(new Date().getFullYear() - 4, 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), -7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), 7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), 7)
                ];
            },
            appearance: 'default'
        }
    ];



    useEffect(() => {

        setIsLocalDataLoading(true)
        dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
            .then((res) => {
                // @ts-ignore
                const sortedData = res.payload.slice().sort((a: any, b: any) => {
                    const dateA = new Date(a.action_date).getTime();
                    const dateB = new Date(b.action_date).getTime();

                    return dateA - dateB;
                });
                const lastElement = sortedData[sortedData.length - 1];
                dispatch(onSetActivityCurrentNotification(lastElement))
            })
            .then(() => {
                setIsLocalDataLoading(false)
            })
    }, [userData.user_ref, dispatch])


    useEffect(() => {
        if (selectedDateRange !== null && selectedDateRange[0] && selectedDateRange[1]) {
            const startDate = new Date(selectedDateRange[0]);
            const endDate = new Date(selectedDateRange[1]);
            const currentEvents = activityEvents.filter((l) => {
                const activityDate = new Date(l.action_date)
                return activityDate >= startDate && activityDate <= endDate
            }).filter((a) => a.event_status.eventStatus !== 'not_shown')
            setVisibleActivities(currentEvents)
            console.log(currentEvents[0])
            console.log(currentEvents[0]?.activity_ref)
            if(currentEvents && currentEvents[0]){
                dispatch(onSetDefaultNotification(currentEvents[0]))
                if (currentEvents[0].activity_ref !== null){
                    console.log('1')
                    dispatch(GetEventsByActivityId(Number(currentEvents[0].activity_ref)))
                    dispatch(GetEventsById(Number(currentEvents[0].event_id)))
                }
                else{
                    console.log('2')
                    dispatch(onSetCurrentEvent(null))
                    dispatch(onSetDefaultNotificationWhenEventWithNoActivityRef(Number(currentEvents[0].event_id)))
                }
            }

        } else {
            setVisibleActivities(activityEvents)
            dispatch(onSetDefaultNotification(activityEvents[0]))
        }
    }, [activityEvents, selectedDateRange])

    const onGetEvent = (eventStatus: string) => {
        setNotificationPriority(String(eventStatus))
        setIsLocalDataLoading(true)
        dispatch(onSetCurrentEventTabStatus(eventStatus))
        if (eventStatus !== 'All Notifications') {
            dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: [eventStatus]}))
                .then(() => {
                    setIsLocalDataLoading(false)
                })
        } else {
            dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                .then(() => {
                    setIsLocalDataLoading(false)
                })
        }
    }


    const handleItemHover = () => {
        setHoveredItem(true);
    };
    const handleItemLeave = () => {
        setHoveredItem(false);
    };


    const handleDateChange = (event: any, dates: any) => {
        event.stopPropagation()
        dispatch(onChangeSelectedDatesRange(dates))
    }


    const handleChangeDomain = (value: string) => {
        setDomain(value)
    };

    const onCancelUpdateContact = () => {
        dispatch(onOpenContactsModal(false))
        dispatch(onClearAddContacts())
        dispatch(onSetActivitiesOrganizationId(null))
    }

    const onCancelUpdateDomain = () => {
        dispatch(onOpenDomainModal(false))
    }

    const onUpdateContact = () => {
        setIsLoading(true)
        dispatch(UpdateContactByEventId({
            event_id: currentEventId!!,
            contacts: contactsForUpdate.map((item: string) => item).join(",")
        }))
            .then(() => {
                setIsLoading(false)
                dispatch(onOpenContactsModal(false))
                if (currentEventStatus !== 'All Notifications') {
                    dispatch(GetEventsByStatus({
                        user_ref: Number(userData.user_ref),
                        event_status: [currentEventStatus]
                    }))
                        .then(() => {
                            setIsLocalDataLoading(false)
                            dispatch(onClearAddContacts())
                            dispatch(onSetActivitiesOrganizationId(null))
                        })
                } else {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                        .then(() => {
                            setIsLocalDataLoading(false)
                            dispatch(onClearAddContacts())
                            dispatch(onSetActivitiesOrganizationId(null))
                        })
                }
            })
    }

    const onUpdateDomain = () => {
        setIsLoading(true)
        dispatch(UpdateDomainByEventId({event_id: currentEventId!!, domain: domain}))
            .then(() => {
                setIsLoading(false)
                dispatch(onOpenDomainModal(false))
                if (currentEventStatus !== 'All Notifications') {
                    dispatch(GetEventsByStatus({
                        user_ref: Number(userData.user_ref),
                        event_status: [currentEventStatus]
                    }))
                        .then(() => {
                            setIsLocalDataLoading(false)
                        })
                } else {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                        .then(() => {
                            setIsLocalDataLoading(false)
                        })
                }
            })
    }

    const onSearchModeSwitch = () => {
        dispatch(onSwitchInputsMode(!switched))
        dispatch(onClearCompanies())
        dispatch(onClearSearchedCompaniesViaContact())
        dispatch(onSetActivitiesOrganizationId(null))
    }


    const companiesList = companies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF
    }))

    const contacts = formContacts.flat(1).map((contact: any) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF,
        name: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        email: `${contact?.PE_EMAIL && true ? contact?.PE_EMAIL : ''}`,
        company: `${contact?.PE_FORM_ADDRESS ? contact.PE_FORM_ADDRESS.trim().split('\r\n')[0].split(',')[0] : ''}`
    }))


    const searchedCompaniesViaContactList = contactsWithoutOrganizationSearched.map((contact: any, index) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF,
        name: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        email: `${contact?.PE_EMAIL && true ? contact?.PE_EMAIL : ''}`,
        company: `${contact?.PE_FORM_ADDRESS ? contact.PE_FORM_ADDRESS.trim().split('\r\n')[0].split(',')[0] : ''}`
    }))

    const onChangeSelectValue = (value: any, selectName: string, option?: any) => {
        const exists = contactsByEventId.some(item => item.PE_REF === option.ref)
        if (selectName === 'Organizations' && !switched) {
            dispatch(GetActivitiesContactsThunk(option?.ref))
            dispatch(onSetActivitiesOrganizationId(option?.ref))
            dispatch(onClearCompanies())
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
        }
        //////
        else if (selectName === 'Organizations' && switched) {
            dispatch(onSetActivitiesOrganizationId(option.ref))
        }
        ///////
        else if (selectName === 'Contacts' && !switched) {
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            if (exists) {
                message.warn('You have already added this contact')
            } else {
                console.log(option)
                dispatch(onAddContacts({
                    NAME: option.name,
                    PE_EMAIL: option.email,
                    PE_REF: option.ref,
                    COMPANY_NAME: option.company
                }))
                dispatch(onAddContactsForUpdate(String(option.ref)))
            }
        }
        //////
        else if (selectName === 'Contacts' && switched) {
            // dispatch(GetActivitiesCompaniesByContactIdThunk(option.ref))
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            dispatch(onClearSearchedCompaniesViaContact())
            console.log(option)
            if (exists) {
                message.warn('You have already added this contact')
            } else {
                dispatch(onAddContacts({
                    NAME: option.name,
                    PE_EMAIL: option.email,
                    PE_REF: option.ref,
                    COMPANY_NAME: option.company
                }))
                dispatch(onAddContactsForUpdate(String(option.ref)))
            }

            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
        }


    }


    const onSearch = (value: string) => {
        if (!switched) {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(GetAllCompaniesThunk(value))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
                }, 2000);

            }
        } else {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    // dispatch(onSetIsCompaniesLoading(true))
                    // dispatch(SearchContactsByCompanyNameThunk(value))
                    dispatch(onClearCompanies())
                    // dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
                }, 2000);

            }

        }

    };


    const handleBlur = () => {
        // Do nothing to prevent value loss
    };

    const onRemoveSelected = (value: any) => {
        dispatch(onRemoveContacts(Number(value)))
    }

    const filterByContactRefs = (arr1: { value: string, label: string, ref: any }[], arr2: ContactByEventId[], key1: string, key2: string) => {
        return arr2.filter((item2: any) => {
            return arr1.some((item1: any) => item1[key1] === item2[key2]);
        });
    };

    if (companyId !== null) {
        contactsByEventId = filterByContactRefs(contacts, contactsByEventId, 'ref', 'PE_REF')

    }

    return (
        <div className={s.inner}>
            <div className={s.header}>
                <h1 className={s.title}>Notifications</h1>
                <div
                    className={s.notificationInfo}
                    onMouseEnter={handleItemHover}
                    onMouseLeave={handleItemLeave}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_2681_75618)">
                            <path
                                d="M10.0013 13.3332V9.99984M10.0013 6.6665H10.0096M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
                                stroke="#007DFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2681_75618">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                {
                    hoveredItem
                    &&
                    <div className={s.infoBlock}>
                        <h2>Status details:</h2>
                        <div className={s.infoBlock__items}>
                            <div className={s.infoBlock__item}>
                                <span className={`${s.infoBlock__badge} ${s.infoBlock__badge_required}`}>Required</span>
                                <span className={s.infoBlock__text}>- Need to select an action.</span>
                            </div>
                            <div className={s.infoBlock__item}>
                                <span className={`${s.infoBlock__badge} ${s.infoBlock__badge_hold}`}>Hold</span> <span
                                className={s.infoBlock__text}>- Hold on the event.</span>
                            </div>
                            <div className={s.infoBlock__item}>
                                <span
                                    className={`${s.infoBlock__badge} ${s.infoBlock__badge_cancelled}`}>Cancelled</span>
                                <span className={s.infoBlock__text}>- Cancel the event.</span>
                            </div>
                            <div className={s.infoBlock__item}>
                                <span
                                    className={`${s.infoBlock__badge} ${s.infoBlock__badge_confirmed}`}>Confirmed</span>
                                <span className={s.infoBlock__text}>- Waiting for the email to be sent according to the schedule.</span>
                            </div>
                            <div className={s.infoBlock__item}>
                                <span className={`${s.infoBlock__badge} ${s.infoBlock__badge_sent}`}>Sent</span> <span
                                className={s.infoBlock__text}>- The email was sent successfully.</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <p className={s.underTitleText}>View your notifications and details.</p>
            <div style={{
                display: 'flex',
            }}
                 className={s.headerButtons}
            >

                <div className={s.filterButtons}>
                    {
                        notificationButtonTitles.map((n: string) => {
                            return (
                                <button
                                    className={n === notificationPriority ? `${s.filterButtons__button} ${s.filterButtons__button_active}` : `${s.filterButtons__button}`}
                                    onClick={() => onGetEvent(n)}>
                                    {n !== null && n === 'action_required' ? 'Required action' : n !== null && n.charAt(0).toUpperCase() + n.slice(1)}
                                </button>
                            )
                        })
                    }
                </div>
                <div className={s.datePicker}>
                    {/* @ts-ignore */}
                    <DateRangePicker onChange={(value, event) => handleDateChange(event, value)} ranges={predefinedRanges}
                                     placeholder="Start date - End date"
                                     style={{width: 280, height: 42, left: 725}}
                                     defaultCalendarValue={selectedDateRange}
                                     value={selectedDateRange}
                                     className={'notificationsDatePicker'}
                                     placement={'leftStart'}

                    />
                </div>
            </div>

            <div className={s.notificationsBlock}>
                <div style={{
                    width: '70.2%',
                    height: '70vh',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    paddingBottom: '50px'
                }} className={s.notificationsInner}>
                    {
                        isLocalDataLoading
                            ?
                            <div style={{
                                height: 800,
                                width: '400px',
                                margin: '0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    height: '300px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    <Lottie
                                        // @ts-ignore
                                        config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                        }}
                                    />
                                </div>

                                <p style={{
                                    textAlign: 'center'
                                }}>Loading</p>
                            </div>
                            :
                            <div>
                                <>
                                    {
                                        visibleActivities.length
                                            ?
                                            <div>
                                                {
                                                    visibleActivities?.filter((e: ActivityEventType) => e?.event_status?.eventStatus !== 'not_shown').map((e: ActivityEventType) => {
                                                        return (
                                                            <NotificationItem
                                                                status={e.event_status !== null ? e.event_status.eventStatus : ''}
                                                                validTill={e.action_date}
                                                                activityNumber={e.activity_ref}
                                                                withActions={true}
                                                                eventNumber={e.event_id}
                                                                headerNotificationItem={false}
                                                                eventStatus={notificationPriority}
                                                                isRead={e.is_read}
                                                                description={e.event_status !== null ? e.event_status.description : ''}
                                                                isLoading={isDataLoading}
                                                                eventDescription={e.event_template !== null ? e.event_template : null}
                                                                event={e}
                                                                eventComment={e.description}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div style={{
                                                padding: '250px 0'
                                            }}>
                                                <Empty/>
                                            </div>

                                    }

                                </>
                            </div>


                    }
                </div>
                <DefaultNotification visibleEvents={visibleActivities}/>
            </div>
            {

                isContactsModalOpened
                &&
                <CreationModal
                    title={'Editing contact'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <g clipPath="url(#clip0_3380_35675)">
                            <path
                                d="M32 36V34C32 32.9391 31.5786 31.9217 30.8284 31.1716C30.0783 30.4214 29.0609 30 28 30H21C19.9391 30 18.9217 30.4214 18.1716 31.1716C17.4214 31.9217 17 32.9391 17 34V36M36 23V29M39 26H33M28.5 22C28.5 24.2091 26.7091 26 24.5 26C22.2909 26 20.5 24.2091 20.5 22C20.5 19.7909 22.2909 18 24.5 18C26.7091 18 28.5 19.7909 28.5 22Z"
                                stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3380_35675">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                    onCancel={onCancelUpdateContact}
                    onSave={onUpdateContact}
                    loading={isLoading}
                    withMargin={true}
                    buttonOkText={'Save'}
                    buttonCancelText={'Cancel'}
                >
                    {
                        !switched
                            ?
                            <>
                                <div className={'notificationsSelect notificationsSearchInput'}>
                                    <label>Organizations</label>
                                    {/*<Select*/}
                                    {/*    mode="multiple"*/}
                                    {/*    allowClear*/}
                                    {/*    style={{ width: '100%', height: '44px' }}*/}
                                    {/*    placeholder="Please select contact"*/}
                                    {/*    onChange={handleChangeContact}*/}
                                    {/*    options={contactsOptions}*/}
                                    {/*/>*/}
                                    <Select
                                        filterOption={(input, option) =>
                                            (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                        }
                                        showSearch
                                        placeholder="Search"
                                        optionFilterProp="children"
                                        onChange={(value, option) => onChangeSelectValue(value, 'Organizations', option)}
                                        onSearch={onSearch}
                                        options={companiesList}
                                        notFoundContent={null}
                                        // value={modalFormFields.organisations}
                                        style={{
                                            minHeight: '44px',
                                            width: '100%'
                                        }}
                                        onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                        onBlur={handleBlur}
                                        loading={isCompaniesLoading}
                                        suffixIcon={!isCompaniesLoading ? <Tooltip
                                            text="Please, use % in searching string, after/before the actual search value, for example lars% in order to find organizations"
                                            classname={'notificationsSearchInputTooltip'}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip> : <LoadingOutlined/>}
                                    />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%'
                                }}>
                                    <button onClick={onSearchModeSwitch} className={s.switchButton}>
                                        <img src={switchImg} alt="switch"/>
                                    </button>
                                </div>
                                <div className={'notificationsSelect'} style={{
                                    marginTop: '-20px'
                                }}>
                                    <label>Contacts</label>
                                    <Select
                                        placeholder="Contacts"
                                        // onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                        options={contacts}
                                        style={{
                                            minHeight: '44px',
                                            width: '100%'
                                        }}
                                        // defaultValue={contactsOptions}
                                        // value={contactsOptions}
                                        value={null}
                                        onDeselect={(value: any) => onRemoveSelected(value)}
                                        onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                        // disabled={maxWidthInputs || disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                    />
                                </div>
                                <div>
                                    {
                                        contactsByEventId.map((c: ContactByEventId) => {
                                            return (
                                                <div className={s.editContact} key={c.PE_REF}>
                                                    <div>
                                                        <p className={s.editContact__name}>{c.NAME} <span
                                                            className={s.editContact__company}>{c.COMPANY_NAME && `(${c.COMPANY_NAME})`}</span>
                                                        </p>
                                                        <p className={s.editContact__email}>{c.PE_EMAIL}</p>
                                                    </div>
                                                    <button className={s.editContact__remove}
                                                            onClick={() => onRemoveSelected(c.PE_REF)}>Remove
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>

                            :
                            <>
                                <div className={'notificationsSelect'}>
                                    <div className={'notificationsSelect notificationsSearchInput'}>
                                        <label>Contacts</label>
                                        <Select
                                            filterOption={(input, option) =>
                                                (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                            }
                                            showSearch
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            // onDeselect={(value: any) => onRemoveSelected(value)}
                                            onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                            onSearch={onSearch}
                                            options={searchedCompaniesViaContactList}
                                            notFoundContent={null}
                                            // value={contactsOptions}
                                            // value={modalFormFields.contacts}
                                            style={{
                                                minHeight: '44px',
                                                width: '100%'
                                            }}
                                            onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                            onBlur={handleBlur}
                                            loading={isCompaniesLoading}
                                            suffixIcon={!isCompaniesLoading ? <Tooltip
                                                text="Please, use % in searching string, after/before the actual search value, for example lars% in order to find contacts"
                                                classname={'notificationsSearchInputTooltip'}>
                                                <QuestionCircleOutlined/>
                                            </Tooltip> : <LoadingOutlined/>}
                                        />
                                    </div>
                                    <div>
                                        {
                                            contactsByEventId.map((c: ContactByEventId) => {
                                                return (
                                                    <div className={s.editContact} key={c.PE_REF}>
                                                        <div>
                                                            <p className={s.editContact__name}>{c.NAME} <span
                                                                className={s.editContact__company}>{c.COMPANY_NAME && `(${c.COMPANY_NAME})`}</span>
                                                            </p>
                                                            <p className={s.editContact__email}>{c.PE_EMAIL}</p>
                                                        </div>
                                                        <button className={s.editContact__remove}
                                                                onClick={() => onRemoveSelected(c.PE_REF)}>Remove
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%'
                                    }}>
                                        <button onClick={onSearchModeSwitch} className={s.switchButton}>
                                            <img src={switchImg} alt="switch"/>
                                        </button>
                                    </div>
                                    <div style={{
                                        marginTop: '-20px'
                                    }}>
                                        <label>Organizations</label>
                                        <Select
                                            style={{width: '100%', height: '44px'}}
                                            disabled={true}
                                        />
                                    </div>
                                </div>


                            </>
                    }

                </CreationModal>

            }
            {
                isDomainModalOpened
                &&
                <CreationModal
                    title={'Editing domain'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <g clipPath="url(#clip0_3380_35675)">
                            <path
                                d="M32 36V34C32 32.9391 31.5786 31.9217 30.8284 31.1716C30.0783 30.4214 29.0609 30 28 30H21C19.9391 30 18.9217 30.4214 18.1716 31.1716C17.4214 31.9217 17 32.9391 17 34V36M36 23V29M39 26H33M28.5 22C28.5 24.2091 26.7091 26 24.5 26C22.2909 26 20.5 24.2091 20.5 22C20.5 19.7909 22.2909 18 24.5 18C26.7091 18 28.5 19.7909 28.5 22Z"
                                stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3380_35675">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                    onCancel={onCancelUpdateDomain}
                    onSave={onUpdateDomain}
                    loading={isLoading}
                    withMargin={true}
                    buttonOkText={'Save'}
                    buttonCancelText={'Cancel'}
                >
                    <div className={'notificationsSelect'}>
                        <label>Domain</label>
                        <Select
                            allowClear
                            style={{
                                width: '100%', height: '44px', borderRadius: '8px',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                            }}
                            placeholder="Please select domain"
                            onChange={handleChangeDomain}
                            options={domainNames}
                            // value={}
                            defaultValue={currentEvent?.domain!!}
                        />
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default Notifications;