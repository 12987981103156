import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {message, Popover, Select} from 'antd'
import classes from './Header.module.css'
import {ReactComponent as Logo} from './../../../img/logo.svg'
import {ReactComponent as DownArrow} from './../../../img/icons/downArrow.svg'
import {ReactComponent as LogOut} from './../../../img/icons/logOut.svg'
import {ReactComponent as NotificationsBell} from './../../../img/icons/notificationsBell.svg'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {selectUserData, selectUsername, SignOutThunk} from '../../../store/userReducer'
import {
    ContactByEventId,
    GetEventsByStatus,
    onAddContacts,
    onAddContactsForUpdate,
    onChangeSelectedDatesRange,
    onOpenContactsModal,
    onOpenDomainModal,
    onRemoveContacts,
    selectActivityCurrentNotification,
    selectContactsByEventId,
    selectCurrentEventId, selectCurrentEventTabStatus,
    selectIsContactsModalOpened,
    selectIsDomainModalOpened,
    selectUpdatedContacts,
    UpdateContactByEventId,
    UpdateDomainByEventId
} from '../../../store/eventsReducer'
import HeaderMenuNotifications from "../../HeaderMenuNotifications/HeaderMenuNotifications";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import CreationModal from "../CreationModal/CreationModal";
import {ReactComponent as SearchIcon} from "../../../img/icons/searchTable.svg";
import {LoadingOutlined} from "@ant-design/icons";
import s from "../../Notifications/Notifications.module.scss";
import switchImg from "../../../img/icons/switcher.png";
import {
    GetActivitiesCompaniesByContactIdThunk,
    GetActivitiesContactsThunk,
    onChangeModalActivityFormField,
    onClearActivitiesContacts,
    onClearSearchedCompaniesViaContact,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSwitchInputsMode,
    SearchContactsByCompanyNameThunk,
    selectActivityFormContacts,
    selectIsInputsSwitched, selectOrganizationId,
    selectSearchedCompaniesViaContact
} from "../../../store/activitesGrid";
import {
    GetAllCompaniesThunk,
    onClearCompanies,
    onSetIsCompaniesLoading,
    selectAllCompanies, selectIsCompaniesLoading
} from "../../../store/companies";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";




const Header = () => {
    const username = useAppSelector(selectUsername)
    const [openedNotifications, setOpenedNotifications] = useState(false)
    const [time, setTime] = useState(moment());
    const outsideDivRef = useRef<any>(null);
    const exemptedButtonRef = useRef(null);
    const dispatch = useAppDispatch()
    const userData = useAppSelector(selectUserData)
    const currentPage = window.location.pathname
    const isContactsModalOpened = useAppSelector(selectIsContactsModalOpened)
    const isDomainModalOpened = useAppSelector(selectIsDomainModalOpened)
    const [domain, setDomain] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    let contactsByEventId = useAppSelector(selectContactsByEventId)
    const currentEventId = useAppSelector(selectCurrentEventId)
    const contactsForUpdate = useAppSelector(selectUpdatedContacts)
    const switched = useAppSelector(selectIsInputsSwitched)
    const companies = useAppSelector(selectAllCompanies)
    const formContacts = useAppSelector(selectActivityFormContacts)
    const isCompaniesLoading = useAppSelector(selectIsCompaniesLoading)
    let typingTimeout: any;
    const currentEvent = useAppSelector(selectActivityCurrentNotification)
    const searchedCompaniesViaContact = useAppSelector(selectSearchedCompaniesViaContact)
    const currentEventStatus = useAppSelector(selectCurrentEventTabStatus)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const companyId = useAppSelector(selectOrganizationId)

    useEffect(() => {
        const timer = setInterval(() => setTime(moment()), 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if(currentPage !== '/notifications'){
            dispatch(onChangeSelectedDatesRange([new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate(), 0, 0, 0),
                new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate(), 23, 59, 59, 999)]))
            dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
        }
    }, [userData.user_ref, dispatch, currentPage])

    useEffect(() => {
        if(currentPage !== '/properties' && currentPage !== '/properties/brokerage/reqs'){
            gridDataHelper.removeBrokeragePropertiesSearchValue()
            gridDataHelper.removeBrokeragePropertiesStatus()

        }
    }, [userData.user_ref, dispatch, currentPage])


    useEffect(() => {
        const closeDivOutsideClick = (event: MouseEvent) => {
            // @ts-ignore
            if (outsideDivRef.current && !outsideDivRef.current.contains(event.target as Node) && exemptedButtonRef.current && !exemptedButtonRef.current.contains(event.target as Node)) {
                setOpenedNotifications(false);
            }
        };

        document.addEventListener('click', closeDivOutsideClick);

        return () => {
            document.removeEventListener('click', closeDivOutsideClick);
        };
    }, []);

    const onOpenNotifications = () => {
        setOpenedNotifications(!openedNotifications)
    }


    const domainNames = [
        {value: 'CD', label: 'ČINŽOVNÍ DŮM'},
        {value: 'RS', label: 'E&V-PRAGUE RESIDENTIAL'},
        {value: 'RR', label: 'E&V-PRAGUE RENTAL'},
        {value: 'OR', label: 'OFFICE-RENT'},
    ]


    const handleChangeDomain = (value: string) => {
        setDomain(value)
    };

    const onCancelUpdateContact = () => {
        dispatch(onOpenContactsModal(false))
    }

    const onCancelUpdateDomain = () => {
        dispatch(onOpenDomainModal(false))
    }

    const onUpdateContact = () => {
        setIsLoading(true)
        dispatch(UpdateContactByEventId({
            event_id: currentEventId!!,
            contacts: contactsForUpdate.map((item: string) => item).join(",")
        }))
            .then(() => {
                setIsLoading(false)
                dispatch(onOpenContactsModal(false))
                if (currentEventStatus !== 'All Notifications') {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                    dispatch(onSetActivitiesOrganizationId(null))
                } else {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                    dispatch(onSetActivitiesOrganizationId(null))
                }
            })
    }

    const onUpdateDomain = () => {
        setIsLoading(true)
        dispatch(UpdateDomainByEventId({event_id: currentEventId!!, domain: domain}))
            .then(() => {
                setIsLoading(false)
                dispatch(onOpenDomainModal(false))
                if (currentEventStatus !== 'All Notifications') {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                } else {
                    dispatch(GetEventsByStatus({user_ref: Number(userData.user_ref), event_status: []}))
                }
            })
    }

    const onSearchModeSwitch = () => {
        dispatch(onSwitchInputsMode(!switched))
        dispatch(onClearCompanies())
        dispatch(onClearSearchedCompaniesViaContact())
    }


    const companiesList = companies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF
    }))

    const contacts = formContacts.flat(1).map((contact: any) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        // label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        ref: contact.PE_REF
    }))


    const searchedCompaniesViaContactList = searchedCompaniesViaContact.map((contact: any, index) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ?  '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ?  '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF
    }))

    const onChangeSelectValue = (value: any, selectName: string, option?: any) => {
        const exists = contactsByEventId.some(item => item.PE_REF === option.ref)
        if (selectName === 'Organizations' && !switched) {
            dispatch(GetActivitiesContactsThunk(option?.ref))
            dispatch(onSetActivitiesOrganizationId(option?.ref))
            dispatch(onClearCompanies())
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
        }
        //////
        else if (selectName === 'Organizations' && switched) {
            dispatch(onSetActivitiesOrganizationId(option.ref))
        }
        ///////
        else if (selectName === 'Contacts' && !switched) {
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            if(exists){
                message.warn('You have already added this contact')
            }
            else{
                dispatch(onAddContacts({NAME: option.label,
                    PE_EMAIL: option.label,
                    PE_REF: option.ref}))
                dispatch(onAddContactsForUpdate(String(option.ref)))
            }
        }
        //////
        else if (selectName === 'Contacts' && switched) {
            dispatch(GetActivitiesCompaniesByContactIdThunk(option.ref))
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            dispatch(onClearSearchedCompaniesViaContact())
            if(exists){
                message.warn('You have already added this contact')
            }
            else{
                dispatch(onAddContacts({NAME: option.label,
                    PE_EMAIL: option.label,
                    PE_REF: option.ref}))
                dispatch(onAddContactsForUpdate(String(option.ref)))
            }

            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
        }


    }


    const onSearch = (value: string) => {
        if (!switched) {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(GetAllCompaniesThunk(value))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
                }, 2000);

            }
        } else {
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(SearchContactsByCompanyNameThunk(value))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
                }, 2000);

            }

        }

    };

    const handleBlur = () => {
        // Do nothing to prevent value loss
    };


    const onRemoveSelected = (value: any) => {
        dispatch(onRemoveContacts(Number(value)))
    }


    const filterByContactRefs = (arr1:  {value: string, label: string, ref: any}[], arr2:  ContactByEventId[], key1: string, key2: string) => {
        return arr2.filter((item2: any) => {
            return arr1.some((item1: any) => item1[key1] === item2[key2]);
        });
    };

    if(companyId !== null){
        contactsByEventId = filterByContactRefs(contacts, contactsByEventId, 'ref', 'PE_REF')

    }

    return (
        <>
            <header className={classes.wrapper}>
                <Logo className={classes.logo}/>
                <div className={classes.appTitle}>
                    PRIMO PORTAL
                </div>
                {
                    currentScreenWidth! > 768
                    &&
                    <div className={classes.userData}>
                        <div className={classes.nicknameArea}>
                            <div
                                style={{
                                    marginRight: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                                onClick={onOpenNotifications}
                                ref={exemptedButtonRef}
                            >
                                <NotificationsBell/>
                            </div>
                            {/*<Avatar size={40} icon={<UserDefaultIcon/>} style={{marginRight: '8px'}}/>*/}
                            <span className={classes.nickname}>
                        {username}
                    </span>
                            <Popover trigger='click' content={UserMenu}>
                                <DownArrow style={{marginLeft: '8px', cursor: 'pointer'}}/>
                            </Popover>
                        </div>
                        <div>
                            <span className={classes.date}>{moment(time).format('D MMM YYYY')}</span>
                            |
                            <span className={classes.time}>{moment(time).format('H:mm a')}</span>
                        </div>
                    </div>
                }

                {
                    openedNotifications
                    &&
                    <div >
                        <HeaderMenuNotifications
                            setIsMenuOpened={(isMenuOpened) => setOpenedNotifications(isMenuOpened)}
                        />
                    </div>

                }

            </header>
            {

                isContactsModalOpened
                &&
                <CreationModal
                    title={'Editing contact'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <g clipPath="url(#clip0_3380_35675)">
                            <path
                                d="M32 36V34C32 32.9391 31.5786 31.9217 30.8284 31.1716C30.0783 30.4214 29.0609 30 28 30H21C19.9391 30 18.9217 30.4214 18.1716 31.1716C17.4214 31.9217 17 32.9391 17 34V36M36 23V29M39 26H33M28.5 22C28.5 24.2091 26.7091 26 24.5 26C22.2909 26 20.5 24.2091 20.5 22C20.5 19.7909 22.2909 18 24.5 18C26.7091 18 28.5 19.7909 28.5 22Z"
                                stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3380_35675">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                    onCancel={onCancelUpdateContact}
                    onSave={onUpdateContact}
                    loading={isLoading}
                    withMargin={true}
                    buttonOkText={'Save'}
                    buttonCancelText={'Cancel'}
                >
                    {
                        !switched
                            ?
                            <>
                                <div className={'notificationsSelect'}>
                                    <label>Organizations</label>
                                    {/*<Select*/}
                                    {/*    mode="multiple"*/}
                                    {/*    allowClear*/}
                                    {/*    style={{ width: '100%', height: '44px' }}*/}
                                    {/*    placeholder="Please select contact"*/}
                                    {/*    onChange={handleChangeContact}*/}
                                    {/*    options={contactsOptions}*/}
                                    {/*/>*/}
                                    <Select
                                        filterOption={(input, option) =>
                                            (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                                        }
                                        showSearch
                                        placeholder="Search"
                                        optionFilterProp="children"
                                        onChange={(value, option) => onChangeSelectValue(value, 'Organizations', option)}
                                        onSearch={onSearch}
                                        options={companiesList}
                                        notFoundContent={null}
                                        // value={modalFormFields.organisations}
                                        style={{
                                            minHeight: '44px',
                                            width: '100%'
                                        }}
                                        onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                        onBlur={handleBlur}
                                        loading={isCompaniesLoading}
                                        suffixIcon={!isCompaniesLoading ? <SearchIcon/> : <LoadingOutlined/>}
                                    />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%'
                                }}>
                                    <button onClick={onSearchModeSwitch} className={s.switchButton}>
                                        <img src={switchImg} alt="switch"/>
                                    </button>
                                </div>
                                <div className={'notificationsSelect'} style={{
                                    marginTop: '-20px'
                                }}>
                                    <label>Contacts</label>
                                    <Select
                                        placeholder="Contacts"
                                        // onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                        options={contacts}
                                        style={{
                                            minHeight: '44px',
                                            width: '100%'
                                        }}
                                        // defaultValue={contactsOptions}
                                        // value={contactsOptions}
                                        value={null}
                                        onDeselect={(value: any) => onRemoveSelected(value)}
                                        onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                        // disabled={maxWidthInputs || disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                    />
                                </div>
                                <div>
                                    {
                                        contactsByEventId.map((c:ContactByEventId) => {
                                            return(
                                                <div className={s.editContact} key={c.PE_REF}>
                                                    <div>
                                                        <p className={s.editContact__name}>{c.NAME} <span className={s.editContact__company}>{c.COMPANY_NAME && `(${c.COMPANY_NAME})`}</span></p>
                                                        <p className={s.editContact__email}>{c.PE_EMAIL}</p>
                                                    </div>
                                                    <button className={s.editContact__remove} onClick={() => onRemoveSelected(c.PE_REF)}>Remove</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>

                            :
                            <>
                                <div className={'notificationsSelect'}>
                                    <div className={'notificationsSelect'}>
                                        <label>Contacts</label>
                                        <Select
                                            filterOption={(input, option) =>
                                                (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                                            }
                                            showSearch
                                            placeholder="Search"
                                            optionFilterProp="children"
                                            // onDeselect={(value: any) => onRemoveSelected(value)}
                                            onSelect={(value: any, option: any) => onChangeSelectValue(value, 'Contacts', option)}
                                            onSearch={onSearch}
                                            options={searchedCompaniesViaContactList}
                                            notFoundContent={null}
                                            // value={contactsOptions}
                                            // value={modalFormFields.contacts}
                                            style={{
                                                minHeight: '44px',
                                                width: '100%'
                                            }}
                                            onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                            onBlur={handleBlur}
                                            loading={isCompaniesLoading}
                                            suffixIcon={!isCompaniesLoading ? <SearchIcon/> : <LoadingOutlined/>}
                                        />
                                    </div>
                                    <div>
                                        {
                                            contactsByEventId.map((c:ContactByEventId) => {
                                                return(
                                                    <div className={s.editContact} key={c.PE_REF}>
                                                        <div>
                                                            <p className={s.editContact__name}>{c.NAME} <span className={s.editContact__company}>{c.COMPANY_NAME && `(${c.COMPANY_NAME})`}</span></p>
                                                            <p className={s.editContact__email}>{c.PE_EMAIL}</p>
                                                        </div>
                                                        <button className={s.editContact__remove} onClick={() => onRemoveSelected(c.PE_REF)}>Remove</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%'
                                    }}>
                                        <button onClick={onSearchModeSwitch} className={s.switchButton}>
                                            <img src={switchImg} alt="switch"/>
                                        </button>
                                    </div>
                                    <div style={{
                                        marginTop: '-20px'
                                    }}>
                                        <label>Organizations</label>
                                        <Select
                                            style={{ width: '100%', height: '44px' }}
                                            disabled={true}
                                        />
                                    </div>
                                </div>


                            </>
                    }

                </CreationModal>

            }
            {
                isDomainModalOpened
                &&
                <CreationModal
                    title={'Editing domain'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <g clipPath="url(#clip0_3380_35675)">
                            <path
                                d="M32 36V34C32 32.9391 31.5786 31.9217 30.8284 31.1716C30.0783 30.4214 29.0609 30 28 30H21C19.9391 30 18.9217 30.4214 18.1716 31.1716C17.4214 31.9217 17 32.9391 17 34V36M36 23V29M39 26H33M28.5 22C28.5 24.2091 26.7091 26 24.5 26C22.2909 26 20.5 24.2091 20.5 22C20.5 19.7909 22.2909 18 24.5 18C26.7091 18 28.5 19.7909 28.5 22Z"
                                stroke="#0707FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3380_35675">
                                <rect width="24" height="24" fill="white" transform="translate(16 16)"/>
                            </clipPath>
                        </defs>
                    </svg>}
                    onCancel={onCancelUpdateDomain}
                    onSave={onUpdateDomain}
                    loading={isLoading}
                    withMargin={true}
                    buttonOkText={'Save'}
                    buttonCancelText={'Cancel'}
                >
                    <div className={'notificationsSelect'}>
                        <label>Domain</label>
                        <Select
                            allowClear
                            style={{
                                width: '100%', height: '44px', borderRadius: '8px',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                            }}
                            placeholder="Please select domain"
                            onChange={handleChangeDomain}
                            options={domainNames}
                            // value={}
                            defaultValue={currentEvent?.domain!!}
                        />
                    </div>
                </CreationModal>
            }
        </>

    )
}

const UserMenu = () => {
    const dispatch = useAppDispatch()
    return (
        <div className={classes.userMenu} onClick={() => dispatch(SignOutThunk())}>
            <LogOut/>Log Out
        </div>
    )
}





export default Header
